import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import useClass from '../../../../hooks/useClass';
import { deleteQuestion, updateAnswer } from '../../../../store/customer/application/Assesment';

const Questions = () => {

  const containerRef = useRef(null);
  const { dispatch } = useClass();
  const { selectedQuestions } = useSelector((state) => (state?.pd));
  const prevQuestionsLength = useRef(selectedQuestions?.length || 0);


  useEffect(() => {
    if (containerRef.current && selectedQuestions?.length > prevQuestionsLength.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
    prevQuestionsLength.current = selectedQuestions?.length || 0;
  }, [selectedQuestions]); 

  const handleDelete = (i) => {
    dispatch(deleteQuestion(i));
  }

  const handleAnswer = (index, answer) => {
    dispatch(updateAnswer({index, answer}));
  }

  return (
    <div className='pd-questions' style={{maxHeight: '500px', overflowY: 'scroll'}} ref={containerRef}>
      {selectedQuestions?.map((q, index) => (
        <React.Fragment key={`pd-question-${index}`}>
          <div className='mb-4'>
            <div>
              <span style={{fontSize: '16px', fontWeight: 'bold'}} className='text-primary'>
                {index+1}. {q?.question}&nbsp;
                <Button className='p-0 text-danger' color='link' onClick={() => handleDelete(index)}>
                  <i className='ri-close-circle-line' />
                </Button>
              </span>
            </div>
            <form>
              <div className='form-group'>
                <textarea 
                  rows={2} 
                  className='w-100 form-control'
                  onChange={(e) =>  handleAnswer(index, e.target.value)}
                  style={{maxHeight: '250px'}}
                  value={selectedQuestions?.[index]?.answer || ''}
                />
              </div>
            </form>
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}

export default React.memo(Questions);
