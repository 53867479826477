import React, { useState } from 'react';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import TextInput from '../../../../components/text-input/TextInput';
import useClass from '../../../../hooks/useClass';
import { createCampaignEmailTemplate } from '../../../../store/campaign/template';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { messageType } from '../../../../constants/constants.js';

const Email = () => {

  const { dispatch } = useClass();
  const [form, setForm] = useState({
    emailSubject: '',
    emailBody: '',
    description: '',
    status: 'Active',
    emailName: '',
    emailType: '',
    emailFiles: []
  });

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmailForm()) {
      const formData = new FormData();
      for (const key in form) {
        if (Array.isArray(form[key])) {
          form[key].forEach(file => {
            formData.append(key, file);
          });
        } else {
          formData.append(key, form[key]);
        }
      }
      dispatch(createCampaignEmailTemplate(formData));
    }
  }

  const validateEmailForm = () => {
    const result = Object.keys(form).every((key) => {
      return form.hasOwnProperty(key) && form[key].trim() !== '';
    });
    return result;
  }


  return (
    <>
      <Form className='p-3' onSubmit={handleSubmit}>
        <FormGroup>
          <TextInput 
            label={'Email Subject'}
            value={form?.emailSubject}
            required={true}
            type={'emailSubject'}
            name={'emailSubject'}
            handleInput={handleInput}
          />
        </FormGroup>
        <FormGroup>
          <TextInput 
            label={'Email Body'}
            value={form?.emailBody}
            required={true}
            type={'text'}
            name={'emailBody'}
            handleInput={handleInput}
          />
        </FormGroup>
         <FormGroup>
         <div className='heading'>Upload Files</div>
          <Input
            type="file"
            name="emailFiles" 
            multiple
            onChange={(e) => handleInput('emailFiles', e.target.files)} 
          />
       </FormGroup>
       <FormGroup>
          <TextInput 
            label={'Email Name '}
            value={form?.emailName}
            required={true}
            type={'text'}
            name={'emailName'}
            handleInput={handleInput}
          />
        </FormGroup>
        <FormGroup>
          <TextInput 
            label={'Email Description'}
            value={form?.description}
            required={true}
            type={'textarea'}
            name={'description'}
            handleInput={handleInput}
          />
        </FormGroup>
        <FormGroup>
          <Dropdown
            label={'Select  email Type'}
            value={form?.emailType}
            required={true}
            name={'emailType'}
            handleInput={handleInput}
            options ={messageType}
          />
        </FormGroup>
        <Button className='w-100 mt-2' color="primary" type='submit'>Create Email</Button>
      </Form>
    </>
  )
}

export default React.memo(Email);
