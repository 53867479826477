import { call, put, all, fork, takeLatest, select } from 'redux-saga/effects';
import { getApplicationCAM, getApplicationCAMError, getApplicationCAMSuccess, getEditableFields, getEditableFieldsError, getEditableFieldsSuccess, getUserAndDocList, getUserAndDocListError, getUserAndDocListSuccess, toggleCAM, toggleCollapse, updateApplicationCAM, updateApplicationCAMError, updateApplicationCAMSuccess, updateEditableFields, updateEditableFieldsError, updateEditableFieldsSuccess, updateUserAndDoc, updateUserAndDocError, updateUserAndDocSuccess } from './action';
import { Toaster } from '../../../components/toaster/Toaster';
import { LoanService } from '../../../service/loanService';

const toaster = new Toaster();
const loanServer = new LoanService();



function* watchEditableFields() {
    yield takeLatest(getEditableFields.type, fetchEditableFields);
}

function* watchUpdateEditableFields() {
    yield takeLatest(updateEditableFields.type, sendEditableFields);
}

function* watchApplicationCAM() {
    yield takeLatest(getApplicationCAM.type, fetchApplicationCAM);
}

function* watchUpdateApplicationCAM() {
    yield takeLatest(updateApplicationCAM.type, sendApplicationCAM);
}

function* watchUserAndDoc() {
    yield takeLatest(getUserAndDocList.type, fetchUserAndDoc);
}

function* watchUpdateUserAndDoc() {
    yield takeLatest(updateUserAndDoc.type, sendUserAndDoc);
}




function* fetchEditableFields({payload}) {
    try {
        const { openCollapse } = yield select((state) => (state.cam));
        const response = yield call(loanServer.getEditableFields, payload);
        const fields = response?.data?.data;
        if(fields?.length === 0){
            yield put(toggleCollapse(openCollapse+1));
        }
        yield put(getEditableFieldsSuccess(fields));
    } catch(error) {
        toaster.error(error);
        yield put(getEditableFieldsError(error));
    }
}

function* sendEditableFields({payload}) {
    try {
        const { openCollapse } = yield select((state) => (state.cam));
        const response = yield call(loanServer.updateEditableFields, payload);
        const { header, body, footer} = response?.data?.data;
        const bodyHtml = atob(body || '');
        const headerHtml = atob(header || '');
        const footerHtml = atob(footer || '');
        const cam = `${decodeURIComponent(bodyHtml)}`;
        yield put(updateEditableFieldsSuccess({cam}));
        yield put(toggleCollapse(openCollapse+1));
    } catch(error) {
        toaster.error(error);
        yield put(updateEditableFieldsError(error));
    }
}

function* fetchApplicationCAM({payload}) {
    try {
        const response = yield call(loanServer.getApplicationCAM, payload);
        yield put(getApplicationCAMSuccess());
    } catch(error) {
        toaster.error(error);
        yield put(getApplicationCAMError(error));
    }
}

function* sendApplicationCAM({payload}) {
    try {
        const { openCollapse } = yield select((state) => (state.cam));
        const response = yield call(loanServer.updateApplicationCAM, payload);
        yield put(updateApplicationCAMSuccess());
        yield put(toggleCollapse(openCollapse+1));
    } catch(error) {
        toaster.error(error);
        yield put(updateApplicationCAMError(error));
    }
}

function* fetchUserAndDoc({payload}) {
    try {
        const response = yield call(loanServer.getUserAndDoc, payload.lenderCode, payload.customerCode);
        const { users, documents } = response?.data?.data;
        yield put(getUserAndDocListSuccess({users: users, docs: documents}));
    } catch(error) {
        toaster.error(error);
        yield put(getUserAndDocListError(error));
    }
}

function* sendUserAndDoc({payload}) {
    try {
        const response = yield call(loanServer.updateUserAndDoc, payload);
        yield put(updateUserAndDocSuccess());
        yield put(toggleCAM());
        toaster.success('Mail sent successfully');
        setTimeout(() => {
            window.location.reload();
        }, 1500)
    } catch(error) {
        toaster.error(error);
        yield put(updateUserAndDocError(error));
    }
}


export default function* camSaga(){
    yield all([
        fork(watchEditableFields),
        fork(watchUpdateEditableFields),
        fork(watchApplicationCAM),
        fork(watchUpdateApplicationCAM),
        fork(watchUserAndDoc),
        fork(watchUpdateUserAndDoc)
    ])
};
