import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { 
    getBankStatementReport,
    getBankStatementReportError,
    getBankStatementReportSuccess,
    getBankTransactions,
    getBankTransactionsError,
    getBankTransactionsSuccess,
    getBureauReport, 
    getBureauReportError, 
    getBureauReportSuccess,
} from './actions';
import { ApiService } from '../../service/ApiService';
import { Toaster } from '../../components/toaster/Toaster';

const server = new ApiService();
const toaster = new Toaster();


function* watchBureauReport() {
    yield takeEvery(getBureauReport.type, fetchBureauReport);
}

function* watchBankStatement() {
    yield takeEvery(getBankStatementReport.type, fetchBankStatement);
}

function* watchBankTransactions() {
    yield takeEvery(getBankTransactions.type, fetchBankTransactions);
}


function* fetchBureauReport(action) {
    try {
        const response = yield call(server.fetchBureauReport, action?.payload);
        yield put(getBureauReportSuccess(response?.data?.data?.[0]));
    } catch(error) {
        yield put(getBureauReportError(error));
        toaster.error(error);
    }
}

function* fetchBankStatement(action) {
    try {
        const response = yield call(server.fetchBankStatement, action?.payload);
        yield put(getBankStatementReportSuccess(response?.data?.data));
    } catch(error) {
        yield put(getBankStatementReportError(error));
        toaster.error(error);
    }
}

function* fetchBankTransactions(action) {
    try {
        const response = yield call(server.fetchBankTransactions, action?.payload);
        yield put(getBankTransactionsSuccess(response?.data?.data));
    } catch(error) {
        yield put(getBankTransactionsError(error));
        toaster.error(error);
    }
}

export default function* reportSaga(){
    yield all([
        fork(watchBureauReport),
        fork(watchBankStatement),
        fork(watchBankTransactions)
    ])
};