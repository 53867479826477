import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Card, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap';
import classnames from 'classnames';
import Email from './Email.js';
import WhatsApp from './WhatsApp.js';
import SMS from './SMS.js';

const Create = ({ isOpen, setIsOpen }) => {

  const [activeTab, setActiveTab] = useState('email');

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} centered>
      <ModalHeader toggle={() => setIsOpen(!isOpen)}>
        Create Template
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <Nav tabs>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'email' })} onClick={() => toggleTab('email')}>
                      Email
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'whatsapp' })} onClick={() => toggleTab('whatsapp')}>
                      WhatsApp
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'sms' })} onClick={() => toggleTab('sms')}>
                      SMS
                    </NavLink>
                  </NavItem>  
                </Nav>
              </CardHeader>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="email">
                  <Email />
                </TabPane>
                <TabPane tabId="whatsapp">
                  <WhatsApp />
                </TabPane>
                <TabPane tabId="sms">
                  <SMS />
                </TabPane>
              </TabContent>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default Create;
