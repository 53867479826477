import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LoadingButton from '../../../components/Common/LoadingButton';
import { useParams } from 'react-router-dom';
import FolllowUp from '../edit/FolllowUp';
import useClass from '../../../hooks/useClass';
import { createLeadTask } from '../../../store/user/userSlice';

const Create = ({isOpen, setIsOpen}) => {

  const { dispatch } = useClass();
  const { leadCode } = useParams();
  const [form, setForm] = useState({
    meetingType: '',
    followupDate: '',
    description: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleCreate = () => {
    const invalid = Object.keys(form)?.some((key) => (!form[key] || form[key].trim() === ''));
    if(invalid){
      setIsSubmitted(true);
      return;
    }
    const payload = {
      name: 'Follow up',
      type: 'MEETING',
      applicationId: leadCode,
      ...form
    }
    dispatch(createLeadTask(payload));
    setIsOpen(false);
    setForm({
      meetingType: '',
      followupDate: '',
      description: ''
    })
  }

  const handleModelClose = () => {
    setIsOpen(false);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        size={'md'}
        toggle={handleModelClose}
        centered
      >
        <ModalHeader toggle={handleModelClose}>
          Create new schedule
        </ModalHeader>
        <ModalBody>
          <FolllowUp
            form={form}
            isSubmitted={isSubmitted}
            required={true}
            handleInput={handleInput}
          />
          <div>
            <div className='text-input'>
              <div className='heading'>Description<span className='text-danger'>*</span></div>
              <div className="input-group">
                <textarea
                  className='form-control'
                  placeholder='Any comment...'
                  value={form?.description}
                  onChange={(e) => handleInput('description', e.target.value)}
                  cols={8}
                  rows={3}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <LoadingButton
            buttonContent={'Create'}
            isLoading={false}
            color={'success'}
            onClick={handleCreate}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default React.memo(Create);
