import React, { useState } from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import TextInput from '../../../components/text-input/TextInput';
import { FILL_ALL_MENDETORY_FIELDS } from '../../../utils/error';
import { isMobileValid } from '../../../utils/helper';


const CreateChargingStation = (props) => {
  const { toaster, oemServer, navigate } = useClass();
  const { isOpen, setIsOpen } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    lat: '',
    lng: '',
    csDisplayno: '',
    stationName: '',
    address: '',
    cityName: '',
    pinCode: '',
    stOwner: '',
    companyName: '',
    mobileNumber: '',
    contactPerson: '',
    amenities: '',
    avgCostDiscom: '',
    openingTime: '',
    closingTime: '',
    wkStatus: false,
    isFourWheeler: false,
  });

  const handleCheckbox = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleModelClose = () => {
    setIsOpen(false);
  };

  const handleInput = (key, value) => {
    if(key === 'mobileNumber' && !isMobileValid(value)){
      return;
    }
    setForm((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleChargingStationCreation = async () => {
    try {
      if (validateForm()) {
        setIsLoading(true);
        const payload = {
          ...form,
          pinCode: parseInt(form.pinCode, 10),
          lat: parseFloat(form.lat),
          lng: parseFloat(form.lng),
          csDisplayno: parseInt(form.csDisplayno, 10)
        };
        const response = await oemServer.createChargingStation(payload);
        setIsLoading(false);
        if (response?.data?.success) {
          handleModelClose();
          navigate(`/charging_station/details/${response?.data?.data?.id}`);
          toaster.show(false, 'Charging station created successfully');
        }
      } else {
        toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
      }
    } catch (error) {
      setIsLoading(false);
      toaster.error(error);
    }
  };

  const validateForm = () => {
    const result = Object.keys(form).every((key) => {
      console.log(key, form.hasOwnProperty(key) ,form[key]?.toString()?.trim() !== '')
      return form.hasOwnProperty(key) && form[key]?.toString()?.trim() !== '';
    });
    return result && form?.mobileNumber?.toString().length === 10;
  };

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        toggle={handleModelClose} 
        centered
        size='lg'
      >
        <ModalHeader toggle={handleModelClose}>
          Create Charging Station
        </ModalHeader>
        <ModalBody>
          <Row>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <TextInput
              label={'Latitude'}
              name={'lat'}
              value={form.lat}
              handleInput={handleInput}
              type={'number'}
              required={true}
            />
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <TextInput
              label={'Longitude'}
              name={'lng'}
              value={form.lng}
              handleInput={handleInput}
              type={'number'}
              required={true}
            />
          </div>
          <div className='mt-4 col-lg-6 col-md-6 col-sm-12'>
            <TextInput
              label={'CS Display No'}
              name={'csDisplayno'}
              value={form.csDisplayno}
              handleInput={handleInput}
              type={'text'}
              required={true}
            />
          </div>
          <div className='mt-4 col-lg-6 col-md-6 col-sm-12'>
            <TextInput
              label={'Station Name'}
              name={'stationName'}
              value={form.stationName}
              handleInput={handleInput}
              type={'text'}
              required={true}
            />
          </div>
          <div className='mt-4 col-lg-6 col-md-6 col-sm-12'>
            <TextInput
              label={'Address'}
              name={'address'}
              value={form.address}
              handleInput={handleInput}
              type={'text'}
              required={true}
            />
          </div>
          <div className='mt-4 col-lg-6 col-md-6 col-sm-12'>
            <TextInput
              label={'City Name'}
              name={'cityName'}
              value={form.cityName}
              handleInput={handleInput}
              type={'text'}
              required={true}
            />
          </div>
          <div className='mt-4 col-lg-6 col-md-6 col-sm-12'>
            <TextInput
              label={'Pin Code'}
              name={'pinCode'}
              value={form.pinCode}
              handleInput={handleInput}
              type={'number'}
              required={true}
              maxLength={6}
            />
          </div>
          <div className='mt-4 col-lg-6 col-md-6 col-sm-12'>
            <TextInput
              label={'Owner'}
              name={'stOwner'}
              value={form.stOwner}
              handleInput={handleInput}
              type={'text'}
              required={true}
            />
          </div>
          <div className='mt-4 col-lg-6 col-md-6 col-sm-12'>
            <TextInput
              label={'Company Name'}
              name={'companyName'}
              value={form.companyName}
              handleInput={handleInput}
              type={'text'}
              required={true}
            />
          </div>
          <div className='mt-4 col-lg-6 col-md-6 col-sm-12'>
            <TextInput
              label={'Mobile Number'}
              name={'mobileNumber'}
              value={form.mobileNumber}
              handleInput={handleInput}
              type={'number'}
              maxLength={10}
              required={true}
            />
          </div>
          <div className='mt-4 col-lg-6 col-md-6 col-sm-12'>
            <TextInput
              label={'Contact Person'}
              name={'contactPerson'}
              value={form.contactPerson}
              handleInput={handleInput}
              type={'text'}
              required={true}
            />
          </div>
          <div className='mt-4 col-lg-6 col-md-6 col-sm-12'>
            <TextInput
              label={'Amenities'}
              name={'amenities'}
              value={form.amenities}
              handleInput={handleInput}
              type={'text'}
              required={true}
            />
          </div>
          <div className='mt-4 col-lg-6 col-md-6 col-sm-12'>
            <TextInput
              label={'Average Cost (Discom)'}
              name={'avgCostDiscom'}
              value={form.avgCostDiscom}
              handleInput={handleInput}
              type={'number'}
              required={true}
            />
          </div>
          <div className='mt-4 col-lg-6 col-md-6 col-sm-12'>
            <TextInput
              label={'Opening Time'}
              name={'openingTime'}
              value={form.openingTime}
              handleInput={handleInput}
              type={'text'}
              required={true}
            />
          </div>
          <div className='mt-4 col-lg-6 col-md-6 col-sm-12'>
            <TextInput
              label={'Closing Time'}
              name={'closingTime'}
              value={form.closingTime}
              handleInput={handleInput}
              type={'text'}
              required={true}
            />
          </div>
          <div className='mt-4 col-lg-6 col-md-6 col-sm-12'>
          <Label check>
            <Input
              type="checkbox"
              id="wkStatus"
              name="wkStatus"
              checked={form.wkStatus}
              onChange={(e) => handleCheckbox('wkStatus', e.target.checked)}
            />
             <span className="ms-2">WK Status</span>
          </Label>
          </div>
          <div className='mt-4 col-lg-6 col-md-6 col-sm-12'>
          <Label check>
            <Input
              type="checkbox"
              id="isFourWheeler"
              name="isFourWheeler"
              checked={form.isFourWheeler}
              onChange={(e) => handleCheckbox('isFourWheeler', e.target.checked)}
            />
             <span className="ms-2">Four Wheeler</span>
          </Label>
          </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
            Close
          </Button>
          <LoadingButton
            buttonContent={'Create'}
            isLoading={isLoading}
            onClick={handleChargingStationCreation}
            color={'success'}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreateChargingStation;
