import React, { useState } from 'react';
import useClass from '../../../../hooks/useClass';
import { createCampaignSMSTemplate } from '../../../../store/campaign/template';
import { Button, Form, FormGroup } from 'reactstrap';
import TextInput from '../../../../components/text-input/TextInput';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { messageType } from '../../../../constants/constants.js';

const SMS = () => {

  const { dispatch } = useClass();
  const [form, setForm] = useState({
    smsText:'',
    description:'',
    dltId:'',
    senderId:'',
    smsType:'',
  });

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateSmsForm()) {
      dispatch(createCampaignSMSTemplate(form));
    }
  }

  const validateSmsForm = () => {
    const result = Object.keys(form).every((key) => {
      if(typeof(form[key]) == 'object') return true
      return form.hasOwnProperty(key) && form[key].trim() !== '';
    });
    return result;
  }

  return (
    <>
      <Form className='p-3' onSubmit={(e) =>handleSubmit(e,'sms')}>
        <FormGroup>
          <TextInput
            label={'SMS Text'}
            value={form.smsText}
            type={'textarea'}
            required={true}
            name={'smsText'}
            handleInput={handleInput}
          />
        </FormGroup>
        <FormGroup>
          <TextInput 
            label={'SMS Description'}
            value={form.description}
            type={'textarea'}
            required={true}
            name={'description'}
            handleInput={handleInput}
          />       
        </FormGroup>
        <FormGroup>
          <TextInput 
            label={'DLT ID'}
            value={form.dltId}
            type={'textarea'}
            maxLength={19}
            required={true}
            name={'dltId'}
            handleInput={handleInput}
          />
        </FormGroup>
        <FormGroup>
          <TextInput 
            label={'Sender Id'}
            value={form.senderId}
            type={'textarea'}
            required={true}
            name={'senderId'}
            handleInput={handleInput}
          />
        </FormGroup>
        <FormGroup>
        <Dropdown
          label={'Select  SMS Type'}
          value={form?.emailType}
          required={true}
          name={'smsType'}
          handleInput={handleInput}
          options ={messageType?.map((type) => ({label: type?.label, value: type?.value}))}
          />
        </FormGroup>
        <Button className='w-100 mt-2' color="primary" type='submit'>Create SMS</Button>
      </Form>
    </>
  )
}

export default React.memo(SMS);
