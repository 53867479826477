import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner, Badge } from "reactstrap";
import useClass from "../../../hooks/useClass";
import { useSelector } from "react-redux";
import { getSegmentDetail } from "../../../store/campaign/segment";

const Details = () => {
  const { dispatch } = useClass();
  const { segmentId } = useParams();
  const { detail, loading } = useSelector((state) => state?.campaignSegment);

  useEffect(() => {
    dispatch(getSegmentDetail(segmentId));
  }, [dispatch, segmentId]);

  if (loading) {
    return (
      <Container fluid className="text-center">
        <Spinner color="primary" />
      </Container>
    );
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="segment" breadcrumbItem="Details" />
        <Row>
          <Col xl={12}>
            <Card className="shadow-lg mb-4">
            <CardHeader>
                  <div className="d-flex gap-4 justify-content-between">
                    <div className="section-heading">Segment Details</div>
                  </div>
                </CardHeader>
              <CardBody>
                <Row>
                  <Col xl={4} md={6} sm={12} className="d-flex gap-1 mb-3">
                    <strong>Segment Name:</strong>
                    <div>{detail?.name || "-"}</div>
                  </Col>
                  <Col xl={4} md={6} sm={12} className="d-flex gap-1 mb-3">
                    <strong>Segment Code:</strong>
                    <div>{detail?.code || "-"}</div>
                  </Col>
                  <Col xl={4} md={6} sm={12} className="d-flex gap-1 mb-3">
                    <strong>Description:</strong>
                    <div>{detail?.description || "-"}</div>
                  </Col>
                  <Col xl={4} md={6} sm={12} className="d-flex gap-1 mb-3">
                    <strong>Created By:</strong>
                    <div>{detail?.createdBy || "-"}</div>
                  </Col>
                  <Col xl={4} md={6} sm={12} className="d-flex gap-1 mb-3">
                    <strong>Created Date:</strong>
                    <div>
                      {detail?.createdAt ? new Date(detail.createdAt).toLocaleDateString() : "-"}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="d-flex justify-content-around">
            <Col xl={4} md={6} sm={12} className="mb-3">
                    <strong>Filter Query Where:</strong>
                    <div style={{ maxHeight: "150px", overflowY: "auto", border: "1px solid #dee2e6", padding: "10px", background:"white" }}>
                      {detail?.filterQueryWhere || "-"}
                    </div>
                  </Col>
                  <Col xl={4} md={6} sm={12} className="mb-3">
                    <strong>Filter Query Json:</strong>
                    <div style={{ maxHeight: "150px", overflowY: "auto", border: "1px solid #dee2e6", padding: "10px", whiteSpace: 'pre-wrap', wordWrap: 'break-word', background:"white" }}>
                      {detail?.fiterQueryJson ? (
                        JSON.stringify(detail.fiterQueryJson, null, 2)
                      ) : (
                        "-"
                      )}
                    </div>
                  </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Details;
