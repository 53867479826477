import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HotLeads from '../Pages/Hot-Lead/HotLeads';
import Details from '../Pages/Hot-Lead/Details';

const HotLead = () => {

  return (
    <Routes>
      <Route path='/list' element={<HotLeads />} />
      <Route path='/details/:leadCode' element={<Details />} />
    </Routes>
  )
}

export default HotLead;
