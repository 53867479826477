import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import TextInput from '../../../components/text-input/TextInput';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { FILL_ALL_MENDETORY_FIELDS } from '../../../utils/error';

const CreateVehicle = (props) => {
  const { toaster, oemServer, permission, navigate } = useClass();
  const { isOpen, setIsOpen } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [manufactureOptions, setManufactureOptions] = useState([]);
  const [form, setForm] = useState({
    assetOemCode: '',
    modelName: '',
    variantName: '',
    modelYear: ''
  });

  useEffect(() => {
    const fetchManufactures = async () => {
      try {
        const response = await oemServer.getManufacture(); 
        const options = response.data.data.map(oem => ({
          label: oem.name,
          value: oem.code
        }));
        setManufactureOptions(options);
      } catch (error) {
        toaster.error(error);
      }
    };

    fetchManufactures();
  }, []);

  const handleModelClose = () => {
    setIsOpen(false);
  };

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleVehicleCreation = async () => {
    try {
      if (validateForm()) {
        setIsLoading(true);
        const payload = {
          ...form,
          assetOemCode: form?.assetOemCode?.value, // Ensure it's a string
        };
        const response = await oemServer.createVehicle(payload); 
        setIsLoading(false);
        handleModelClose();
        navigate(`/vehicle/details/${response?.data?.data?.code}`);
        toaster.show(false, 'Vehicle created successfully');
      } else {
        toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
      }
    } catch (error) {
      setIsLoading(false);
      toaster.error(error);
    }
  };

  const validateForm = () => {
    const result = Object.keys(form).every((key) => {
        return form.hasOwnProperty(key) && form[key] !== "";
    });

    return result;
  }

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        toggle={handleModelClose} 
        centered
        size='lg'
      >
        <ModalHeader toggle={handleModelClose}>
          Create Vehicle
        </ModalHeader>
        <ModalBody>
          <Row>
          <div className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <Dropdown
              label="Manufacturer"
              required={true}
              name="assetOemCode"
              value={form.assetOemCode}
              handleInput={handleInput}
              options={manufactureOptions}
              placeholder="Select Manufacturer"
            />
          </div>
          <div className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <TextInput
              label="Model Name"
              required={true}
              name="modelName"
              value={form.modelName}
              handleInput={handleInput}
              type="text"
            />
          </div>
          <div className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <TextInput
              label="Variant Name"
              required={true}
              name="variantName"
              value={form.variantName}
              handleInput={handleInput}
              type="text"
            />
          </div>
          <div className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <TextInput
              label="Model Year"
              required={true}
              name="modelYear"
              value={form.modelYear}
              handleInput={handleInput}
              type="text"
            />
          </div>
          <div className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <TextInput
              label="Category"
              name="category"
              value={form.category}
              handleInput={handleInput}
              type="text"
            />
          </div>
          <div className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <TextInput
              label="Range"
              name="range"
              value={form.range}
              handleInput={handleInput}
              type="text"
            />
          </div>
          <div className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <TextInput
              label="Battery Capacity"
              name="batteryCapacity"
              value={form.batteryCapacity}
              handleInput={handleInput}
              type="text"
            />
          </div>
          <div className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <TextInput
              label="Energy Consumption"
              name="energyConsumption"
              value={form.energyConsumption}
              handleInput={handleInput}
              type="text"
            />
          </div>
          <div className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <TextInput
              label="Max Speed"
              name="maxSpeed"
              value={form.maxSpeed}
              handleInput={handleInput}
              type="text"
            />
          </div>
          <div className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <TextInput
              label="Warranty"
              name="warranty"
              value={form.warranty}
              handleInput={handleInput}
              type="text"
            />
          </div>
          <div className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <TextInput
              label="CO2 Emission Per Km"
              name="co2EmissionPerKm"
              value={form.co2EmissionPerKm}
              handleInput={handleInput}
              type="text"
            />
          </div>
          <div className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <TextInput
              label="Cost Per Km"
              name="costPerKm"
              value={form.costPerKm}
              handleInput={handleInput}
              type="text"
            />
          </div>
          <div className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <TextInput
              label="Min Price"
              name="minPrice"
              value={form.minPrice}
              handleInput={handleInput}
              type="number"
            />
          </div>
          <div className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <TextInput
              label="Max Price"
              name="maxPrice"
              value={form.maxPrice}
              handleInput={handleInput}
              type="number"
            />
          </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" onClick={handleModelClose} className="btn btn-secondary">
            Close
          </Button>
          <LoadingButton
            buttonContent="Create"
            isLoading={isLoading}
            onClick={handleVehicleCreation}
            color="success"
          />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreateVehicle;
