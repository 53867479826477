import React, { useEffect, useState } from 'react';
import { Row, Nav, NavItem, NavLink, TabContent, TabPane, Col, Spinner, Button } from 'reactstrap';
import Table from '../../../components/Common/Table';
import { useSelector } from 'react-redux';
import childLoanColumns from './ChildLoanColumns';
import lenderColumns from './LenderColumns';
import useClass from '../../../hooks/useClass';
import { loanData, rupeeSymbol } from '../../../utils/constants';
import LenderMappingBRE from '../BRE/LenderMappingBRE';
import { updateActiveLoanIndex } from '../../../store/customer/application/applicationAction';
import CAMBoard from '../../../components/cam/CAMBoard';
import { toggleBREFieldsModal } from '../../../store/customer/bre/actions';
import BREFields from '../BRE/BREFields';
import { getLenderConfigFields } from '../../../store/utils/actions';

const Loans = () => {

  const { dispatch } = useClass();
  const { activeApplicationIndex, activeLoanIndex, applicationList: applications, loading } = useSelector((state) => (state?.applicationAction));
  const {list: lenderList, loading: eligibleLenderLoader} = useSelector((state) => (state?.eligibleLenders))
  const [newLenderList, setNewLenderList] = useState([]);

  useEffect(() => {
    const arr = lenderList?.filter((obj) => (
      (obj?.type === 'LENDER' && applications?.[activeApplicationIndex]?.productType === 'LOAN') || 
      (obj?.type === 'INSURER' && applications?.[activeApplicationIndex]?.productType === 'INSURANCE'))
    );
    computeChildLoanData(arr);
  }, [applications, activeApplicationIndex, activeLoanIndex, lenderList]);

  const computeChildLoanData = (arr) => {
    let pendingLenders = [];
    if(applications[activeApplicationIndex] && arr.length > 0){
      for(const lender of arr){
        let newObj = {...lender, lenderCode: lender.code};
        if(applications[activeApplicationIndex]?.loan?.[activeLoanIndex]?.childLoans && applications[activeApplicationIndex]?.productType === 'LOAN'){
          for(const child of applications[activeApplicationIndex]?.loan?.[activeLoanIndex]?.childLoans){
            if(child?.lenderCode === lender.code){
              newObj = null;
            }
          }
        }
        if(newObj){
          pendingLenders = [...pendingLenders, newObj];
        }
      }
    }
    setNewLenderList(pendingLenders);
  }

  const toggleTab = (id) => {
    return () => {
      if(id === activeLoanIndex){
        return;
      }
      dispatch(updateActiveLoanIndex(id))
    }
  }

  const handleBREUpdate = () => {
    dispatch(getLenderConfigFields());
    dispatch(toggleBREFieldsModal());
  }

  return (
    <>
      <Row>
          {applications?.length > 0 && 
            <>
              <Nav tabs>
                {applications?.[activeApplicationIndex]?.loan?.map((loan, index) => (
                  <NavItem key={`loan${index}`}>
                    <NavLink 
                      style={{color: activeLoanIndex === index && 'green' }} 
                      className={`${activeLoanIndex === index && 'active'} clickable`} 
                      onClick={toggleTab(index)}
                    >
                      <i className="bx bx-coin-stack me-1 align-middle"> </i>{" "}
                      {loan?.uid}-{loan?.productType}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={activeLoanIndex} className="p-3">
                <TabPane tabId={activeLoanIndex} id="home">
                  <Row>
                    {applications?.[activeApplicationIndex]?.loan?.length > 0 && <>
                      {Object?.keys(applications?.[activeApplicationIndex]?.loan?.[activeLoanIndex]).map((key, index) => {
                        if(loanData[key]){
                          return (
                            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={`child${index}`}>
                              <Row className='data-key ms-4'>{loanData[key]}</Row>
                              <Row className='data-value me-4'>{rupeeSymbol?.includes(key) && '₹'} {applications?.[activeApplicationIndex]?.loan?.[activeLoanIndex]?.[key] || '-'}</Row>
                            </div>
                          )
                        }
                      })}
                    </>}
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <div style={{fontSize: '16px', fontWeight: 'bold'}} className='text-dark'>Child Loans</div>
                    </Col>
                  </Row>
                  {applications[activeApplicationIndex]?.loan?.[activeLoanIndex]?.childLoans?.length > 0 ? <Row>
                      <Col>
                        <Table
                          columns={childLoanColumns}
                          loading={loading}
                          data={applications[activeApplicationIndex]?.loan?.[activeLoanIndex]?.childLoans}
                          showPagination={false}
                          expandableRows={false}
                        />
                      </Col>
                    </Row> : 
                    <Row>
                      <Row className='mt-4 d-flex justify-content-center'> No child loan created yet</Row>
                    </Row>}
                  <hr />
                  <Row>
                    <Col>
                      <div className='d-flex justify-content-between mb-2'>
                        <div style={{fontSize: '16px', fontWeight: 'bold'}} className='text-dark'>Eligible Lenders</div>
                        <div><Button size='sm' color='info' onClick={handleBREUpdate}>Update BRE</Button></div>
                      </div>
                    </Col>
                  </Row>
                  {newLenderList?.length > 0 ? <Row>
                    <Col xl={12}>
                      <Table
                        columns={lenderColumns}
                        loading={loading}
                        data={newLenderList}
                        showPagination={false}
                        expandableRows={true}
                        ExpandedComponent={LenderMappingBRE}
                      />
                    </Col>
                  </Row> : <Row className='mt-4'>
                      {eligibleLenderLoader ? 
                      <Row className='d-flex justify-content-center'><Spinner /></Row> :
                      <Row className='d-flex justify-content-center'> No eligible lender</Row>}
                    </Row>}
                </TabPane>
              </TabContent>
            </>
          }
      </Row>
      <CAMBoard />
      <BREFields />
    </>
  )
}

export default React.memo(Loans);
