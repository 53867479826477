import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import TextInput from '../../../components/text-input/TextInput';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { FILL_ALL_MENDETORY_FIELDS } from '../../../utils/error';

// Dummy data for battery types
const batteryTypes = [
  { label: 'Lead Acid', value: 'LEAD_ACID' },
  { label: 'Lithium Ion', value: 'LITHIUM_ION' },
  { label: 'Nickel Metal Hydride', value: 'NICKEL_METAL_HYDRIDE' }
];

const CreateBatteryModel = (props) => {
  const { toaster, navigate,oemServer } = useClass();
  const { isOpen, setIsOpen } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [manufactureOptions, setManufactureOptions] = useState([]);
  const [form, setForm] = useState({
    make: '',
    capacity: '',
    type: 'LEAD_ACID', 
    warranty: '',
    description: ''
  });

  useEffect(() => {
    const fetchManufactures = async () => {
      try {
        const response = await  oemServer.getManufacture(); 
        const options = response.data.data.map(oem => ({
          label: oem.name,
          value: oem.code
        }));
        setManufactureOptions(options);
      } catch (error) {
        toaster.error(error);
      }
    };

    fetchManufactures();
  }, []);



  const handleModelClose = () => {
    setIsOpen(false);
  };

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleBatteryCreation = async () => {
    try {
      setIsLoading(true);
      if (validateForm()) {
        const payload = {
          ...form,
          make: form?.make?.value,
          type:form?.type?.value,
          capacity: parseFloat(form.capacity) // Ensure capacity is a number
        };
        const response = await oemServer.createBattery(payload);
        setIsLoading(false);
        handleModelClose();
        navigate(`/battery/details/${response?.data?.data?.code}`);
        toaster.show(false, 'Battery model created successfully');
      } else {
        toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
      }
    } catch (error) {
      setIsLoading(false);
      toaster.error(error);
    }
  };

  const validateForm = () => {
    const result = Object.keys(form).every((key) => {
        return form.hasOwnProperty(key) && form[key] !== "";
    });
    return result;
  }

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        toggle={handleModelClose} 
        centered
      >
        <ModalHeader toggle={handleModelClose}>
          Create Battery Model
        </ModalHeader>
        <ModalBody>
          <div>
          <Dropdown
              label="Manufacturer"
              required={true}
              name="make"
              value={form.make}
              handleInput={handleInput}
              options={manufactureOptions}
              placeholder="Select Manufacturer"
            />
          </div>

          <div className='mt-4'>
            <TextInput
              label={'Capacity'}
              required={true}
              name={'capacity'}
              value={form.capacity}
              handleInput={handleInput}
              type={'number'}
            />
          </div>

          <div className='mt-4'>
            <Dropdown
                  label={'Type'}
                  options={batteryTypes}
                  handleInput={handleInput}
                  required={true}
                  value={form?.type}
                  name={'type'}
                />
          </div>

          <div className='mt-4'>
            <TextInput
              label={'Warranty'}
              name={'warranty'}
              value={form.warranty}
              handleInput={handleInput}
              type={'text'}
            />
          </div>

          <div className='mt-4'>
            <TextInput
              label={'Description'}
              name={'description'}
              value={form.description}
              handleInput={handleInput}
              type={'text'}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
            Close
          </Button>
          <LoadingButton
            buttonContent={'Create'}
            isLoading={isLoading}
            onClick={handleBatteryCreation}
            color={'success'}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreateBatteryModel;
