import React, { useEffect, useState } from "react";
import useClass from "../../../hooks/useClass";
import { Card, CardHeader, CardBody } from "reactstrap";
import paymentsColumn from "./PaymentTableColumns";
import Table from "../../../components/Common/Table";
import Filters from "../../../components/Common/Filters";
import PaymentForm from "./create";
import PaymentModal from "./create";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCustomerPayments } from "../../../store/customer/payment";

const Payment = () => {

  const { customerCode } = useParams();
  const [paymentData, setPaymentData] = useState([]);
  //const [loading, setLoading] = useState(false);
  const [createPayment, setCreatePayment] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, perPage: 20 });
  const { toaster, server, permission, dispatch } = useClass();
  const { paymentDetails, loading } = useSelector((state) => (state?.payments));

  useEffect(() =>{
    setPaymentData(paymentDetails.map((currentpayment,paymentIndex) => {
      return {
        ...currentpayment,index:paymentIndex+1
      }
    }))
  },[paymentDetails])
  const getCustomerPaymentDetails = (params) =>{
    const { pagination, searchText, dateRange } = params;
     
      dispatch(getCustomerPayments({
      page:pagination?.page,
      perPage: pagination?.perPage,
      search: searchText,
      customerCode:  customerCode
    }))
  }
  const columns = paymentsColumn(permission);
  
  return (
    <>
      <Card className="common-card-style">
        <CardHeader className="d-flex justify-content-between">
          <h5 className="card-title mb-0">Payments</h5>
          <Filters
            fetchDataFromServer={getCustomerPaymentDetails}
            pagination={pagination}
            setPagination={setPagination}
            showCreateButton={permission?.PARTNERS?.LIST?.CREATE_NEW_PARTNER}
            onCreateButtonClick={() => setCreatePayment(true)}
          />
        </CardHeader>
        <CardBody>
          <Table
            data={paymentData}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
            columns={columns}
            paginationTotalRows={paymentDetails.length}
          />
        </CardBody>
      </Card>
      <PaymentModal isOpen={createPayment} setIsOpen={setCreatePayment} customerCode={customerCode}  />
    </>
  );
};

export default Payment;
