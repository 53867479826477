import { createSlice } from "@reduxjs/toolkit";



const configUtils = createSlice({
    name: 'configUtils',
    initialState: {
        loading: false,
        error: null,
        lenderConfigFields: null
    },
    reducers: {
        getLenderConfigFields: (state, action) => {
            state.loading = true;
            state.lenderConfigFields = null;
        },
        getLenderConfigFieldsSuccess: (state, action) => {
            state.loading = false;
            state.lenderConfigFields = action.payload;
        },
        getLenderConfigFieldsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const {
    getLenderConfigFields,
    getLenderConfigFieldsSuccess,
    getLenderConfigFieldsError
} = configUtils?.actions;
  
// reducers
export const utilsReducer = {
    configUtils: configUtils.reducer 
}