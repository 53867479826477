// yourSaga.js
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { 
    getApplicationLogs, 
    getApplicationLogsError, 
    getApplicationLogsSuccess,
    getComment,
    getCommentError,
    getCommentSuccess,
    getCustomerApplicationList, 
    getCustomerApplicationListError, 
    getCustomerApplicationListSuccess, 
    getCustomerBasicDetails, 
    getCustomerBasicDetailsError, 
    getCustomerBasicDetailsSuccess, 
    getEligibleLendersOfApplication, 
    getEligibleLendersOfApplicationError, 
    getEligibleLendersOfApplicationSuccess, 
    getEntityDetails, 
    getEntityDetailsError, 
    getEntityDetailsSuccess,
    sendMCADocRequest,
    sendMCADocRequestError,
    sendMCADocRequestSuccess,
    updateComment,
    updateCommentError,
    updateCommentSuccess,
} from './customer';
import { 
    getCustomerPayments,
    updateCustomerPayment, 
    updateCustomerPaymentSuccess, 
    getCustomerPaymentsError, 
    getCustomerPaymentsSuccess 
} from './payment';
import { getCustomerEmiScheduleLoading, getCustomerEmiScheduleSuccess } from './emiSchedule';
import loanSaga from './loan/saga';
import applicationSaga from './application/saga';
import { ApiService } from '../../service/ApiService';
import { Toaster } from '../../components/toaster/Toaster';
import { updateApplicationDataSuccess } from './application/Assesment';
import camSaga from './cam/saga';
import breSaga from './bre/saga';
import { handleApplicationFieldApproveRejectSuccess } from './application/applicationAction';
const server = new ApiService();
const toaster = new Toaster();

// Watcher saga: spawns a new fetchData task on each application action

function* watchCustomerApplicationList() {
    yield takeEvery(getCustomerApplicationList.type, fetchCustomerApplicationList);
}

function* watchCustomerBasicDetails() {
    yield takeEvery(getCustomerBasicDetails.type, fetchCustomerBasicDetails);
}

function* watchEntityDetails() {
    yield takeEvery([
        getEntityDetails.type, 
        updateApplicationDataSuccess.type,
        sendMCADocRequestSuccess.type,
        handleApplicationFieldApproveRejectSuccess.type
    ], fetchEntityDetails);
}

function* watchMCADocRequest() {
    yield takeEvery(sendMCADocRequest.type, requestMCADocs);
}

function* watchCustomerpayments() {
    yield takeEvery(getCustomerPayments.type, fetchCustomerPayments);
}

function* watchUpdateCustomerPayments() {
    yield takeEvery(updateCustomerPayment.type, updateCustomerPayments);
}

function* watchCustomerEmiScedule() {
    yield takeEvery(getCustomerEmiScheduleLoading.type, fetchCustomerEmiSchedule);
}

function* watchEligibleLenders() {
    yield takeEvery(getEligibleLendersOfApplication.type, fetchEligibleLenders);
}

function* watchApplicationLogs() {
    yield takeEvery(getApplicationLogs.type, fetchApplicationLogs);
}

function* watchGetComment() {
    yield takeEvery(getComment.type, fetchComment);
}

function* watchUpdateComment() {
    yield takeEvery(updateComment.type, sendComment);
}



// updateCustomerPayments

const fetchCustomerApplicationListFromServer = async (payload) => (
    await server.getApplicationList(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate, payload?.type).then((response) => (response)).catch((error) => (error))
)




// Worker saga: will be fired on respective actions
function* fetchCustomerApplicationList(action) {
    try {
        const response = yield call(fetchCustomerApplicationListFromServer, {page: action?.payload?.page, perPage: action?.payload?.perPage, search: action.payload.search, dateRange: action?.payload?.dateRange, type: action?.payload?.type});
        const { data, count } = response?.data;
        data.forEach((data, index) => {
            data.id = index + 1;
        });
        yield put(getCustomerApplicationListSuccess({data, count}));
    } catch(error){
        toaster.error(error);
        yield put(getCustomerApplicationListError(error));
    }
}

function* fetchCustomerBasicDetails(action) {
    try {
        const response = yield call(server.getBasicDetailsOfCustomer, action?.payload);
        yield put(getCustomerBasicDetailsSuccess(response?.data?.data));
    } catch(error){
        toaster.error(error);
        yield put(getCustomerBasicDetailsError(error));
    }
}

function* fetchEntityDetails(action) {
    try {
        const response = yield call(server.getCustomerEntityDetails, action?.payload);
        const data = response?.data?.data?.[0] || {};
        const docs = data?.entityDoc;
        const mcaDocs = docs?.filter((obj) => (obj?.docSource === 'mca'));
        yield put(getEntityDetailsSuccess({data, mcaDocs}));
    } catch(error){
        toaster.error(error);
        yield put(getEntityDetailsError(error));
    }
}

function* requestMCADocs({payload}) {
    try {
        const response = yield call(server.requestMCADocs, payload);
        yield put(sendMCADocRequestSuccess(payload.customerCode));
    } catch(error) {
        toaster.error(error);
        yield put(sendMCADocRequestError(error));
    }
}


function* fetchCustomerPayments(action) {
    try {
        const response = yield call(server.getPayments, action?.payload);
        const data = response?.data?.data || [];
        yield put(getCustomerPaymentsSuccess(data))
    } catch(error){
        toaster.error(error);
        yield put(getCustomerPaymentsError(error))
    }
}


function* updateCustomerPayments(action) {
    try {
        const response = yield call(server.createNewPayment, action?.payload);
        const data = response?.data?.data || {};
        yield put(updateCustomerPaymentSuccess(data))
    } catch(error){
        toaster.error(error);
        yield put(getCustomerPaymentsError(error))
    }
}

function* fetchCustomerEmiSchedule(action) {
    try {
        const response = yield call(server.getLoanEmiSchedule, action?.payload?.customerCode);
        const data = response?.data?.data || {};
        yield put(getCustomerEmiScheduleSuccess(data))
    } catch(error){
        toaster.error(error);
        yield put(getCustomerPaymentsError(error))
    }
}

function* fetchEligibleLenders(action) {
    try {
        const response = yield call(server.getEligibleLenders, action?.payload);
        yield put(getEligibleLendersOfApplicationSuccess(response.data.data));
    } catch(error) {
        toaster.error(error);
        yield put(getEligibleLendersOfApplicationError(error));
    }
}

function* fetchApplicationLogs(action) {
    try {
        const response = yield call(server.getApplicationLogs, action?.payload);
        yield put(getApplicationLogsSuccess(response?.data?.data));
    } catch(error) {
        toaster.error(error);
        yield put(getApplicationLogsError(error));
    }
}

function* fetchComment(action) {
    try {
        const response = yield call(server.getComment, action?.payload);
        yield put(getCommentSuccess({data: response?.data?.data, code: action?.payload}));
    } catch(error) {
        toaster.error(error);
        yield put(getCommentError(error));
    }
}

function* sendComment(action) {
    try {
        const response = yield call(server.updateComment, action?.payload);
        yield put(updateCommentSuccess(response?.data?.data));
        toaster.show(false, 'Notes Updated Successfully');
    } catch(error) {
        toaster.error(error);
        yield put(updateCommentError(error));
    }
}


export default function* customerSaga(){
    yield all([
        fork(breSaga),
        fork(camSaga),
        fork(loanSaga),
        fork(applicationSaga),
        fork(watchCustomerApplicationList),
        fork(watchCustomerBasicDetails),
        fork(watchEntityDetails),
        fork(watchMCADocRequest),
        fork(watchCustomerpayments),
        fork(watchUpdateCustomerPayments),
        fork(watchCustomerEmiScedule),
        fork(watchEligibleLenders),
        fork(watchApplicationLogs),
        fork(watchGetComment),
        fork(watchUpdateComment)
    ])
};
