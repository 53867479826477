import JoditEditor from 'jodit-react';
import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux';
import useClass from '../../hooks/useClass';
import { storeChangedEditorValue } from '../../store/customer/cam/action';

const EditorMail = () => {

  const { dispatch } = useClass();
  const editorMail = useRef(null);
  const { cam, mail } = useSelector((state) => (state?.cam));

  const editorConfig = useMemo(() => ({
    placeholder: '',
    height: '60svh'
  }), []);

  const handleBlur = (key, value) => {
    const htmlContent = value; // Get HTML content
    const plainText = htmlContent.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ').trim();
    dispatch(storeChangedEditorValue({key, value}));
  };

  const handleMail = (e) => {
    if(e.target.checked){
      handleBlur('mail', cam);
      return;
    }
    handleBlur('mail', '');
  }

  return (
    <>
      <div className=' d-flex justify-content-end'>
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            id={'takeEmail'}
            onChange={handleMail}
          />
          <span
            className="form-check-label"
          >
            Import CAM in email body
          </span>
        </div>
      </div>
      <JoditEditor
        ref={editorMail}
		    value={mail}
        config={editorConfig}
		    onBlur={(v) => handleBlur('mail', v)}
      />
    </>
  )
}

export default React.memo(EditorMail);
