// yourSaga.js
import { call, put, takeEvery, all, fork, select } from 'redux-saga/effects';
import { Toaster } from '../../../components/toaster/Toaster';
import { 
    updateApplicationAction, 
    updateApplicationActionSuccess, 
    updateApplicationActionError, 
    getApplicationFieldStatusError,
    getApplicationFieldStatus,
    getApplicationFieldStatusSuccess,
    updateSaasApplicationStatus,
    updateSaasApplicationStatusError,
    getAdditionalDocList,
    getAdditionalDocListError,
    getAdditionalDocListSuccess,
    handleApplicationFieldApproveReject,
    handleApplicationFieldApproveRejectError,
    sendApplicationToLender,
    sendApplicationToLenderError,
    getApplicationList,
    getApplicationListSuccess,
    getApplicationListError,
    assignApplication,
    assignApplicationError,
    assignApplicationSuccess,
    handleApplicationFieldApproveRejectSuccess
} from './applicationAction';
import { ApiService } from '../../../service/ApiService';
import { AdminService } from '../../../service/AdminService';
import { 
    getPDQuestions, 
    getPDQuestionsError, 
    getPDQuestionsSuccess, 
    getPreviousPD, 
    getPreviousPDError, 
    submitPD, 
    submitPDError,
    submitPDSuccess,
    getPreviousPDSuccess,
    updateApplicationData,
    updateApplicationDataError,
    updateApplicationDataSuccess
} from './Assesment';
const server = new ApiService();
const toaster = new Toaster();
const adminServer = new AdminService();

// Watcher saga: spawns a new fetchData task on each application action
function* watchApplicationList() {
    yield takeEvery([
        getApplicationList.type,
        handleApplicationFieldApproveRejectSuccess.type
    ], fetchApplications);
}

function* watchApplicationAction() {
    yield takeEvery(updateApplicationAction.type, takeActionOnApplication);
}

function* watchApplicationFieldStatus() {
    yield takeEvery(getApplicationFieldStatus.type, fetchApplicationFieldStatus);
}

function* watchSaasApplicationAction() {
    yield takeEvery(updateSaasApplicationStatus.type, sendSaasApplicationStatus);
}

function* watchAdditionalDocList() {
    yield takeEvery(getAdditionalDocList.type, fetchAdditionalDocList);
}

function* watchFieldApproveReject() {
    yield takeEvery(handleApplicationFieldApproveReject.type, sendFieldForApproveReject);
}

function* watchSendToLender() {
    yield takeEvery(sendApplicationToLender.type, sendToLender);
}

function* watchApplicationAssignment() {
    yield takeEvery(assignApplication.type, handleApplicationAssignment);
}

function* watchPDQuestions() {
    yield takeEvery(getPDQuestions.type, fetchPDQuestions);
}

function* watchPreviousPD() {
    yield takeEvery(getPreviousPD.type, fetchPreviosPD);
}

function* watchSubmitPD() {
    yield takeEvery(submitPD.type, sendPD);
}

function* watchApplicationFieldUpdate() {
    yield takeEvery(updateApplicationData.type, sendUpdatedApplicationData);
}



// Worker saga: will be fired on respective actions
function* fetchApplications(action) {
    try {
        const response = yield call(server.getCustomerApplicationsDetails, action?.payload);
        yield put(getApplicationListSuccess(response?.data?.data));
    } catch(error) {
        toaster.error(error);
        yield put(getApplicationListError(error));
    }
}

function* takeActionOnApplication(action) {
    try {
        const response = yield call(server.takeActionOnLoan, action?.payload);
        yield put(updateApplicationActionSuccess(response?.data?.data));
        toaster.show(false, 'Successfully done');
        window.location.reload();
    } catch (error) {
        yield put(updateApplicationActionError(error));
        toaster.error(error);
    }
}

function* fetchApplicationFieldStatus(action) {
    try {
        const response = yield call(server.getApplicationFieldStatus, action?.payload);
        const {data} = response?.data;
        let payload = {};
        for(let i = 0; i < data?.length; i++){
            payload = {...payload, [data[i]?.fieldKey]: data?.[i]?.status}
        }
        yield put(getApplicationFieldStatusSuccess(payload));
    } catch(error){
        yield put(getApplicationFieldStatusError(error));
        toaster.error(error);
    }
}

function* sendSaasApplicationStatus(action) {
    try {
        const response = yield call(server.updateSaasApplication, action?.payload);
        window?.location?.reload();
    } catch(error) {
        toaster.error(error);
        yield put(updateSaasApplicationStatusError(error));
    }
}

function* fetchAdditionalDocList(action) {
    try {
        const response = yield call(server.getAdditionalDocList, {});
        let data = response?.data?.data?.map((obj) => ({...obj, label: obj?.fieldName, value: obj?.fieldCode}));
        yield put(getAdditionalDocListSuccess([...data, {label: 'Others', value: 'others'}]));
    } catch (error) {
        toaster.error(error);
        yield put(getAdditionalDocListError(error));
    }
}

function* sendFieldForApproveReject({payload}) {
    try {
        const result = yield toaster.confirmation(payload?.action?.label);
        if(!result?.isConfirmed){
            return;
        }
        const payload2 = {
            status: payload?.action?.code, 
            field: payload?.field, 
            applicationId: payload?.applicationId, 
            customerCode: payload?.customerCode
        }
        const response = yield call(server.updateApplicationFieldStatus, payload2);
        toaster.success('Succesfully updated');
        yield put(handleApplicationFieldApproveRejectSuccess(payload?.customerCode));
    } catch(error) {
        toaster.error(error);
        yield put(handleApplicationFieldApproveRejectError(error));
    }
}

function* sendToLender(action) {
    try {
        const result = yield toaster.confirmation('Send');
        if(!result?.isConfirmed){
            yield put(sendApplicationToLenderError({}));
            return;
        }
        const response = yield call(server.createChildLoan, action?.payload?.childLoanPayload);
        const lenderResponse = yield call(server.sendToLender, action?.payload?.lenderPayload);
        toaster.show(false, 'Email sent');
        window.location.reload();
    } catch(error) {
        toaster.error(error);
        yield put(sendApplicationToLenderError(error));
    }
}

function* handleApplicationAssignment(action) {
    try {
        const permission = yield select((state) => (state?.permissionPair?.permissions));
        if(!permission?.CUSTOMERS?.DETAILS?.ASSIGN_APPLICATION_TO_USER){
            toaster.show(true, `You don't have permission`);
            return;
        }
        const { team, user=null, applications } = action?.payload;
        const result = yield toaster.confirmation('Assign', `Assign ${applications} to ${user ? user.name : team.name}`);
        if(!result.isConfirmed){
            return;
        }
        const payload = {
            teamId: team.id, 
            userId: user?.userId, 
            applicationIds: applications
        }
        const response = yield call(adminServer.assignApplication, payload);
        yield put(assignApplicationSuccess());
        toaster.success('Assigned Successfully');
        window.location.reload();
    } catch(error) {
        toaster.error(error);
        yield put(assignApplicationError(error));
    }
}

function* fetchPDQuestions({payload}) {
    try {
        const response = yield call(adminServer.getPDQuestions, payload);
        let question = response?.data?.data;
        if(question?.length === 0){
            question = [payload];
        }
        yield put(getPDQuestionsSuccess(question));
    } catch(error) {
        toaster.error(error);
        yield put(getPDQuestionsError(error));
    }
}

function* fetchPreviosPD({payload}) {
    try {
        const response = yield call(adminServer.getPreviousPD, payload);
        yield put(getPreviousPDSuccess(response?.data?.data))
    } catch(error) {
        toaster.error(error);
        yield put(getPreviousPDError(error));
    }
}

function* sendPD({payload}) {
    try {
        const response = yield call(adminServer.submitPD, payload);
        toaster.success('PD saved successfully');
        yield put(submitPDSuccess());
    } catch(error) {
        toaster.error(error);
        yield put(submitPDError(error));
    }
}

function* sendUpdatedApplicationData({payload}) {
    try {
        const response = yield call(adminServer.updateApplicationData, payload);
        toaster.success('Data updated successfully');
        yield put(updateApplicationDataSuccess(payload.customerCode));
    } catch(error) {
        toaster.error(error);
        yield put(updateApplicationDataError(error));
    }
}


export default function* applicationSaga(){
    yield all([
        fork(watchApplicationList),
        fork(watchApplicationAction),
        fork(watchApplicationFieldStatus),
        fork(watchSaasApplicationAction),
        fork(watchAdditionalDocList),
        fork(watchFieldApproveReject),
        fork(watchSendToLender),
        fork(watchApplicationAssignment),
        fork(watchPDQuestions),
        fork(watchPreviousPD),
        fork(watchSubmitPD),
        fork(watchApplicationFieldUpdate)
    ])
};
