import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Tasks from '../Pages/Task/Tasks';
import Details from '../Pages/Task/Details';

const Task = () => {

  return (
    <Routes>
      <Route path='/list' element={<Tasks />} />
      <Route path='/details/:taskCode' element={<Details />} />
    </Routes>
  )
}

export default Task;
