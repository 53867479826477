import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import useClass from '../../../hooks/useClass';
import { updateHotLeadDetails } from '../../../store/user/userSlice';
import { useSelector } from 'react-redux';
import { FILL_ALL_MENDETORY_FIELDS } from '../../../utils/error';
import LoadingButton from '../../../components/Common/LoadingButton';
import EntityDetails from './EntityDetails';
import { formatDate3 } from '../../../utils/helper';

const Update = (props) => {

  const {isOpen, setIsOpen} = props;
  const { loading, details } = useSelector((state) => (state?.hotLead));
  const { dispatch, toaster } = useClass();
  const [form, setForm] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if(details){
      setForm(details?.details);
    }
  }, [details]);

  const handleInput = (key, value) => {
    if(key === 'deadline') {
      value = formatDate3(value);
    }
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const updateLeadDetails = () => {
    setIsSubmitted(true);
    if (validateForm()) {
      let payload = {
        leadSource: 'CMS',
        status: 'INCOMPLETE',
        details: form
      }
      dispatch(updateHotLeadDetails({ payload, id: details?.code }));
    }
  }

  const validateForm = () => {
    let fields = [
      'entityType', 
      'entityName', 
      'businessType', 
      'yearOfIncorporation',
      'serviceCities',
      'currentFYRevenue',
      'currentFYPAT',
      'lastFYRevenue',
      'lastFYPAT',
      'selectedProducts',
      'loanAmount',
      'deadline'
    ]
    if(form?.businessType?.label?.includes('Fleet')){
      fields = [...fields,
        'fleetSize',
        'evsInFleet'
      ]
    }
    const result = fields?.every((key) => {
      return form?.[key] && form?.[key] !== "";
    });
    if(!result){
      toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
    }
    return result;
  }

  return (
    <>
      <Modal
        size='lg'
        isOpen={isOpen}
        toggle={() => {setIsOpen(!isOpen)} }
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Update Lead
          </h5>
          <button type="button" onClick={() => setIsOpen(!isOpen)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <EntityDetails handleInput={handleInput} form={form} isSubmitted={isSubmitted} />
        </div>
        <div className="modal-footer">
          <LoadingButton
            buttonContent={'Update'}
            isLoading={loading}
            onClick={updateLeadDetails}
            color={'success'}
          />
        </div>
      </Modal>
    </>
  )
}

export default React.memo(Update);
