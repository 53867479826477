import React from 'react';
import ScoreChart from '../../../components/Charts/ScoreChart';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

const TapFinScore = ({isOpen, setIsOpen, name}) => {

  return (
    <>
      <Modal
        isOpen={isOpen}
        size='xl'
        toggle={() => setIsOpen(!isOpen)}
        centered
      >
        <ModalHeader>
          TapFin Score - <span className='text-primary'>{name}</span>
          <button type="button" onClick={() => setIsOpen(!isOpen)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <ScoreChart percentage={'73'} value={'790'} />
            </Col>
          </Row>
          <Row style={{backgroundColor: 'cornsilk'}} className='p-2'>
            <Col lg={4} md={4} sm={4} xs={4}>
              Vehicle Details
            </Col>
            <Col lg={4} md={4} sm={4} xs={4}>
              Battery Details
            </Col>
            <Col lg={4} md={4} sm={4} xs={4}>
              Other Details
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default React.memo(TapFinScore);
