import React from 'react';
import TextInput from '../../../components/text-input/TextInput';
import { FIELD_MANDATORY } from '../../../utils/error';
import { isValidEmail } from '../../../utils/helper';

const BasicDetails = ({handleInput, form, isSubmitted}) => {

  return (
    <>
      <div className='row'>
        <TextInput
          customClass={'col-lg-4 col-md-6 col-sm-12'}
          label={'Contact Person Name'}
          required={true}
          name={'name'}
          value={form?.name}
          handleInput={handleInput}
          type={'text'}
          isInValid={isSubmitted && !form?.name}
          supportiveText={isSubmitted && !form?.name && FIELD_MANDATORY}
        />
        <TextInput 
          customClass={'col-lg-4 col-md-6 col-sm-12'}
          label={'Email'}
          required={true}
          name={'email'}
          value={form?.email}
          handleInput={handleInput}
          type={'text'}
          isInValid={isSubmitted && (!form?.email || !isValidEmail(form?.email))}
          supportiveText={isSubmitted && ((!form?.email && FIELD_MANDATORY) || (!isValidEmail(form?.email) && 'Enter valid email'))}
        />
        <TextInput 
          customClass={'col-lg-4 col-md-6 col-sm-12'}
          label={'Mobile No.'}
          required={true}
          name={'mobile'}
          value={form?.mobile}
          handleInput={handleInput}
          type={'number'}
          inputMode={'numeric'}
          maxLength={10}
          isInValid={isSubmitted && !form?.mobile}
          supportiveText={isSubmitted && !form?.mobile && FIELD_MANDATORY}
        />
      </div>
    </>
  )
}

export default React.memo(BasicDetails);
