import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import columns from "./ApiLogTableColumns";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import useClass from "../../../hooks/useClass";
import Table from "../../../components/Common/Table";
import Filters from "../../../components/Common/Filters";

const ApiLogs = () => {
  document.title = "Logs | TapFin";

  const { adminServer, toaster, permission } = useClass();
  const [loading, setLoading] = useState(true);
  const [logList, setLogList] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, perPage: 20 });
  const [totalData, setTotalData] = useState(0);

  const getLogList = async (params) => {
    try {
      const { pagination, searchText } = params;
      const response = await adminServer.getAllLogs(
        pagination?.page,
        pagination?.perPage,
        searchText
      );
      const { data, totalCount } = response?.data;
      data?.forEach((data, index) => {
        data.index = index + 1;
      });
      setTotalData(totalCount);
      setLogList(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toaster.error(error);
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="TapFin" breadcrumbItem="Logs" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="d-flex justify-content-between">
                  <h5 className="card-title mb-0">Logs List</h5>
                  <Filters
                    fetchDataFromServer={getLogList}
                    pagination={pagination}
                    setPagination={setPagination}
                  />
                </CardHeader>
                <CardBody>
                  <Table
                    data={logList}
                    loading={loading}
                    pagination={pagination}
                    setPagination={setPagination}
                    columns={columns}
                    paginationTotalRows={totalData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ApiLogs;
