import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row, Button } from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Table from '../../../components/Common/Table';
import userTableColumns from './UserTableColumns';
import CreateUser from './CreateUser';
import useClass from '../../../hooks/useClass';
import Filters from '../../../components/Common/Filters';
import { getUserList } from '../../../store/admin/adminData';
import { useSelector } from 'react-redux';

const Users = () => {

  document.title = "Users | TapFin";

  const { permission, dispatch } = useClass();
  const columns = userTableColumns(permission);
  const { list, totalList, loading } = useSelector((state) => (state?.user));
  const [createUser, setCreateUser] = useState(false);
  const [pagination, setPagination] = useState({page: 1, perPage: 20});

  const fetchUserList = async (params) => {
    const { pagination, searchText, dateRange } = params;
    dispatch(getUserList({page: pagination?.page, perPage: pagination?.perPage, search: searchText, dateRange: dateRange}));
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="TapFin" breadcrumbItem="Users" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="d-flex justify-content-between">
                  <h5 className="card-title mb-0"></h5>
                  <Filters
                    fetchDataFromServer={fetchUserList}
                    pagination={pagination}
                    setPagination={setPagination}
                    showCreateButton={permission?.USERS?.LIST?.CREATE_NEW_USER}
                    onCreateButtonClick={() => {setCreateUser(true)}}
                  />
                </CardHeader>
                <CardBody>
                  <Table 
                    data={list} 
                    loading={loading}
                    pagination={pagination}
                    setPagination={setPagination}
                    columns={columns}
                    paginationTotalRows={totalList}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CreateUser
        isOpen={createUser}
        setIsOpen={setCreateUser}
      />
    </>
  );
};

export default Users;