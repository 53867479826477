import JoditEditor from 'jodit-react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useClass from '../../hooks/useClass';
import { storeChangedEditorValue } from '../../store/customer/cam/action';

const Editor = ({editorCAM}) => {

  
  const { dispatch } = useClass();
  const { cam } = useSelector((state) => (state?.cam));

  const editorConfig = useMemo(() => ({
    placeholder: '',
    height: '60svh'
  }), []);

  const handleBlur = (key, value) => {
    dispatch(storeChangedEditorValue({key, value}));
  };

  return (
    <>
      <JoditEditor
        ref={editorCAM}
		  	value={cam}
        config={editorConfig}
		  	onBlur={(v) => handleBlur('cam', v)}
		  />
    </>
  )
}

export default React.memo(Editor);
