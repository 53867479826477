import { createSlice } from "@reduxjs/toolkit";

const cam = createSlice({
    name: 'camAction',
    initialState: {
        openCAM: false,
        loading: false,
        error: null,
        openCollapse: 0,
        editableFields: [],
        cam: '',
        email: '',
        takeEmail: false,
        lenderUsers: [],
        documents: [],
        lender: {}
    },
    reducers: {
        toggleCAM: (state, action) => {
            state.lender = action.payload;
            state.openCAM = !state.openCAM;
            state.editableFields = [];
            state.openCollapse = 0;
            state.email = '';
        },
        toggleCollapse: (state, action) => {
            state.openCollapse = action.payload;
        },
        getEditableFields: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        getEditableFieldsSuccess: (state, action) => {
            state.loading = false;
            state.editableFields = action.payload;
        },
        getEditableFieldsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        storeFieldValue: (state, action) => {
            let newFields = state.editableFields;
            newFields[action.payload.index].value = action.payload.value;
            state.editableFields = newFields;
        },
        updateEditableFields: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        updateEditableFieldsSuccess: (state, action) => {
            state.loading = false;
            state.cam = action.payload.cam;
        },
        updateEditableFieldsError: (state, action) => {
            state.loading = false;
            state.error = action.payload
        },
        getApplicationCAM: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        getApplicationCAMSuccess: (state, actoin) => {
            state.loading = false;
            state.cam = actoin.payload;
        },
        getApplicationCAMError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        toggleMail: (state, action) => {
            state.takeEmail = !state.takeEmail;
        },
        storeChangedEditorValue: (state, action) => {
            state[action.payload.key] = action.payload.value;
        },
        updateApplicationCAM: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        updateApplicationCAMSuccess: (state,action) => {
            state.loading = false;
        },
        updateApplicationCAMError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        getUserAndDocList: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        getUserAndDocListSuccess: (state, action) => {
            state.loading = false;
            state.lenderUsers = action.payload.users;
            state.documents = action.payload.docs;
        },
        getUserAndDocListError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        storeSelectedUser: (state, action) => {
            const user = state.lenderUsers[action.payload.index];
            if (user) {
                user.selected = !user.selected;
            }
        },
        storeSelectedDoc: (state, action) => {
            let doc = state.documents[action.payload.index];
            if(doc){
                doc.selected = !doc.selected;
            }
        },
        updateUserAndDoc: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        updateUserAndDocSuccess: (state, action) => {
            state.loading = false;
        },
        updateUserAndDocError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const {
    toggleCAM,
    toggleCollapse,
    getEditableFields, 
    getEditableFieldsSuccess, 
    getEditableFieldsError,
    storeFieldValue,
    updateEditableFields,
    updateEditableFieldsSuccess,
    updateEditableFieldsError,
    getApplicationCAM,
    getApplicationCAMSuccess,
    getApplicationCAMError,
    storeChangedEditorValue,
    toggleMail,
    updateApplicationCAM,
    updateApplicationCAMSuccess,
    updateApplicationCAMError,
    getUserAndDocList,
    getUserAndDocListSuccess,
    getUserAndDocListError,
    storeSelectedDoc,
    storeSelectedUser,
    updateUserAndDoc,
    updateUserAndDocSuccess,
    updateUserAndDocError
  } = cam.actions;
  
  export const camReducer = {
    cam: cam.reducer
  }
  