import { createSlice } from "@reduxjs/toolkit";

const report = createSlice({
  name: 'bureauReport',
  initialState: {
    loading: false,
    error: null,
    creditReport: null,
    bankStatement: null,
    transactions: null
  },
  reducers: {
    getBureauReport: (state, action) => {
      state.loading = true;
      state.error = null;
      state.creditReport = null;
    },
    getBureauReportSuccess: (state, action) => {
      state.loading = false;
      state.creditReport = action?.payload;
    },
    getBureauReportError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    },
    getBankStatementReport: (state, action) => {
      state.loading = true;
      state.error = null;
      state.bankStatement = null;
    },
    getBankStatementReportSuccess: (state, action) => {
      state.loading = false;
      state.bankStatement = action?.payload;
    },
    getBankStatementReportError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    },
    getBankTransactions: (state, action) => {
      state.loading = true;
      state.transactions = null;
    },
    getBankTransactionsSuccess: (state, action) => {
      state.loading = false;
      state.transactions = action?.payload;
    },
    getBankTransactionsError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    }
  }
})

export const {
  getBureauReport,
  getBureauReportSuccess,
  getBureauReportError,
  getBankStatementReport,
  getBankStatementReportSuccess,
  getBankStatementReportError,
  getBankTransactions,
  getBankTransactionsSuccess,
  getBankTransactionsError
} = report?.actions;

export const reportReducer = {
  reports: report.reducer
}