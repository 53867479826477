import React, { useEffect, useState } from 'react';
import { Row, Container, Card, CardBody, CardHeader, Col } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import useClass from '../../hooks/useClass';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getTeamList } from '../../store/admin/adminData';
import CustomUncontrolledDropdown from '../../components/Dropdown/CustomUncontrolledDropdown';
import { formatDate3 } from '../../utils/helper';
import { getHotLeadDetails } from '../../store/user/userSlice';
import FollowUps from './followups/FollowUps';
import Update from './edit/Update';

const Details = () => {

  const { permission, toaster, adminServer, dispatch } = useClass();
  const { leadCode } = useParams();
  const { list: teamList, loading: teamListLoading} = useSelector((state) => (state?.team));
  const { details: leadDetails, loading } = useSelector((state) => (state?.hotLead));
  const [assignedTeam, setAssignedTeam] = useState({});
  const [updateLead, setUpdateLead] = useState(false);

  useEffect(() => {
    dispatch(getHotLeadDetails(leadCode));
  }, []);

  useEffect(() => {
    teamList?.forEach((team) => {
      if(team?.id === leadDetails?.teamId){
        const members = team?.members?.map((member) => ({...member, label: member.name, value: member.userId}))
        let data = {...team};
        data.members = members;
        setAssignedTeam(data);
      }
    })
  }, [teamList]);

  const handleLeadAssignmentTeam = (team) => {
    return async () => {
      try {
        const result = await toaster.confirmation('Assign', `Assign this Lead to ${team?.name}`);
        if(result?.isConfirmed){
          const payload = {teamId: team.id, leadCodes: [leadCode]}
          const response = await adminServer.assignLead(payload);
          window.location.reload();
        }
      } catch(error) {
        toaster.error(error);
      }
    }
  }

  const handleLeadAssignmentUser = (user) => {
    return async () => {
      try {
        const result = await toaster.confirmation('Assign', `Assign Lead to ${user.name}`);
        if(result?.isConfirmed){
          const payload = {userId: user.id, teamId: leadDetails?.teamId, leadCodes: [leadCode]}
          const response = await adminServer.assignLead(payload);
          window.location.reload();
        }
      } catch(error) {
        toaster.error(error);
      }
    }
  }

  const fetchTeamList = (search) => {
    if(permission?.TEAMS?.LIST?.VIEW_TEAM_LIST_ALL){
      dispatch(getTeamList({search}));
    }
  }


  return (
    <>
      <Row className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="hot_leads" breadcrumbItem="Details" />
          <Row>
            <Col xl={12}>
              <Card className='common-card-style'>
                <CardHeader>
                  <div className='d-flex gap-4 justify-content-between'>
                    <div className='section-heading'>Lead Details</div>
                    <div className='form-check form-switch form-switch-md'>
                    <div className='clickable'>
                    {(permission?.HOT_LEADS?.DETAILS?.UPDATE_LEAD_DETAILS && leadDetails?.details) || true && 
                      <i
                        style={{color: 'green'}} 
                        onClick={() => setUpdateLead(!updateLead)} 
                        className='fas fa-edit' 
                      />
                    }
                  </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Name</Row>
                      <Row className='data-value me-4'>{leadDetails?.name}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Mobile</Row>
                      <Row className='data-value me-4'>{leadDetails?.mobile}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Email</Row>
                      <Row className='data-value me-4'>{leadDetails?.email}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Partner Code</Row>
                      <Row className='data-value me-4'>{leadDetails?.partnerCode}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Status</Row>
                      <Row className='data-value me-4'>{leadDetails?.status || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Created By</Row>
                      <Row className='data-value me-4'>{leadDetails?.createdBy}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>code</Row>
                      <Row className='data-value me-4'>{leadDetails?.code || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Lead Source</Row>
                      <Row className='data-value me-4'>{leadDetails?.leadSource || '-'}</Row>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card className='common-card-style'>
                <CardHeader>
                  <div className='d-flex gap-4 justify-content-between'>
                    <div className='section-heading'>Entity Details</div>
                    <div className='form-check form-switch form-switch-md'>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Entity Type</Row>
                      <Row className='data-value me-4'>{leadDetails?.details?.entityType?.label || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Entity Name</Row>
                      <Row className='data-value me-4'>{leadDetails?.details?.entityName || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Business Type</Row>
                      <Row className='data-value me-4'>{leadDetails?.details?.businessType?.label || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Year of Incorporation</Row>
                      <Row className='data-value me-4'>{leadDetails?.details?.yearOfIncorporation?.label || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Service Cities</Row>
                      <Row className='data-value me-4'>{leadDetails?.details?.serviceCities && leadDetails?.details?.serviceCities?.map((city, index) => (<React.Fragment key={`cities-lead-${index}`}>{city?.label}{leadDetails?.details?.serviceCities?.length === (index+1) ? '' : ','}</React.Fragment>)) || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Fleet Size</Row>
                      <Row className='data-value me-4'>{leadDetails?.details?.fleetSize || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>EV(s) in Fleet</Row>
                      <Row className='data-value me-4'>{leadDetails?.details?.evsInFleet || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Current FY Revenue</Row>
                      <Row className='data-value me-4'>₹ {leadDetails?.details?.currentFYRevenue ? parseInt(leadDetails?.details?.currentFYRevenue).toLocaleString() : '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Current FY PAT</Row>
                      <Row className='data-value me-4'>₹ {leadDetails?.details?.currentFYPAT ? parseInt(leadDetails?.details?.currentFYPAT).toLocaleString() : '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Last FY Revenue</Row>
                      <Row className='data-value me-4'>₹ {leadDetails?.details?.lastFYRevenue ? parseInt(leadDetails?.details?.lastFYRevenue) : '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Last FY PAT</Row>
                      <Row className='data-value me-4'>₹{leadDetails?.details?.lastFYPAT ? parseInt(leadDetails?.details?.lastFYPAT).toLocaleString() : '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Selected Products</Row>
                      <Row className='data-value me-4'>{leadDetails?.details?.selectedProducts?.label || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Loan Amount</Row>
                      <Row className='data-value me-4'>₹ {leadDetails?.details?.loanAmount ? parseInt(leadDetails?.details?.loanAmount).toLocaleString() : '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Deadline</Row>
                      <Row className='data-value me-4'>{formatDate3(leadDetails?.details?.deadline) || '-'}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Notes</Row>
                      <Row className='data-value me-4'>{leadDetails?.details?.notes || '-'}</Row>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card className='common-card-style'>
                <CardHeader>
                  <div className='d-flex gap-4 justify-content-between'>
                    <div className='section-heading'>Team Details</div>
                    <div className='form-check form-switch form-switch-md'>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Team Name</Row>
                      <Row className='data-value me-4'>
                        {permission?.HOT_LEADS?.DETAILS?.ASSIGN_LEAD_TO_USER ? <CustomUncontrolledDropdown
                          options={teamList}
                          onClick={handleLeadAssignmentTeam}
                          value={leadDetails?.teamName || 'None'}
                          loading={teamListLoading}
                          handleSearch={fetchTeamList}
                        /> : <>{leadDetails?.teamName || 'None'}</>}
                      </Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Assigned To</Row>
                      <Row className='data-value me-4'>
                        {permission?.HOT_LEADS?.DETAILS?.ASSIGN_LEAD_TO_USER ? <CustomUncontrolledDropdown
                          options={assignedTeam?.members || []}
                          onClick={handleLeadAssignmentUser}
                          value={leadDetails?.assignTo|| 'None'}
                          showSearch={false}
                        />: <>{leadDetails?.assignTo|| 'None'}</>}
                      </Row>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col lg={12}>
              <FollowUps />
            </Col>
          </Row>
        </Container>
      </Row>
     <Update isOpen={updateLead} setIsOpen={setUpdateLead} />
    </>
  )
}

export default Details;
