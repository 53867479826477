import React, { useMemo, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import Create from './Create';
import ScheduleTable from './ScheduleTable';
import { useSelector } from 'react-redux';

const FollowUps = () => {

  const { tasks } = useSelector((state) => (state?.hotLead));
  const [isOpen, setIsOpen] = useState(false);
  const [create, setCreate] = useState(false);

  const toggleCollapse = (id) => {
    setIsOpen(!isOpen);
  }

  const handleFollowupCreate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCreate(true);
  }

  const isPending = useMemo(() => {
    const pendingTasks = tasks?.filter((obj) => (obj?.taskStatus !== 'COMPLETE'));
    return pendingTasks?.length > 0;
  }, [tasks]);

  return (
    <>
      <Card>
        <CardHeader onClick={toggleCollapse}>
          <div className='d-flex gap-4 justify-content-between'>
            <div className='section-heading'>Follow-ups</div>
            <div className='form-check form-switch form-switch-md'>
              <Button
                className='me-2'
                disabled={isPending} 
                color='success' 
                onClick={handleFollowupCreate}
              >
                +Create
              </Button>
              <i className={isOpen ? 'ri-arrow-up-fill' : 'ri-arrow-down-fill'} />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Collapse isOpen={isOpen}>
            <ScheduleTable />
          </Collapse>
        </CardBody>
      </Card>
      <Create isOpen={create} setIsOpen={setCreate} />
    </>
  )
}

export default React.memo(FollowUps);
