import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Table from "../../../components/Common/Table";
import useClass from "../../../hooks/useClass";
import Filters from "../../../components/Common/Filters";
import { useSelector } from "react-redux";
import { getCampaignTemplates } from "../../../store/campaign/template";
import campaignTemplates from "./Columns"
import Create from "./create/Create";
const Templates = () => {
  document.title = "Templates | TapFin";
  const { permission, dispatch} = useClass();

  const column = campaignTemplates(permission)
  const [pagination, setPagination] = useState({ page: 1, perPage: 20 });
  const [createTemplate, setCreateTemplate] = useState(false)
  const {templateList, loading, totalList} = useSelector((state)=> state.campaignTemplate);
  const fetchTemplateList = (params) => {
    const { pagination, searchText, dateRange } = params;
    dispatch(getCampaignTemplates({page: pagination?.page,
      perPage: pagination?.perPage,
      search: searchText,
      dateRange: dateRange}));
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="TapFin" breadcrumbItem="Templates" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="d-flex justify-content-between">
                  <h5 className="card-title mb-0">Template List</h5>
                  <Filters
                    fetchDataFromServer={fetchTemplateList}
                    pagination={pagination}
                    setPagination={setPagination}
                    showCreateButton={permission?.LENDERS?.LIST?.CREATE_NEW_LENDER}
                    onCreateButtonClick={() => setCreateTemplate(true)}
                  />
                </CardHeader>
                <CardBody>
                  <Table
                    data={templateList}
                    loading={loading}
                    pagination={pagination}
                    setPagination={setPagination}
                    columns={column}
                    paginationTotalRows={totalList}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Create isOpen={createTemplate} setIsOpen={setCreateTemplate} />
      </div>
    </>
  );
};

export default Templates;

