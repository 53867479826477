import React, { useState } from 'react';
import { Modal, ModalHeader } from "reactstrap";
import LoadingButton from '../../components/Common/LoadingButton';
import useClass from '../../hooks/useClass';
import TextInput from '../../components/text-input/TextInput';
import Dropdown from '../../components/Dropdown/Dropdown';
import { FILL_ALL_MENDETORY_FIELDS } from '../../utils/error';
import { createNewTask } from '../../store/user/userSlice';

const Create = (props) => {

  const { toaster, dispatch } = useClass();
  const {isOpen, setIsOpen} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    description: '',
    type: ''
  });

  const handleModelClose = () => {
    setForm({
      name: '',
      description: '',
      type: ''
    });
    setIsOpen(false);
  }

  const handleInput = (key, value) => {
    setForm((prev) => ({
        ...prev,
        [key]: value
    }))
  }

  const handleTaskCreation = async () => {
    if(validateForm()){
      const payload = {
        name: form?.name,
        description: form?.description,
        type: form?.type?.value
      }
      dispatch(createNewTask(payload));
      handleModelClose();
    } else {
      toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
    }
  }

  const validateForm = () => {
    const result = Object.keys(form).every((key) => {
        return form.hasOwnProperty(key) && form[key] !== "";
    });
    return result;
  }

  return (
    <>
        <Modal isOpen={isOpen}
          toggle={() => {setIsOpen(!isOpen)} }
          centered
        >
            <ModalHeader toggle={handleModelClose}>
              Create Task
            </ModalHeader>
            <div className="modal-body">
              <div>
                <TextInput 
                  label={'Name'}
                  required={true}
                  name={'name'}
                  value={form?.name}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>
              <div className='mt-4'>
                <TextInput 
                  label={'Description'}
                  required={true}
                  name={'description'}
                  value={form?.description}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>
              <div className='mt-4'>
                <Dropdown
                  label={'Type'}
                  name={'type'}
                  handleInput={handleInput}
                  options={[{label: 'Customer Visit', value: 'CUSTOMER_VISIT'},{label: 'Verification', value: 'VERIFICATION'}]}
                  required={true}
                />
              </div>
            </div>
            <div className="modal-footer">
              <LoadingButton
                buttonContent={'Create'}
                isLoading={isLoading}
                onClick={handleTaskCreation}
                color={'success'}
              />
            </div>
        </Modal>
    </>
  )
}

export default Create;
