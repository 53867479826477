import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import useClass from '../../../hooks/useClass';
import { sendData, toggleBREFieldsModal } from '../../../store/customer/bre/actions';
import LoadingButton from '../../../components/Common/LoadingButton';
import { useSelector } from 'react-redux';
// import { lenderConfigFields } from '../../../constants/constants';
import DynamicFields from '../../../components/Config-Canban/DynamicFields';

const BREFields = () => {

  const { dispatch } = useClass();
  // const fields = lenderConfigFields({});
  const { open, loading } = useSelector((state) => (state.bre)); 
  const { lenderConfigFields, loading: fetchingConfig } = useSelector((state) => (state?.configUtils));
  const { activeApplicationIndex, applicationList } = useSelector((state) => (state?.applicationAction));
  const [form, setForm] = useState({});

  const toggle = () => {
    dispatch(toggleBREFieldsModal());
  }

  const handleSubmit = () => {
    console.log(form)
    dispatch(sendData({form, applicationId: applicationList?.[activeApplicationIndex]?.applicationId}));
  }

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  return (
    <>
      <Modal
        size='xl'
        isOpen={open}
        toggle={toggle}
        centered
      >
        <ModalHeader>
          <h5 className="modal-title mt-0" id="myModalLabel">
            BRE Fields
          </h5>
          <button type="button" onClick={toggle} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Row>
            {fetchingConfig ? <>
              <div className='text-center'>
                <Spinner />
              </div>
            </>  : 
            <>
              {lenderConfigFields?.filter((obj) => (obj?.component?.editable))?.map((obj, index) => (
                <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
                  <DynamicFields 
                    form={form}
                    handleInput={handleInput}
                    data={{...obj, required: false}}
                    index={index}
                  />
                </div>
              ))}
            </>}
          </Row>
        </ModalBody>
        <ModalFooter>
          <LoadingButton
            buttonContent={'Submit'}
            isLoading={loading}
            onClick={handleSubmit}
            color={'success'}
            disabled={!(Object.keys(form)?.length > 0 && Object.keys(form)?.some((key) => (form?.[key] !== '' && form?.[key]?.trim() !== '')))}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default React.memo(BREFields);
