import React from 'react';
import EligibleLenders from '../table-buttons/EligibleLenders';

const columns = [
    {
        name: <span className='font-weight-bold fs-13'>Lender Name</span>,
        selector: row => (row?.name || '-'),
        sortable: false,
        width: '300px',
    },
    {
        name: <span className='font-weight-bold fs-13'>Approval Chance</span>,
        sortable: false,
        selector: (row) => {
            let number = Math.floor(Math.random() * 100);
            if(number >= 50 && number <= 69)
                return <span className="badge badge-soft-info"> {`${number} %` || '-'} </span>;
            if(number >= 31 && number < 50)
                return <span className="badge badge-soft-secondary"> {`${number} %` || '-'} </span>;
            if(number < 30)
                return <span className="badge badge-soft-danger"> {`${number} %` || '-'} </span>;
            if(number > 69 && number <= 79)
                return <span className="badge badge-soft-warning"> {`${number} %` || '-'} </span>;
            if(number > 79)
                return <span className="badge badge-soft-success"> {`${number} %` || '-'} </span>;
              
        },
    },
    {
        name: <span className='font-weight-bold fs-13'>Action</span>,
        sortable: false,
        width: '150px',
        cell: (row) => (<EligibleLenders row={row} />),
    },
];

export default columns;
