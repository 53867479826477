import { call, put, takeEvery, all, fork, takeLatest } from 'redux-saga/effects';
import { getLenderConfigFields, getLenderConfigFieldsError, getLenderConfigFieldsSuccess } from './actions';
import { Toaster } from '../../components/toaster/Toaster';
import { UtilsService } from '../../service/UtilsService';

const toaster = new Toaster();
const server = new UtilsService();

function* watchLenderConfigFields() {
    yield takeLatest(getLenderConfigFields.type, fetchLenderConfigFields);
}

function* fetchLenderConfigFields({payload}) {
    try {
        const response = yield call(server.getLenderConfigFields, {});
        yield put(getLenderConfigFieldsSuccess(response?.data?.data));
    } catch(error) {
        toaster.error(error);
        yield put(getLenderConfigFieldsError(error));
    }
}

export default function* utilsSaga(){
    yield all([
        fork(watchLenderConfigFields)
    ])
};