import React, { useEffect, useState, memo } from 'react';
import DateRange from './DateRange';
import { Button, FormGroup } from 'reactstrap';
import Dropdown from '../Dropdown/Dropdown';
import Select from 'react-select';
import CustomSelectInput from '../Dropdown/CustomSelectInput';

const Filters = ({ 
    showCreateButton, 
    onCreateButtonClick, 
    fetchDataFromServer, 
    pagination, 
    setPagination,
    showType=false,
    showStatus=false,
    typeOptions=[],
    statusOptions=[],
    showSearchBar = true,
    showCreateLabel = true,
    searchSupportiveText=''
}) => {

    const [dateRange, setDateRange] = useState({});
    const [searchText, setSearchText] = useState('');
    const [type, setType] = useState(JSON?.parse(localStorage?.getItem('type')) || '');
    const [status, setStatus] = useState(JSON?.parse(localStorage?.getItem('status')) || '');
    
    useEffect(() => {
        let timeOut = setTimeout(() => {
            fetchDataFromServer({pagination, searchText, dateRange, type: type?.value, status: status?.value});
        }, 500);
        return () => {
            clearTimeout(timeOut)
        }
    }, [dateRange, searchText, pagination, type, status]);

    const handleDateRange = (identifier, range) => {
        const {fromDate, toDate} = range;
        setDateRange({fromDate, toDate});
    }

    const handleSearch = (e) => {
        setPagination({page: 1, perPage: 20})
        setSearchText(e?.target?.value);
    }

    const handleTypeSelection = (type) => {
        localStorage.setItem('type', JSON.stringify(type));
        setType(type);
    }

    const handleStatusSelection = (status) => {
        localStorage.setItem('status', JSON.stringify(status));
        setStatus(status);
    }


    return (
        <>
            <div className='d-flex gap-4 '>
                <div>
                    <DateRange
                        identifier={'date_range'}
                        handleInput={handleDateRange}
                    />
                </div>
                {showSearchBar && 
                    <div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            onChange={handleSearch}
                        />
                        <small className='text-muted' style={{fontSize: '65%'}}>{searchSupportiveText}</small>
                    </div>
                }
                {showType && <div>
                    <FormGroup className="form-group has-float-label">
                        <Select
                          components={{ Input: CustomSelectInput }}
                          className="react-select"
                          classNamePrefix="react-select"
                          placeholder={'Select Type'}
                          name={'type'}
                          value={type}
                          onChange={handleTypeSelection}
                          isClearable={true}
                          options={typeOptions}
                        />
                    </FormGroup>
                </div>}
                {showStatus && <div>
                    <FormGroup className="form-group has-float-label">
                        <Select
                          components={{ Input: CustomSelectInput }}
                          className="react-select"
                          classNamePrefix="react-select"
                          placeholder={'Select Status'}
                          name={'status'}
                          value={status}
                          onChange={handleStatusSelection}
                          isClearable={true}
                          options={statusOptions}
                        />
                    </FormGroup>
                </div>}
                {showCreateButton && <div>
                    <Button color="success" onClick={onCreateButtonClick}>
                    {showCreateLabel ? <i className='bx bx-plus-medical me-2'/> : <i class='bx bx-downvote me-2' />}
                    {showCreateLabel ?"Create":"Fetch"}
                    </Button>
                </div>}
            </div>
        </>
    )
}

export default memo(Filters);
