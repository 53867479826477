import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, Spinner } from 'reactstrap';
import useClass from '../../../../hooks/useClass';
import LoadingButton from '../../../Common/LoadingButton';
import { PD_CLOSE, PD_SUBMIT } from '../../../../constants/bubbles';
import { getPDQuestions, selectQuestion, submitPD } from '../../../../store/customer/application/Assesment';
import { useParams } from 'react-router-dom';

const QuestionSearch = () => {

  const { customerCode } = useParams();
  const { dispatch, toaster } = useClass();
  const { searching, loading, questions, selectedQuestions } = useSelector((state) => (state?.pd));
  const { activeApplicationIndex, applicationList } = useSelector((state) => (state?.applicationAction));
  const [search, setSearch] = useState('');
  const [showQuestions, setShowQuestions] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(getPDQuestions(search));
    }, 500);

    return () => {
      clearTimeout(timeout);
    }
  }, [search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  }

  const handleMouse = () => {
    setShowQuestions(!showQuestions);
  }

  const handleQuestionSelect = (question) => {
    if(!question){
      toaster.show(true, 'Please type your question in search bar to create a new question');
    }
    const isExist = selectedQuestions?.some((q) => (q.question === question));
    if(isExist){
      toaster.show(true, 'Question already selected');
      return;
    }
    setShowQuestions(false)
    dispatch(selectQuestion(question));
  }

  const handleSubmit = () => {
    const isValid = selectedQuestions?.every((obj) => (obj?.answer && obj?.answer?.trim() !== ''));
    if(!isValid){
      toaster.show(true, 'Please fill all answers');
      return;
    }
    dispatch(submitPD({payload: selectedQuestions, customerCode, applicationId: applicationList?.[activeApplicationIndex]?.applicationId}));
  }

  return (
    <div className='container-pd-search d-flex'>
      <div className="pd-search" onMouseLeave={handleMouse} onMouseEnter={handleMouse}>
        {showQuestions && <div className='question-container'>
          <Card className='question-list'>
            <CardBody>
            <div style={{fontSize: '12px'}} className='text-info mb-4'>
              {searching ? <Spinner size='sm' /> : `${questions?.length} questions found`}
            </div>
            {questions?.map((question, index) => (
              <div key={`question-${index}`} className='mb-2'>
                <span 
                  style={{cursor: 'pointer'}}
                  className='text-primary'
                  onClick={() => handleQuestionSelect(question)}
                >
                  {index+1}. {question}
                </span>
              </div>
            ))}
            </CardBody>
          </Card>
        </div>}
        <input 
          type="text" 
          id="box" 
          placeholder="Search questions..." 
          className="search-pd__box"
          autoComplete='off'
          onChange={handleSearch}
        />
        <i className="fas fa-search search-pd__icon" id="icon"></i>
      </div>
      <div className='d-flex align-items-end'>
        <Button id={PD_CLOSE} color='danger me-2'>Cancel</Button>
        <LoadingButton
          id={PD_SUBMIT}
          color={'success'}
          buttonContent={'Submit'}
          isLoading={loading}
          onClick={handleSubmit}
          disabled={selectedQuestions?.length === 0}
        />
      </div>
    </div>
  )
}

export default React.memo(QuestionSearch);
