import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import TextInput from '../../../components/text-input/TextInput';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { getLenderList, getPartnerList } from '../../../store/admin/adminData';
import { useSelector } from 'react-redux';
import { FIELD_MANDATORY, FILL_ALL_MENDETORY_FIELDS } from '../../../utils/error';
import { isValidEmail } from '../../../utils/helper';
  

const CreateUser = (props) => {

  const { toaster, adminServer, navigate, permission, dispatch } = useClass();
  const {isOpen, setIsOpen} = props;
  const partnerList = useSelector((state) => (state?.partner?.list));
  const lenderList = useSelector((state) => (state?.lender?.list));
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
    type: "",
    org: ""
  });

  useEffect(() => {
    if(permission?.LENDERS?.LIST?.VIEW_LENDER_LIST_ALL && permission?.PARTNERS?.LIST?.VIEW_PARTNER_LIST_ALL){
      dispatch(getLenderList({page: 1, perPage: 200, search:''}));
      dispatch(getPartnerList({page: 1, perPage: 200, search:''}));
    }
  }, [])

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleDropdown = (key, value) => {
    if(key === 'type' && value?.label === 'TAPFIN'){
      setForm((prev) => ({
        ...prev,
        [key]: value,
        org: value
      }))
    } else {
      setForm((prev) => ({
        ...prev,
        [key]: value
      }))
    }
  }

  const handleModelClose = () => {
    setIsOpen(false);
  }

  const handleUserCreation = async () => {
    try{
      const payload = {...form, type: form?.type?.value, org: form?.org?.value}
      if(validateForm(payload)){
        setIsLoading(true);
        const response = await adminServer.createNewUser(payload);
        setIsLoading(false);
        handleModelClose();
        navigate(`/users/details/${response?.data?.user?.id}`);
      }
    } catch(error){
      setIsLoading(false);
      toaster.error(error);
    }
  }

  const validateForm = (payload) => {
    const result = Object.keys(payload).every((key) => {
      return payload.hasOwnProperty(key) && payload[key]?.trim() !== '' && payload[key];
    });
    const emailValid = isValidEmail(payload?.email);
    if(!result){
      toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
      return false;
    }
    if(!emailValid){
      toaster.show(true, 'Please enter valid email');
      return false;
    }
    return result;
  }

  return (
    <>
        <Modal isOpen={isOpen}
          toggle={handleModelClose}
          centered
        >
            <ModalHeader toggle={handleModelClose}>
              Create User
            </ModalHeader>
            <ModalBody>
              <div>
                <TextInput
                  label={'User Name'}
                  value={form?.name}
                  required={true}
                  type={'text'}
                  name={'name'}
                  handleInput={handleInput}
                />
              </div>
              <div className='mt-4'>
                <TextInput 
                  label={'User email'}
                  value={form?.email}
                  required={true}
                  type={'email'}
                  name={'email'}
                  handleInput={handleInput}
                />
              </div>
              <div className='mt-4'>
                <TextInput
                  label={'Password'}
                  value={form?.password}
                  required={true}
                  type={'text'}
                  name={'password'}
                  handleInput={handleInput}
                />
              </div>
              <div className='mt-4'>
                <Dropdown 
                  label={'User Type'}
                  value={form?.type}
                  required={true}
                  name={'type'}
                  options={[{label: 'TAPFIN', value: 'TAPFIN'}, {label: 'LENDER', value: 'LENDER'}, {label: 'PARTNER', value: 'PARTNER'}]}
                  handleInput={handleDropdown}
                />
              </div>
              {form?.type && <div className='mt-4'>
                { form?.type?.label === 'TAPFIN' && 
                  <TextInput
                    label={'Organisation'}
                    required={true}
                    value={form?.org?.label}
                    disabled={true}
                  />
                }
                {
                  (form?.type?.label === 'PARTNER') && 
                  <Dropdown 
                    label={'Select Partner'}
                    required={true}
                    value={form?.org}
                    name={'org'}
                    options={partnerList?.map((obj) => ({label: obj?.name, value: obj?.code}))}
                    handleInput={handleDropdown}
                  />
                }
                {
                  (form?.type?.label === 'LENDER') && 
                  <Dropdown 
                    label={'Select Lender'}
                    required={true}
                    value={form?.org}
                    name={'org'}
                    options={lenderList?.map((obj) => ({label: obj?.name, value: obj?.code}))}
                    handleInput={handleDropdown}
                  />
                }
              </div>}
            </ModalBody>
            <ModalFooter>
              <LoadingButton
                buttonContent={'Create'}
                isLoading={isLoading}
                onClick={handleUserCreation}
                color={'success'}
              />
            </ModalFooter>
        </Modal>
    </>
  )
}

export default CreateUser;
