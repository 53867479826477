import React, { useState } from 'react';
import { 
  Container,  
  Accordion, 
  AccordionItem,
  AccordionHeader, 
  AccordionBody 
} from 'reactstrap';

const Accordions = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState('0');

  const toggle = (index) => {
    setActiveIndex(activeIndex == index ? '-1' : `${index}`);
  };

  return (
    <Container>
      {items?.map((item,index)=> (
        <Accordion open={activeIndex} toggle={toggle} key={`custacc${index}`}>
          <AccordionItem>
            <AccordionHeader targetId={`${index}`}>
              {item?.title}
            </AccordionHeader>
            <AccordionBody accordionId={`${index}`}>
              {activeIndex == index && item?.content}
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      ))}
    </Container>
  );
};

export default Accordions;
