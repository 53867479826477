import { createSlice } from "@reduxjs/toolkit";
import { initialFieldStatusConstant } from "../../../utils/constants";
import { Toaster } from "../../../components/toaster/Toaster";
import assesment from "./Assesment";

const toaster = new Toaster();

const applicationAction = createSlice({
  name: 'applicationAction',
  initialState: {
    activeApplicationIndex: 0,
    activeLoanIndex: 0,
    applicationList: [],
    additionalDocList: [],
    data: {},
    loading: false,
    error: null,
  },
  reducers: {
    getApplicationList: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getApplicationListSuccess: (state, action) => {
      state.loading = false;
      state.applicationList = action?.payload;
    },
    getApplicationListError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    },
    updateActiveApplicationIndex: (state, action) => {
      state.activeApplicationIndex = action?.payload
    },
    updateActiveLoanIndex: (state, action) => {
      state.activeLoanIndex = action?.payload;
    },
    updateApplicationAction: (state, action) => {
      state.loading = true;
    },
    updateApplicationActionSuccess: (state, action) => {
      state.loading = false;
      state.data = action?.payload
    },
    updateApplicationActionError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    },
    getAdditionalDocList: (state, action) => {

    },
    getAdditionalDocListSuccess: (state, action) => {
      state.additionalDocList = action?.payload;
    },
    getAdditionalDocListError: (state, action) => {
      state.error = action?.payload;
    },
    sendApplicationToLender: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    sendApplicationToLenderSuccess: (state, action) => {
      state.loading = false;
    },
    sendApplicationToLenderError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    }
  }
});

const applicationActionFields = createSlice({
  name: 'applicationActionFields',
  initialState: {
    reKycFields: [],
    taskFields: [],
    additionalDocs: [],
    fieldStatus: initialFieldStatusConstant,
    loading: false,
    error: null
  },
  reducers: {
    updateRekycFields: (state, action) => {
      const {field, data=null, code} = action?.payload;
      if(state.reKycFields?.some((obj) => (obj?.field === field) && (!data || obj?.data?.id === data?.id))){
        return;
      }
      if(state.taskFields?.some((obj) => (obj?.field === field))){
        const newArr = state.taskFields?.filter((obj) => (obj?.field != field));
        state.taskFields = newArr;
      }  
      if(field === 'aadhaar' || field === 'pan' || field === 'mobile'){
        const uniqueDir = new Set();
        state?.reKycFields?.forEach((obj) => {
          if(obj?.data?.din){
            uniqueDir.add(obj?.data?.din);
          }
        })
        if(data?.din){
          uniqueDir.add(data.din);
        }
        if(uniqueDir?.size > 5){
          toaster.show(true, 'Maximum 5 directors allowed for Re-KYC');
          return;
        }
      }
      state.reKycFields = [...state.reKycFields, action?.payload];
      state.fieldStatus = {...state?.fieldStatus, [field]: code};
    },
    updateTaskFields: (state, action) => {
      const {field, data=null, code} = action?.payload;
      if(state.taskFields?.some((obj) => (obj?.field === field))){
        return;
      }
      if(state.reKycFields?.some((obj) => (obj?.field === field))){
        const newArr = state.reKycFields?.filter((obj) => (obj?.field != field));
        state.reKycFields = newArr;
      }
      state.taskFields = [...state.taskFields, action?.payload];
      state.fieldStatus = {...state?.fieldStatus, [field]: code};
    },
    updateAdditionalDocFields: (state, action) => {
      state.additionalDocs = [...state.additionalDocs, action?.payload];
    },
    removeReKYCField: (state, action) => {
      const key = action?.payload;
      const newArr = state.reKycFields?.filter((obj) => (obj?.field != key));
      state.reKycFields = newArr;
      state.fieldStatus = {...state?.fieldStatus, [key]: 'PENDING'};
    },
    removeTaskField: (state, action) => {
      const key = action?.payload;
      const newArr = state.taskFields?.filter((obj) => (obj?.field != key));
      state.taskFields = newArr;
      state.fieldStatus = {...state?.fieldStatus, [key]: 'PENDING'};
    },
    removeAdditionalDocField: (state, action) => {
      const key = action?.payload;
      const newArr = state.additionalDocs?.filter((obj) => (obj?.field != key));
      state.additionalDocs = newArr;
    },
    clearREKYCTaskAdditionalDocs: (state, action) => {
      state.reKycFields = [];
      state.taskFields = [];
      state.additionalDocs = [];
    },
    getApplicationFieldStatus: (state, action) => {
      state.loading = true;
      state.fieldStatus = initialFieldStatusConstant; //will decide later
      state.taskFields = [];
      state.reKycFields = [];
      state.additionalDocs = [];
    },
    getApplicationFieldStatusSuccess: (state, action) => {
      state.fieldStatus = {...state?.fieldStatus, ...action?.payload};
      state.loading = false;
    },
    getApplicationFieldStatusError: (state, action) => {
      state.loading = false;
      state.error = action?.payload
    },
    handleApplicationFieldApproveReject: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    handleApplicationFieldApproveRejectSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    handleApplicationFieldApproveRejectError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    }
  }
})

const saasAction = createSlice({
  name: 'saasAction',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {
    updateSaasApplicationStatus: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateSaasApplicationStatusSuccess: (state, action) => {
      state.loading = false;
    },
    updateSaasApplicationStatusError: (state, action) => {
      state.loading = false;
      state.error = action?.payload
    }
  }
})

const applicationAssignment = createSlice({
  name: 'aaplicationAssignment',
  initialState: {
      loading: false,
      error: null
  },
  reducers: {
      assignApplication: (state, action) => {
          state.loading = true;
          state.error = null;
      },
      assignApplicationSuccess: (state, action) => {
          state.loading = false;
      },
      assignApplicationError: (state, action) => {
          state.loading = false;
          state.error = action?.payload;
      }
  }
})


export const { 
  getApplicationList,
  getApplicationListSuccess,
  getApplicationListError,
  updateActiveApplicationIndex,
  updateActiveLoanIndex,
  updateApplicationAction, 
  updateApplicationActionSuccess, 
  updateApplicationActionError,
  getAdditionalDocList,
  getAdditionalDocListSuccess,
  getAdditionalDocListError,
  sendApplicationToLender,
  sendApplicationToLenderSuccess,
  sendApplicationToLenderError
} = applicationAction?.actions;
export const { 
  updateRekycFields, 
  updateTaskFields,
  updateAdditionalDocFields,
  getApplicationFieldStatus,
  getApplicationFieldStatusSuccess,
  getApplicationFieldStatusError,
  removeReKYCField,
  removeTaskField,
  removeAdditionalDocField,
  clearREKYCTaskAdditionalDocs,
  handleApplicationFieldApproveReject,
  handleApplicationFieldApproveRejectSuccess,
  handleApplicationFieldApproveRejectError
} = applicationActionFields?.actions; 
export const {
  updateSaasApplicationStatus,
  updateSaasApplicationStatusSuccess,
  updateSaasApplicationStatusError
} = saasAction?.actions;
export const {
    assignApplication,
    assignApplicationSuccess,
    assignApplicationError
} = applicationAssignment.actions;

export const applicationReducer = {
  ...assesment,
  applicationAction: applicationAction?.reducer,
  actionFields:  applicationActionFields?.reducer,
  saas: saasAction?.reducer,
  appAssignment: applicationAssignment.reducer
}