// yourSaga.js
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { 
    getInvoiceDetailsAction, 
    getInvoiceDetailsActionFailure, 
    getInvoiceDetailsActionSuccess 
} from './loanAction';
import { Toaster } from '../../../components/toaster/Toaster';
import { ApiService } from '../../../service/ApiService';

const server = new ApiService();
const toaster = new Toaster();

// Watcher saga: spawns a new fetchData task on each getLenderList
function* watchInvoiceDetails() {
    yield takeEvery(getInvoiceDetailsAction.type, getInvoiceDetails);
}


const getInvoiceDataServer = async (payload) => (
    await server.fetchInvoiceDetails(payload).then((response) => (response)).catch((error) => (error))
);


// Worker saga: will be fired on getLenderList actions
function* getInvoiceDetails(action) {
    try {
        const response = yield call(getInvoiceDataServer, action?.payload);
        const {data} = response?.data;
        yield put(getInvoiceDetailsActionSuccess(data));
    } catch (error) {
        yield put(getInvoiceDetailsActionFailure(error));
        toaster.error(error);
    }
}





export default function* loanSaga(){
    yield all([
        fork(watchInvoiceDetails),
    ])
};
