import React from 'react';
import FloatingButton from '../FloatingButton';
import { Badge, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { useSelector } from 'react-redux';
import PD from './pd/PD';
import { PD_CLOSE } from '../../../constants/bubbles';
import useClass from '../../../hooks/useClass';
import { togglePD, toggleUpdateData } from '../../../store/customer/application/Assesment';
import UpdateApplicationData from './UpdateApplicationData';

const ApplicationButtons = () => {

  const { dispatch, permission } = useClass();
  const { activeApplicationIndex, activeLoanIndex, applicationList } = useSelector((state) => (state?.applicationAction));
  const openPD = useSelector((state) => (state?.pd?.open));

  const handlePDButtons = (e) => {
    e.preventDefault();
    e.stopPropagation();
    switch(e.target.id){
      case PD_CLOSE:
        dispatch(togglePD());
        break;
    }
  }

  const handlePD = () => {
    dispatch(togglePD());
  }

  const handleFieldEdit = () => {
    dispatch(toggleUpdateData());
  }

  if(!permission?.CUSTOMERS?.DETAILS?.CONDUCT_PD && !permission?.CUSTOMERS?.DETAILS?.EDIT_APPLICATION_FIELDS){
    return <></>;
  }
  
  return (
    <>
      <FloatingButton>
        {permission?.CUSTOMERS?.DETAILS?.CONDUCT_PD && <li>
          <a id='pd-button' onClick={handlePD}>
            <i className='ri-discuss-fill' />
            <UncontrolledPopover
              trigger="hover"
              target={'pd-button'}
              placement="top"
            >
              <PopoverBody>
                <div className='text-primary'>Personal Discussion</div>
                <Badge color='info'>{applicationList?.[activeApplicationIndex]?.applicationId}</Badge>
              </PopoverBody>
            </UncontrolledPopover>
          </a>
        </li>}
        {permission?.CUSTOMERS?.DETAILS?.EDIT_APPLICATION_FIELDS && <li>
          <a id='edit-field' onClick={handleFieldEdit}>
            <i className='ri-edit-fill' />
            <UncontrolledPopover
              trigger="hover"
              target={'edit-field'}
              placement="top"
            >
              <PopoverBody>
                <div className='text-primary'>Edit</div>
              </PopoverBody>
            </UncontrolledPopover>
          </a>
        </li>}
      </FloatingButton>
      {openPD && 
        <div onClick={handlePDButtons}>
          <PD />
        </div>
      }
      <UpdateApplicationData />
    </>
  )
}

export default React.memo(ApplicationButtons);
