import { call, put, takeEvery, all, fork, takeLatest } from 'redux-saga/effects';
import { sendData, sendDataError, sendDataSuccess } from './actions';
import { Toaster } from '../../../components/toaster/Toaster';
import { ApiService } from '../../../service/ApiService';

const toaster = new Toaster();
const server = new ApiService();

function* watchSendData() {
    yield takeLatest(sendData.type, updateData);
}

function* updateData({payload}) {
    try {
        const response = yield call(server.updateData, payload);
        yield put(sendDataSuccess());
    } catch(error) {
        toaster.error(error);
        yield put(sendDataError(error));
    }
}

export default function* breSaga(){
    yield all([
        fork(watchSendData)
    ])
};