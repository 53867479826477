import React, { useEffect, useState } from "react";
import useClass from "../../../hooks/useClass";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const Details = () => {
  const { adminServer, toaster } = useClass();
  const [loading, setLoading] = useState(true);
  const [log, setLog] = useState({});

  const { logId } = useParams();

  useEffect(() => {
    setLoading(true);
    getLogDetails(logId);
  }, []);

  const getLogDetails = async (logId) => {
    try {
      const response = await adminServer.getLog(logId);
      const data = response?.data?.data;
      setLog(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toaster.error(error);
    }
  };

  return (
    <div>
      <Row className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="api_logs" breadcrumbItem="Log Details" />
          <Row>
            <Col xl={12}>
              <Card className="common-card-style">
                <CardHeader className="d-flex justify-content-between">
                  <div className="section-heading">Log Basic Details</div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Log Id</Row>
                      <Row className="data-value me-4">{log?.id}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">User Id</Row>
                      <Row className="data-value me-4">{log?.userId}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">customer Code</Row>
                      <Row className="data-value me-4">{log?.customerCode}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Request Code</Row>
                      <Row className="data-value me-4">{log?.requestCode}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Request Method</Row>
                      <Row className="data-value me-4">
                        {log?.requestMethod}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Request Type</Row>
                      <Row className="data-value me-4">{log?.requestType}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Request Url</Row>
                      <Row className="data-value me-4">{log?.requestUrl}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Request Time</Row>
                      <Row className="data-value me-4">{log?.requestedAt}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Response Time</Row>
                      <Row className="data-value me-4">{log?.responseAt}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Response Status Code</Row>
                      <Row className="data-value me-4">
                        {log?.responseStatusCode}
                      </Row>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card className="common-card-style">
                <CardHeader className="d-flex justify-content-between">
                  <div className="section-heading">Request Log Json Data</div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="d-flex flex-column mb-2 col-12">
                      <div className="data-key ms-4">Request Body</div>
                      <div className="overflow-content">
                        {JSON.stringify(log?.log?.requestBody, null, 2)}
                      </div>
                    </div>
                    <div className="d-flex flex-column mb-2 col-12">
                      <div className="data-key ms-4">Request Headers</div>
                      <div className="overflow-content">
                        {JSON.stringify(log?.log?.requestHeaders, null, 2)}
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="common-card-style">
                <CardHeader className="d-flex justify-content-between">
                  <div className="section-heading">Response Log Json Data</div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="d-flex flex-column mb-2 col-12">
                      <div className="data-key ms-4">Response Body</div>
                      <div className="overflow-content">
                        {JSON.stringify(log?.log?.responseBody, null, 2)}
                      </div>
                    </div>
                    <div className="d-flex flex-column mb-2 col-12">
                      <div className="data-key ms-4">Response Headers</div>
                      <div className="overflow-content">
                        {JSON.stringify(log?.log?.responseHeaders, null, 2)}
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Row>
    </div>
  );
};

export default Details;
