import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { formatDate3 } from '../../../utils/helper';

const DebtorDetails = () => {

    const { entityDetails } = useSelector((state) => (state?.entity));
    const [topDebtors, setTopDebtors] = useState({});
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if(entityDetails?.additionalData){
            // const debtors = entityDetails?.additionalData?.filter((obj) => (obj?.fieldKey === 'topDebtors'))?.[0]?.value?.filter((data) => (data?.value !== 'NA'));
            const details = entityDetails?.additionalData?.filter((obj) => (obj?.fieldKey === 'debtorDetails'))?.[0]?.value;
            // let arr = [];
            // debtors?.forEach((debt) => {
            //     const debtDetails = details?.filter((obj) => (obj?.name === debt?.value))?.[0];
            //     arr = [...arr, {...debt, ...debtDetails}];
            // })
            setTopDebtors(details);
        }
    }, [entityDetails]);

    const toggleTab = (id) => {
        return () => {
            setActiveTab(id);
        }
    }

    return (
        <>
            {topDebtors?.length > 0 && <Row className='ps-4 pe-4'>
                <Col style={{fontWeight: 'bold'}} className='mb-2'>Top Lenders</Col>
                <Nav tabs>
                    {topDebtors?.map((debtor, index) => (
                        <NavItem key={`topDebtors${index}`}>
                            <NavLink 
                                style={{color: activeTab === index && 'green' }} 
                                className={`${activeTab === index && 'active'} clickable`} 
                                onClick={toggleTab(index)}
                            >
                              {debtor?.name}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
                <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId={activeTab} id="topdebtors">
                        <Row>
                            <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                <div className='data-key'>Type of loan</div>
                                <div className='data-value'>{topDebtors?.[activeTab]?.typeOfLoan}</div>
                            </div>
                            <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                <div className='data-key'>Disbursment Date</div>
                                <div className='data-value'>{formatDate3(topDebtors?.[activeTab]?.disbursmentDate)}</div>
                            </div>
                            <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                <div className='data-key'>Total principle amount</div>
                                <div className='data-value'>₹ {topDebtors?.[activeTab]?.totalPrincipleAmount ? parseInt(topDebtors?.[activeTab]?.totalPrincipleAmount)?.toLocaleString() : '-'}</div>
                            </div>
                            <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                <div className='data-key'>Total outstanding</div>
                                <div className='data-value'>₹ {parseInt(topDebtors?.[activeTab]?.totalOutstanding)?.toLocaleString()}</div>
                            </div>
                            <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                <div className='data-key'>Total loan amount</div>
                                <div className='data-value'>₹ {parseInt(topDebtors?.[activeTab]?.totalLoanAmount)?.toLocaleString()}</div>
                            </div>
                            <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                <div className='data-key'>EMI amount</div>
                                <div className='data-value'>₹ {topDebtors?.[activeTab]?.emiAmount ? parseInt(topDebtors?.[activeTab]?.emiAmount)?.toLocaleString() : '-'}</div>
                            </div>
                            <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                <div className='data-key'>Loan tenor</div>
                                <div className='data-value'>{topDebtors?.[activeTab]?.loanTenure} months</div>
                            </div>
                        </Row>
                    </TabPane>
                </TabContent>
            </Row>}
        </>
    )
}

export default React.memo(DebtorDetails);
