import { createSlice } from "@reduxjs/toolkit";

const customerEmiSchedule = createSlice({
    name: 'customerEmiSchedule',
    initialState: {
        loading: false,
        error: null,
        totalList: 0,
        emiScheduleList:[],
    },
    reducers: {
        getCustomerEmiScheduleLoading: (state, action) => {
            state.loading = true;
        },
        getCustomerEmiScheduleSuccess: (state, action) => {
            state.loading = false;
            state.emiScheduleList = action?.payload;
        },
        getCustomerEmiScheduleError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
});





export const { 
    getCustomerEmiScheduleLoading,
    getCustomerEmiScheduleSuccess,
    getCustomerEmiScheduleError,
} = customerEmiSchedule?.actions;


export const emiScheduleReducer = {
    emiScedule: customerEmiSchedule?.reducer
}