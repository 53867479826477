import React, { useEffect, useState } from 'react';
import useClass from '../../../hooks/useClass';
import { 
  Spinner, 
  Card, 
  CardHeader, 
  CardBody, 
  Nav, 
  NavItem, 
  NavLink, 
  TabContent, 
  TabPane, 
  Row,
  Button
} from 'reactstrap';
import CustomUncontrolledDropdown from '../../../components/Dropdown/CustomUncontrolledDropdown';
import LoadingButton from '../../../components/Common/LoadingButton';
import { useSelector } from 'react-redux';
import { getTeamList } from '../../../store/admin/adminData';
import { useLocation, useParams } from 'react-router-dom';
import { getEligibleLendersOfApplication } from '../../../store/customer/customer';
import Board from '../../../components/Floater/Board';
import CustomerModal from '../Modal';
import Loans from '../loan/Loans';
import Data from './Data';
import { 
  assignApplication, 
  getApplicationFieldStatus, 
  getApplicationList, 
  updateActiveApplicationIndex, 
  updateSaasApplicationStatus 
} from '../../../store/customer/application/applicationAction';
import { updateLoanAction } from '../../../store/customer/loan/loanAction';
import ApplicationButtons from '../../../components/Floater/application/ApplicationButtons';
import Vehicle from './Vehicle';

const Applications = () => {

  const location = useLocation();
  const {customerCode} = useParams();
  const queryParams = new URLSearchParams(location.search);
  const applicationId = queryParams.get('applicationId');
  const { toaster, permission, dispatch } = useClass();
  const {list: teamList, loading: teamListLoading} = useSelector((state) => (state?.team));
  const { activeApplicationIndex, activeLoanIndex, applicationList: applications, loading } = useSelector((state) => (state?.applicationAction));
  const [assignedTo, setAssignedTo] = useState({});
  const [assignedTeam, setAssignedTeam] = useState({});
  const [floaters, setFloaters] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    dispatch(getApplicationList(customerCode));
  }, []);

  useEffect(() => {
    applications?.forEach((application, index) => {
      if(application?.applicationId === applicationId){
        dispatch(updateActiveApplicationIndex(index));
        dispatch(getEligibleLendersOfApplication(applications?.[index]?.applicationId));
      }
    })
  }, [applications])

  useEffect(() => {
    setAssignedTo({
      userName: applications?.[activeApplicationIndex]?.userName, 
      userId: applications?.[activeApplicationIndex]?.userId,
      teamName: applications?.[activeApplicationIndex]?.teamName,
      teamId: applications?.[activeApplicationIndex]?.teamId
    });
    if(applications?.[activeApplicationIndex]?.applicationId){
      dispatch(getApplicationFieldStatus(applications?.[activeApplicationIndex]?.applicationId));
    }
  }, [applications, activeApplicationIndex]);

  useEffect(() => {
    teamList?.forEach((team) => {
      if(team?.id === applications?.[activeApplicationIndex]?.teamId){
        const members = team?.members?.map((member) => ({...member, label: member.name, value: member.userId}))
        let data = {...team};
        data.members = members;
        setAssignedTeam(data);
      }
    })
  }, [teamList, activeApplicationIndex]);
    
  const toggleApplicationTab = (tab) => {
    return () => {
      if (activeApplicationIndex === tab) {
        return;
      }
      dispatch(updateActiveApplicationIndex(tab));
      dispatch(getEligibleLendersOfApplication(applications?.[tab]?.applicationId));
    }
  };

  const handleUserAssignment = (user) => {
    return () => {
      dispatch(assignApplication({user, team: {id: applications[activeApplicationIndex]?.teamId}, applications: [applications[activeApplicationIndex]?.applicationId]}))
    }
  }

  const handleTeamAssignment = (team) => {
    return () => {
      dispatch(assignApplication({team, user: null, applications: [applications[activeApplicationIndex]?.applicationId]}))
    }
  }

  const handleActionButtonClick = (action) => {
    return async () => {
      if(applications?.[activeApplicationIndex]?.productType == 'SOFTWARE') {
        const buttonCont = action?.includes('REJECT') ? 'Reject' : action?.includes('HOLD') ? 'Hold' : 'Approve';
        const result = await toaster.confirmation(buttonCont, `${buttonCont} ${applications?.[activeApplicationIndex]?.productName}`);
        if(result?.isConfirmed){
          dispatch(updateSaasApplicationStatus({action, softwareUid: applications[activeApplicationIndex]?.software?.[0]?.softwareUid}))
        }
      } else {
        handleAction(
          action, 
          applications[activeApplicationIndex]?.loan?.[activeLoanIndex]?.uid, 
          applications?.[activeApplicationIndex]?.productCode,
          applications?.[activeApplicationIndex]?.applicationId
        )
      }
    }
  }

  const fetchTeamList = (search) => {
    if(permission?.TEAMS?.LIST?.VIEW_TEAM_LIST_ALL){
      dispatch(getTeamList({search}));
    }
  }

  const toggleFloaters = (key) => {
    console.log(floaters)
    setFloaters((prev) => ({
      ...prev,
      [key]: !floaters?.[key]
    }))
  }

  const handleAction = async (action, loanUid, productCode, applicationId) => {
    dispatch(updateLoanAction({action, loanUid, productCode, applicationId}));
    setIsOpen(true);
  }

  return (
    <>
      <Card className='common-card-style'>
        <CardHeader>
          <div className='section-heading'>Applications</div>             
        </CardHeader>
        <CardBody>
        { loading ? 
          <div className='text-center'>
            <Spinner></Spinner>
          </div> 
          : 
          <>
            <Nav tabs>
              {applications?.map((application, index) => (
                <NavItem key={`applictions${index}`}>
                  <NavLink 
                    style={{color: activeApplicationIndex === index && 'green' }} 
                    className={`${activeApplicationIndex === index && 'active'} clickable`} 
                    onClick={toggleApplicationTab(index)}
                  >
                    <i className="dripicons-mail me-1 align-middle"> </i>{" "}
                    {application?.productName} - {application?.applicationId}
                    {application?.status === 'UNDER_REVIEW' && <i style={{marginLeft: '0.4rem'}} className='fas fa-check-circle'></i>}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>  
            <TabContent activeTab={activeApplicationIndex} className="pt-4">
              <TabPane tabId={activeApplicationIndex} id="home">
                <React.Fragment key={`apptablko${activeApplicationIndex}`}>
                  {applications?.length > 0 &&
                    <>
                      <Row>
                        <Data />
                        <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                          <Row className='data-key ms-4'>Assigned Team</Row>
                          <Row className='data-value me-4'>
                            {permission?.CUSTOMERS?.DETAILS?.ASSIGN_APPLICATION_TO_USER ? <CustomUncontrolledDropdown
                              options={teamList}
                              onClick={handleTeamAssignment}
                              value={assignedTo?.teamName || 'None'}
                              handleSearch={fetchTeamList}
                              loading={teamListLoading}
                            /> : <>{assignedTo?.teamName || 'None'}</>}
                          </Row>
                        </div>
                        <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                          <Row className='data-key ms-4'>Assigned To</Row>
                          <Row className='data-value me-4'>
                            {permission?.CUSTOMERS?.DETAILS?.ASSIGN_APPLICATION_TO_USER ? <CustomUncontrolledDropdown
                              options={assignedTeam?.members || []}
                              onClick={handleUserAssignment}
                              value={assignedTo?.userName || 'None'}
                              showSearch={false}
                            /> : <>{assignedTo?.userName || 'None'}</>}
                          </Row>
                        </div>
                        <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                          <div className='data-key ms-4'>
                            Notes
                          </div>
                          <div className='data-value me-4'><Button onClick={() => toggleFloaters('application')} className='p-0' color='link'>Take</Button></div>
                        </div>
                      </Row>
                      <Vehicle />
                    </>
                  }
                </React.Fragment>
                <Loans />
                <div className='d-flex flex-direction-row gap-4 justify-content-center flex-wrap mt-4'>
                  {(permission?.ACTIONS?.LOAN) && applications[activeApplicationIndex]?.loan?.[activeLoanIndex]?.availableActions?.map((obj, index) => {
                    if(permission?.ACTIONS?.LOAN?.[obj?.code]){
                      return (
                        <span key={`actionl${index}`}>
                          <LoadingButton
                            color={obj?.color}
                            buttonContent={obj?.lable}
                            onClick={handleActionButtonClick(obj?.code)}
                          />
                        </span>
                      )
                    }
                  })}
                  
                  {(permission?.ACTIONS?.SOFTWARE) && applications[activeApplicationIndex]?.software?.[0]?.availableActions?.map((obj, index) => {
                    if(permission?.ACTIONS?.SOFTWARE?.[obj?.code] || true){
                      return (
                        <span key={`actions${index}`}>
                          <LoadingButton 
                            color={obj?.color}
                            buttonContent={obj?.lable}
                            onClick={handleActionButtonClick(obj?.code)}
                          />
                        </span>
                      )
                    }
                  })}
                </div>
              </TabPane>
            </TabContent>
          </> 
          }
        </CardBody>
      </Card>
      {floaters?.application && <Board
        header={`Application-${applications?.[activeApplicationIndex]?.applicationId}`}
        code={applications?.[activeApplicationIndex]?.applicationId}
        onCancel={() => toggleFloaters('application')}
        onSave={() => toggleFloaters('application')}
      />}
      <CustomerModal isOpen={isOpen} setIsOpen={setIsOpen}/>
      {!loading && <ApplicationButtons />}
    </>
  )
}

export default Applications;
