import './text-input.scss';

const TextInput = (props) => {

  const {
    name,
    showField=true,
    customClass,
    label,
    required,
    preInputText, 
    postInputText,
    placeholder, 
    value="", 
    handleInput,
    supportiveText,
    disabled=false,
    maxLength,
    inputMode='text',
    type='text',
    isValid=false,
    isInValid=false,
  } = props;

  const emmitValue = (e) => {
    if(maxLength){
      if(e.target.value.toString().length <= maxLength){
        handleInput(e.target.name, e.target.value);
      }
    } else {
      handleInput(e.target.name, e.target.value);
    }
  }

  return (
    <>
      {showField && 
      <div className={`${customClass}`}>
        <div className='text-input'>
          <div className='heading'>{label}<span>{required && '*'}</span></div>
          <div className="input-group">
            {preInputText && <span className="input-group-text">{preInputText}</span>}
            <input 
              type={type} 
              className={`form-control ${isValid && 'is-valid'} ${isInValid && 'red-border'}`}
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              onChange={emmitValue}
              maxLength={maxLength}
              inputMode={inputMode}
              required={required}
              name={name}
            />
            {postInputText && <span className="input-group-text">{postInputText}</span>}
          </div>
          <div className={`${isValid && 'valid-feedback'} ${isInValid && 'invalid-feedback'}`}>{supportiveText}</div>
        </div>
      </div>}
    </>
  )
}

export default TextInput;
