import React from 'react';
import { DatePicker } from 'rsuite';
import '../text-input/text-input.scss';

const DatePickerCustom = ({
  disabledDates, 
  value, 
  customClass, 
  required, 
  label, 
  isValid, 
  isInValid, 
  supportiveText,
  name,
  handleInput
}) => {

  const onChange = (date) => {
    handleInput(name, date);
  }

  const formatDateStringToDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split('-');
    return new Date(year, month - 1, day); // Months are 0-indexed in JavaScript's Date object
  };

  return (
    <>
      <div className={`${customClass}`}>
      <div className='text-input'>
          <div className='heading'>{label}<span>{required && '*'}</span></div>
          <div className="input-grou">
            <DatePicker
              className={`${isInValid && 'red-border'}`}
              disabledDate={disabledDates}
              format="dd-MM-yyyy"
              placeholder="Select a date"
              value={formatDateStringToDate(value)}
              onChange={onChange}
              name={name}
              oneTap
            />
          </div>
          <div className={`${isValid && 'valid-feedback'} ${isInValid && 'invalid-feedback'}`}>{supportiveText}</div>
        </div>
      </div>
    </>
  )
}

export default React.memo(DatePickerCustom);
