import { createSlice } from "@reduxjs/toolkit";
import { applicationEditableFields } from "../../../constants/journeyKey";

const personalDiscussion = createSlice({
  name: 'pd',
  initialState: {
    questions: [],
    prevPD: [],
    loading: false,
    error: null,
    searching: false,
    selectedQuestions: [],
    open: false
  },
  reducers: {
    togglePD: (state, action) => {
      state.open = !state.open;
    },
    getPDQuestions: (state, action) => {
      state.searching = true;
      state.error = null;
    },
    getPDQuestionsSuccess: (state, action) => {
      state.searching = false;
      state.questions = action?.payload;
    },
    getPDQuestionsError: (state, action) => {
      state.searching = false;
      state.error = action?.payload
    },
    getPreviousPD: (state, action) => {
      state.error = null;
    },
    getPreviousPDSuccess: (state, action) => {
      state.prevPD = action?.payload;
    },
    getPreviousPDError: (state, action) => {
      state.error = action?.payload;
    },
    submitPD: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    submitPDSuccess: (state, action) => {
      state.loading = false;
      state.open = false;
      state.selectedQuestions = [];
    },
    submitPDError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    },
    selectQuestion: (state, action) => {
      let arr = state.selectedQuestions;
      arr = [...arr, {question: action?.payload, answer:null}]
      state.selectedQuestions = arr;
    },
    deleteQuestion: (state, action) => {
      let arr = state.selectedQuestions?.filter((_, i) => (i !== action?.payload));
      state.selectedQuestions = arr;
    },
    updateAnswer: (state, action) => {
      let quest = state.selectedQuestions[action?.payload?.index];
      quest.answer = action?.payload?.answer;
      state.selectedQuestions[action?.payload?.index] = quest;
    }
  }
});

const editApplicationData = createSlice({
  name: 'appDataUpdate',
  initialState: {
    loading: false,
    error: null,
    open: false,
    fields: applicationEditableFields
  },
  reducers: {
    toggleUpdateData: (state, action) => {
      state.open = !state.open;
    },
    updateFieldValue: (state, action) => {
      state.fields[action.payload.index].value = action.payload.value;
    },
    updateApplicationData: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateApplicationDataSuccess: (state, action) => {
      state.loading = false;
      state.open = false;
    },
    updateApplicationDataError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
})

export const  {
  getPDQuestions,
  getPDQuestionsSuccess,
  getPDQuestionsError,
  getPreviousPD,
  getPreviousPDSuccess,
  getPreviousPDError,
  submitPD,
  submitPDSuccess,
  submitPDError,
  selectQuestion,
  deleteQuestion,
  updateAnswer,
  togglePD
} = personalDiscussion.actions;

export const {
  toggleUpdateData,
  updateFieldValue,
  updateApplicationData,
  updateApplicationDataSuccess,
  updateApplicationDataError
} = editApplicationData.actions;

const assesment = {
  pd: personalDiscussion.reducer,
  editData: editApplicationData.reducer
}

export default assesment;