import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ScoreChart = ({percentage, value}) => {

  const chartParams = {
          
    series: [percentage],
    options: {
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: '#999',
              opacity: 1,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px',
              formatter: function () {
                // Display the credit score
                return value || 0;
              },
            }
          }
        }
      },
      grid: {
        padding: {
          top: -10
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        },
      },
      labels: ['Average Results'],
    },
};

  return (
    <>
      <ReactApexChart options={chartParams.options} series={chartParams.series} type="radialBar" height={350} />
    </>
  )
}

export default React.memo(ScoreChart);
