import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import TextInput from '../../../components/text-input/TextInput';
import { FILL_ALL_MENDETORY_FIELDS } from '../../../utils/error';
  

const CreateRole = (props) => {

  const { toaster, adminServer, navigate, permission } = useClass();
  const {isOpen, setIsOpen} = props;
  const [roleName, setRoleName] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleModelClose = () => {
    setIsOpen(false);
  }

  const handleRoleCreation = async () => {
    try{
      if(validateForm()){
        setIsLoading(true);
        const response = await adminServer.createNewRole({roleName, description});
        setIsLoading(false);
        if(permission?.ROLES?.DETAILS?.VIEW_ROLE_DETAILS){
          navigate(`/roles/details/${response?.data?.roleId}`);
        }
      } else {
        toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
      }
    } catch(error){
      setIsLoading(false);
      toaster.error(error);
    }
  }

  const validateForm = () => {
    return roleName.trim() !== "" && description.trim() !== "";
  }

  return (
    <>
      <Modal 
        isOpen={isOpen}
        toggle={handleModelClose}
        centered
      >
        <ModalHeader toggle={handleModelClose}>
          Create Role
        </ModalHeader>
        <ModalBody>
          <div>
            <TextInput 
              label={'Role Name'}
              required={true}
              name={'roleName'}
              value={roleName}
              handleInput={(key, value) => {setRoleName(value)}}
              type={'text'}
            />
          </div>
          <div className='mt-4'>
            <TextInput 
              label={'Role Description'}
              required={true}
              name={'description'}
              value={description}
              handleInput={(key, value) => {setDescription(value)}}
              type={'text'}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
            Close
          </Button>
          <LoadingButton
            buttonContent={'Create'}
            isLoading={isLoading}
            onClick={handleRoleCreation}
            color={'success'}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default CreateRole;
