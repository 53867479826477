import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useClass from "../../../hooks/useClass";
import TextInput from "../../../components/text-input/TextInput";
import Dropdown from "../../../components/Dropdown/Dropdown";
import LoadingButton from "../../../components/Common/LoadingButton";
import { FILL_ALL_MENDETORY_FIELDS } from "../../../utils/error";

const CreateProduct = (props) => {

  const { adminServer, toaster, navigate } = useClass();
  const { isOpen, setIsOpen } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: ''
  });

  const handleModelClose = () => {
    setIsOpen(false);
  };

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const createProductScheme = async () => {
    try {
      if(validateForm(form)){
        setLoading(true);
        const response = await adminServer.createScheme(form);
        setLoading(false);
        setIsOpen(false);
        navigate(`/product_scheme/details/${response?.data?.data?.code}`);
      } else {
        toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
      }
    } catch (error) {
      setLoading(false);
      toaster.error(error);
    }
  };

  const validateForm = (payload) => {
    const result = Object.keys(payload).every((key) => {
      return form.hasOwnProperty(key) && form[key] !== "";
    });
    return result;
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={handleModelClose}
        centered
      >
        <ModalHeader toggle={handleModelClose}>
            Create Product Scheme
        </ModalHeader>
        <ModalBody>
          <div>
            <TextInput
              name={'name'}
              label={'Scheme Name'}
              value={form?.name}
              required={true}
              handleInput={handleInput}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <LoadingButton
            buttonContent={"Create"}
            isLoading={loading}
            onClick={createProductScheme}
            color={"success"}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreateProduct;
