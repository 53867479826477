import React, { useState } from 'react';
import useClass from '../../../hooks/useClass';
import { useSelector } from 'react-redux';
import LoadingButton from '../../../components/Common/LoadingButton';
import TextInput from '../../../components/text-input/TextInput';
import InvoiceTable from './InVoice';
import { FILL_ALL_MENDETORY_FIELDS } from '../../../utils/error';

const Default = (props) => {

    const {
      handleModelClose,
      isLoading,
      handleAction
    } = props;
    
    const { toaster } = useClass();
    const { action, loanUid } = useSelector((state) => (state?.loanAction?.data));
    const { activeApplicationIndex, applicationList } = useSelector((state) => (state?.applicationAction));
    const [form, setForm] = useState({
      reason: '',
      comment: ''
    });

    const handleInput = (key, value) => {
      if(key === 'sanctionDate'){
        if(new Date(value) > new Date()){
          return;
        }
      }
      setForm((prev) => ({
        ...prev,
        [key]: value
      }))
    }

    const handleSubmit = () => {
      if(validateForm()){
        handleAction({action, loanUid, comment: form})
      } else {
        toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
      }
    }
    
    const validateForm = () => {
      const result = Object.keys(form).every((key) => {
        return form.hasOwnProperty(key) && form[key]?.trim() !== "";
      });
      const lenderApprove = (action !== 'LENDER_APPROVE') || (form?.hasOwnProperty('amountApproved') && form?.amountApproved?.trim() !== '');
      const disburse = (action !== 'DISBURSE') || (form?.hasOwnProperty('amountDisbursed') && form?.amountDisbursed?.trim() !== '');
      return result && lenderApprove && disburse;
    }


    return (
      <>
        <div className="modal-body">
        {action !== 'INVOICE_GENERATE' && <div>
          <TextInput
              label={'Reason'}
              value={form?.reason}
              required={true}
              type={'text'}
              name={'reason'}
              handleInput={handleInput}
            />
          </div>}
          {action === 'LENDER_APPROVE' && <div className='mt-4'>
             <TextInput
              label={'Approved Amount'}
              value={form?.amountApproved}
              required={true}
              type={'number'}
              name={'amountApproved'}
              handleInput={handleInput}
            />
             <div className='mt-4'>
              <TextInput
              label={'Lender LoanUid'}
              value={form?.lenderLoanUid}
              required={true}
              type={'text'}
              name={'lenderLoanUid'}
              handleInput={handleInput}
            />
            </div>
            <div className='mt-4'>
            <TextInput
              label={'Sanction Date'}
              value={form?.sanctionDate}
              required={true}
              type={'date'}
              name={'sanctionDate'}
              handleInput={handleInput}
            />
             </div>
            </div>}
          {action === 'DISBURSE' && <div className='mt-4'>
            <TextInput
              label={'Disbursed Amount'}
              value={form?.amountDisbursed}
              required={true}
              type={'number'}
              name={'amountDisbursed'}
              handleInput={handleInput}
            />
          </div>}
          {/* {action === 'PREVIEW_INVOICE' && <div className='mt-4'>
            <TextInput
              label={'GST Number'}
              value={form?.gstNumber}
              required={true}
              type={'string'}
              name={'gstNumber'}
              handleInput={handleInput}
            />
          </div>} */}
          {action === 'INVOICE_GENERATE' && <div className='mt-4'>
            <InvoiceTable applicationList={applicationList} setForm={setForm} generateInvoice={handleSubmit} loanUid={loanUid}/>
          </div>}
          {action !== 'INVOICE_GENERATE' && <div className='mt-4'>
            <div className='text-input'>
              <div className='heading'>Comment<span className='text-danger'>*</span></div>
              <div className="input-group">
                <textarea
                  className='form-control'
                  placeholder='Any comment...'
                  value={form?.comment}
                  onChange={(e) => handleInput('comment', e.target.value)}
                  cols={12}
                  rows={3}
                />
              </div>
            </div>
          </div> }
        </div>
        <div className="modal-footer">
          {action !== 'INVOICE_GENERATE' && <LoadingButton
            buttonContent={'Submit'}
            isLoading={isLoading}
            onClick={handleSubmit}
            color={'success'}
          />}
        </div>
      </>
  )
}

export default Default;
