import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Lenders from '../../Pages/Admin/Lenders/Lenders';
import Details from '../../Pages/Admin/Lenders/Details';
import useClass from '../../hooks/useClass';
import { getLenderConfigFields } from '../../store/utils/actions';

const Lender = () => {

  const { dispatch } = useClass();

  useEffect(() => {
    dispatch(getLenderConfigFields());
  }, [])

  return (
    <Routes>
        <Route path='/list' element={<Lenders />} />
        <Route path='/details/:lenderCode' element={<Details />} />
    </Routes>
  )
}

export default Lender;
