import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import { updateLeadTask } from '../../../store/user/userSlice';
import { useParams } from 'react-router-dom';

const StatusChange = ({isOpen, setIsOpen, data}) => {

  const { leadCode } = useParams();
  const { dispatch, toaster } = useClass();
  const [comment, setComment] = useState('');

  const handleSubmit = () => {
    if(!comment || !comment.trim()){
      toaster.show(true, 'Please add comment');
      return;
    }
    dispatch(updateLeadTask({payload: {taskStatusData: {status: data?.data?.value}, comment}, taskCode: data?.task?.code, leadCode}));
    setIsOpen(false);
  }

  const handleInput = (e) => {
    setComment(e.target.value);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        size={'md'}
        toggle={() => setIsOpen(!isOpen)}
        centered
      >
        <ModalHeader toggle={() => setIsOpen(!isOpen)}>
          Move To - <span className='text-info'>{data?.data?.label}</span>
        </ModalHeader>
        <ModalBody>
          <div className='text-input'>
            <div className="input-group">
              <textarea
                className='form-control'
                placeholder='Any comment...'
                value={comment}
                onChange={handleInput}
                cols={8}
                rows={3}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <LoadingButton
            buttonContent={'Submit'}
            onClick={handleSubmit}
            color={'success'}
          />
        </ModalFooter>
      </Modal>   
    </>
  )
}

export default React.memo(StatusChange);
