import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { agreementType } from '../../../utils/constants';
import { FILL_ALL_MENDETORY_FIELDS } from '../../../utils/error';

const Create = (props) => {

  const { toaster, adminServer, permission, navigate } = useClass();
  const {isOpen, setIsOpen, selectedTemplated} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    type: ''
  });

  const handleModelClose = () => {
    setIsOpen(false);
  }

  const handleInput = (key, value) => {
    setForm((prev) => ({
        ...prev,
        [key]: value
    }))
  }

  const handleAgreementCreation = async () => {
    try{
      if(validateForm()){
        setIsLoading(true);
        const response = await adminServer.createNewAgreement({type: form?.type?.value, code: selectedTemplated});
        setIsLoading(false);
        if(response?.data?.success){
          handleModelClose();
          if(permission?.AGREEMENT?.DETAILS?.VIEW_AGREEMENT_DETAILS){
            navigate(`/agreement/details/${response?.data?.data?.code}`);
          } else {
            window.location.reload();
            toaster.show(false, 'Task created successfully');
          }
        }
      } else {
        toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
      }
    } catch(error){
      setIsLoading(false);
      toaster.error(error);
    }
  }

  const validateForm = () => {
    const result = Object.keys(form).every((key) => {
        return form.hasOwnProperty(key) && form[key] !== "";
    });
    return result;
  }

  return (
    <>
      <Modal isOpen={isOpen}
        toggle={handleModelClose}
        centered
      >
        <ModalHeader toggle={handleModelClose}>
          Select Agreement Type
        </ModalHeader>
        <ModalBody>
          <div className='mt-4'>
            <Dropdown
              label={'Type'}
              options={agreementType}
              handleInput={handleInput}
              required={true}
              value={form?.type}
              name={'type'}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <LoadingButton
            buttonContent={'Create'}
            isLoading={isLoading}
            onClick={handleAgreementCreation}
            color={'success'}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default Create;
