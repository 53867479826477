import React from 'react';
import useClass from '../../../hooks/useClass';
import { toggleCAM } from '../../../store/customer/cam/action';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';

const ChildLoans = ({row}) => {

  const { permission, dispatch } = useClass();
  const {list: lenderList} = useSelector((state) => (state?.eligibleLenders))

  const handleSendToLender = () => {
    const lender =  lenderList?.filter((obj) => (obj?.code === row?.lenderCode))?.[0] || {};
    dispatch(toggleCAM(lender)); 
  }

  return (
    <>
      {permission?.CUSTOMERS?.DETAILS?.SEND_TO_LENDER && <>
          <Button className='ms-2' size='sm' color='warning' onClick={() => {}}><i className='ri-edit-2-fill' /></Button>
          {lenderList?.length > 0 && <Button className='ms-2' size='sm' color='info' onClick={handleSendToLender}><i className='ri-send-plane-fill' /></Button>}
        </>
      }
    </>
  )
}

export default React.memo(ChildLoans);
