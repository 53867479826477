export const basicInfo = {
    'bussinessName': 'Business Name',
    'bussinessType': 'Business Type',
    'bussinessIncorporationYear': 'Business Incorporation Year',
    'existingVehicles': 'Existing Vehicles',
    'revenueLastAudited': 'Revenue Last Auduted',
    'pbtLastAudited': 'PBT Last Audited',
    'lastProvisionalRevenue': 'Last Provisional Revenue',
    'pbtLastProvisional': 'PBT Last Provisional',
    'projectedRevenue': 'Projected Revenue',
    'pbtProjected': 'PBT Projected'
}

export const requirements = {
    'numberOfVehicleRequired': 'Number Of Vehicles required',
    'manufacturer': 'Menufacturer',
    'model': 'Model',
    'variant': 'Variant'
}

export const typeOfBusiness = [
    { label: 'Battery Manufacturing', value: 'Battery Manufacturing' },
    { label: 'Battery Swapping', value: 'Battery Swapping' },
    { label: 'CPO/CNO', value: 'CPO/CNO' },
    { label: 'Dealer', value: 'Dealer' },
    { label: 'Fleet-Goods Mobility', value: 'Fleet-Goods Mobility' },
    { label: 'Fleet-Passenger Mobility', value: 'Fleet-Passenger Mobility' },
    { label: 'Other Battery Related', value: 'Other Battery Related' },
    { label: 'Vehicle Manufacturer', value: 'Vehicle Manufacturer' },
    { label: 'Others', value: 'Others' }
]

export const defaultCityList = [
    { label: 'Mumbai', value: 'Mumbai' },
    { label: 'Delhi', value: 'Delhi' },
    { label: 'Bengaluru', value: 'Bengaluru' },
    { label: 'Hyderabad', value: 'Hyderabad' },
    { label: 'Ahmedabad', value: 'Ahmedabad' },
    { label: 'Chennai', value: 'Chennai' },
    { label: 'Kolkata', value: 'Kolkata' },
    { label: 'Pune', value: 'Pune' },
    { label: 'Jaipur', value: 'Jaipur' },
    { label: 'Surat', value: 'Surat' },
    { label: 'Lucknow', value: 'Lucknow' },
    { label: 'Kanpur', value: 'Kanpur' },
    { label: 'Nagpur', value: 'Nagpur' },
    { label: 'Indore', value: 'Indore' },
    { label: 'Thane', value: 'Thane' },
    { label: 'Bhopal', value: 'Bhopal' },
    { label: 'Visakhapatnam', value: 'Visakhapatnam' },
    { label: 'Patna', value: 'Patna' },
    { label: 'Vadodara', value: 'Vadodara' },
    { label: 'Ghaziabad', value: 'Ghaziabad' }
];

export const applicationEditableFields = [
    {
        label: 'Last Audited Revenue',
        code: 'revenueLastAudited',
        value: '',
        preInputText: '₹',
        fieldType: 'string'
    },
    {
        label: 'Last Audited PBT',
        code: 'pbtLastAudited',
        value: '',
        preInputText: '₹',
        fieldType: 'string'
    },
    {
        label: 'Last Provisional Revenue',
        code: 'lastProvisionalRevenue',
        value: '',
        preInputText: '₹',
        fieldType: 'string'
    },
    {
        label: 'Last Provisional PBT',
        code: 'pbtLastProvisional',
        value: '',
        preInputText: '₹',
        fieldType: 'string'
    },
    {
        label: 'Projected Revenue',
        code: 'projectedRevenue',
        value: '',
        preInputText: '₹',
        fieldType: 'string'
    },
    {
        label: 'Projected PBT',
        code: 'pbtProjected',
        value: '',
        preInputText: '₹',
        fieldType: 'string'
    },
    // {
    //     label: 'Amount Required',
    //     code: 'amountRequired',
    //     value: '',
    //     preInputText: '₹',
    //     fieldType: 'string'
    // },
    // {
    //     label: 'Number of vehicle Required',
    //     code: 'numberOfVehicleRequired',
    //     value: '',
    //     preInputText: '₹',
    //     fieldType: 'string'
    // }
]
