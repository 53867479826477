import React, { useRef } from 'react';
import { Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useSelector } from 'react-redux';
import useClass from '../../hooks/useClass';
import CAMData from './CAMData';
import { toggleCAM } from '../../store/customer/cam/action';
import EditorCAM from './EditorCAM';
import LenderUsers from './LenderUsers';
import EditorMail from './EditorMail';
import CAMFooter from './CAMFooter';

const CAMBoard = () => {

  const editorCAM = useRef(null);
  const { dispatch } = useClass();
  const { openCollapse, openCAM, lender } = useSelector((state) => (state?.cam));

  const toggle = () => {
    dispatch(toggleCAM({}));
  }

  return (
    <>
      <Modal
        isOpen={openCAM}
        size='xl'
        toggle={toggle}
        centered
        // fullscreen
      >
        <ModalHeader toggle={toggle}>
          CAM-{lender?.name}
        </ModalHeader>
        <ModalBody>
          <Collapse isOpen={openCollapse === 0}>
            <CAMData />
          </Collapse>
          <Collapse isOpen={openCollapse === 1}>
            <LenderUsers />
          </Collapse>
          <Collapse isOpen={openCollapse === 2}>
            <EditorCAM editorCAM={editorCAM} />
          </Collapse>
          <Collapse isOpen={openCollapse === 3}>
            <EditorMail />
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <CAMFooter editorCAM={editorCAM} />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default React.memo(CAMBoard);
