import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import TextInput from '../../../components/text-input/TextInput';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { FILL_ALL_MENDETORY_FIELDS } from '../../../utils/error';

// Dummy data for charger types
const chargerTypes = [
  { label: 'AC Charger', value: 'AC' },
  { label: 'DC Charger', value: 'DC' }
];

// Dummy data for power types
const powerTypes = [
  { label: 'Single Phase', value: 'SINGLE_PHASE' },
  { label: 'Three Phase', value: 'THREE_PHASE' }
];

const CreateChargerModel = (props) => {
  const { toaster, navigate, oemServer } = useClass();
  const { isOpen, setIsOpen } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [manufactureOptions, setManufactureOptions] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  const [form, setForm] = useState({
    stationId: '',
    assetOemCode: '',

 
  });

  useEffect(() => {
    const fetchManufactures = async () => {
      try {
        const response = await oemServer.getManufacture();
        const options = response.data.data.map(oem => ({
          label: oem.name,
          value: oem.code
        }));
        setManufactureOptions(options);
      } catch (error) {
        toaster.error(error);
      }
    };

    const fetchChargingStation = async () => {
      try {
        const response = await oemServer.getChargingStation();
        const options = response.data.data.map(oem => ({
          label: oem.stationName,
          value: oem.stationId
        }));
        setStationOptions(options);
      } catch (error) {
        toaster.error(error);
      }
    };

    fetchManufactures();
    fetchChargingStation()
    
  }, []);

  const handleModelClose = () => {
    setIsOpen(false);
  };

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleChargerCreation = async () => {
    try {
      setIsLoading(true);
      if (validateForm()) {
        const payload = {
          ...form,
          stationId: parseInt(form?.stationId.value), // Ensure stationId is a number
          ratedCapacity: parseInt(form?.ratedCapacity),
          noOfChargers: parseInt(form?.noOfChargers),
          serviceCharge: parseFloat(form?.serviceCharge),
          assetOemCode: form.assetOemCode?.value,
          powerType:form?.powerType?.value,
          chargerType:form?.chargerType?.value
        };
        const response = await oemServer.createCharger(payload);
        setIsLoading(false);
        handleModelClose();
        navigate(`/charger/details/${response?.data?.data?.code}`);
        toaster.show(false, 'Charger model created successfully');
      } else {
        toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
      }
    } catch (error) {
      setIsLoading(false);
      toaster.error(error);
    }
  };

  const validateForm = () => {
    return Object.keys(form).every((key) => form[key] !== "");
  };

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        toggle={handleModelClose} 
        centered
      >
        <ModalHeader toggle={handleModelClose}>
          Create Charger Model
        </ModalHeader>
        <ModalBody>
          <Dropdown
            label="Manufacturer"
            required={true}
            name="assetOemCode"
            value={form.assetOemCode}
            handleInput={handleInput}
            options={manufactureOptions}
            placeholder="Select Manufacturer"
          />
          <div className='mt-4'>
          <Dropdown
            label={'Station ID'}
            required={true}
            name="stationId"
            value={form.stationId}
            handleInput={handleInput}
            options={stationOptions}
            placeholder="Select Station"
          />
          </div>
          <div className='mt-4'>
            <Dropdown
              label={'Charger Type'}
              options={chargerTypes}
              handleInput={handleInput}
              required={true}
              value={form.chargerType}
              name={'chargerType'}
            />
          </div>
          <div className='mt-4'>
            <TextInput
              label={'Number of Chargers'}
              required={true}
              name={'noOfChargers'}
              value={form.noOfChargers}
              handleInput={handleInput}
              type={'number'}
            />
          </div>
          <div className='mt-4'>
            <TextInput
              label={'Rated Capacity'}
              required={true}
              name={'ratedCapacity'}
              value={form.ratedCapacity}
              handleInput={handleInput}
              type={'number'}
            />
          </div>
          <div className='mt-4'>
            <TextInput
              label={'Service Charge'}
              name={'serviceCharge'}
              value={form.serviceCharge}
              handleInput={handleInput}
              type={'number'}
            />
          </div>
          <div className='mt-4'>
            <Dropdown
              label={'Power Type'}
              options={powerTypes}
              handleInput={handleInput}
              required={true}
              value={form.powerType}
              name={'powerType'}
            />
          </div>
          <div className='mt-4'>
            <TextInput
              label={'Connector Working Status'}
              name={'connectorWorkingStatus'}
              value={form.connectorWorkingStatus}
              handleInput={handleInput}
              type={'text'}
            />
          </div>
          <div className='mt-4'>
            <TextInput
              label={'Tariff Rate'}
              name={'tariffRate'}
              value={form.tariffRate}
              handleInput={handleInput}
              type={'text'}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
            Close
          </Button>
          <LoadingButton
            buttonContent={'Create'}
            isLoading={isLoading}
            onClick={handleChargerCreation}
            color={'success'}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreateChargerModel;
