import React, { useMemo } from 'react';
import TextInput from '../text-input/TextInput';
import Dropdown from '../Dropdown/Dropdown';

const DynamicFields = ({data, handleInput, form}) => {

    const valueKey = (code) => {
        const keys = code?.split(' ');
        const value = keys?.reduce((obj, key) => obj?.[key], form);
        return value;
    }

    const dynamic = useMemo(() => {
        switch(data?.component?.type){
            case 'dropdown':
                return <>
                    <Dropdown
                        customClass={'w-100'}
                        name={data?.code}
                        label={data?.label}
                        placeholder={data?.label}
                        options={data?.options}
                        handleInput={handleInput}
                        value={valueKey(data?.code)}
                        isClearable={data?.isMulti}
                        required={true}
                        isMulti={data?.isMulti}
                    />
                </>
            case 'binary':
                return <>
                    <div className='col-lg-8 col-md-6 col-sm-12 mb-2 text-input'>
                      <div className='heading'>
                        {data?.label}&nbsp;
                        {data?.required && <span className='text-danger'>*</span>}
                      </div>
                      <div className="form-check form-check-inline mt-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={data.code}
                          id={data?.code}
                          defaultValue={true}
                          onChange={(e) => (handleInput(data.code, e.target.checked))}

                        />
                        <span
                          className="form-check-label"
                          htmlFor={data.coe}
                        >
                          Yes
                        </span>
                      </div>
                      <div className="form-check form-check-inline mt-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={data.code}
                          id={data.code}
                          defaultValue={false}
                          onChange={(e) => (handleInput(data.code, e.target.checked))}
                        />
                        <span
                          className="form-check-label"
                          htmlFor={data.code}
                        >
                          No
                        </span>
                      </div>
                    </div>
                </>
            default:
                return (
                    <TextInput
                        customClass={'w-100'}
                        value={valueKey(data?.code)}
                        name={data?.code}
                        label={data?.label}
                        handleInput={handleInput}
                        type={data?.type}
                        placeholder={data?.label}
                        required={data?.required === false ? false : true}
                    />
                )
        }
    }, [data, form])

    return (
        <div className='d-flex align-items-center'>
          {dynamic}
        </div>
    )
}

export default React.memo(DynamicFields);
