import { configureStore } from '@reduxjs/toolkit';
import { adminReducer } from './admin/adminData';
import createSagaMiddleware from 'redux-saga';
import sagas from './sagas';
import { reportReducer } from './reports/actions';
import { userReducer } from './user/userSlice';
import { oemReducer } from './oem/oemData';
import { dashboardReducer } from './dashboard/dashboard';
import { campaignTemplateReducer } from './campaign/template';
import { campaignSegmentReducer } from './campaign/segment';
import { campaignFlowReducer } from './campaign/campaignFlow';
import { applicationReducer } from './customer/application/applicationAction';
import { customerReducer } from './customer/customer';
import { utilsReducer } from './utils/actions';
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    ...userReducer,
    ...adminReducer,
    ...reportReducer,
    ...applicationReducer,
    ...customerReducer,
    ...oemReducer,
    ...userReducer,
    ...utilsReducer,
    dashboardData:dashboardReducer,
    campaignTemplate:campaignTemplateReducer,
    campaignSegment: campaignSegmentReducer,
    campaignFlow: campaignFlowReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
})

sagaMiddleware.run(sagas);
