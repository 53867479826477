import { axiosInstance } from "./base";
import { headers } from "./base";

export class UtilsService {

    CMS_BASE_URL= process.env.REACT_APP_CMS_BASE_URL;

    getLenderConfigFields = () => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/lender/config/fields`, {headers: headers()});
    }

}