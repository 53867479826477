import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
import useClass from "../../hooks/useClass";
import { getNotifications } from "../../store/user/userSlice";
import { formatNotificationTime } from "../../utils/helper";

const NotificationDropdown = (props) => {
  const { notifications: notificationData, totalList } = useSelector((state) => state?.notification);
  const { server, toaster, dispatch } = useClass();
  const [menu, setMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(0);
  const [isViewed, setIsViewed] = useState(false);

  useEffect(() => {
    dispatch(getNotifications({page}));
  }, [page]);

  useEffect(() => {
    if (notificationData) {
      setNotifications(notificationData);
    }
  }, [notificationData]);

  const clearAllNotifications = async () => {
    try {
      const response = await server.clearAllNotifications();
      setNotifications([]);
    } catch (error) {
      toaster.error(error);
    }
  };

  const handleViewMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  const markNotificationAsRead = async (id) => {
    try {
      const response = await server.markNotificationAsRead(id);
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === id ? { ...notification, isRead: true } : notification
        )
      );
    } catch (error) {
      toaster.error(error);
    }
  };

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => {
          setMenu(!menu);
          setIsViewed(true);
        }}
        className="dropdown d-inline-block"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="ri-notification-3-line" />
          {(totalList > 0 && !isViewed) && <span className="noti-dot"></span>}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <SimpleBar style={{ height: "230px" }}>
            {notifications.length === 0 ? (
              <div className="text-center p-3" style={{ height: "230px" }}>
                <p className="text-muted mb-0">No notifications</p>
              </div>
            ) : (
              notifications.map((notification, index) => (
                <React.Fragment key={notification.id}>
                    <div
                      className="text-reset notification-item d-block p-2"
                      style={{
                        backgroundColor: !notification.isRead ? '#d0e7ff' : 'transparent',
                        border: !notification.isRead ? '1px solid #a0c8ff' : 'none',
                      }}
                      onClick={() => markNotificationAsRead(notification.id)}
                    >
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-xs">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="ri-notification-3-line"></i>
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1">{notification.title}</h6>
                        <p className="text-muted mb-0">{notification.message}</p>
                        <small className="text-muted">
                          <i className="mdi mdi-clock-outline"></i>{" "}
                          {formatNotificationTime(notification.createdAt)}
                        </small>
                      </div>
                    </div>
                  </div>

                  {index === notifications.length - 1 && notifications.length > 0 && (
                    <div className="p-2 border-top text-center">
                      <Link
                        onClick={handleViewMore}
                        className="btn btn-sm btn-link font-size-14"
                        to="#"
                      >
                        <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                        View More
                      </Link>
                    </div>
                  )}
                </React.Fragment>
              ))
            )}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default NotificationDropdown;

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
