import { validateDateTime } from "rsuite/esm/DateInput";

export const modules = ['DASHBOARD', 'CUSTOMERS', 'LOANS', 'ADMIN'];

const booleanOptions = [
    {
        label: 'True',
        value: 'True'
    },
    {
        label: 'False',
        value: 'False'
    }
]

const yesNoOptions = [
    {
        label: 'Yes',
        value: 'Yes'
    },
    {
        label: 'No',
        value: 'No'
    }
]


const facilityTypeOptions = [
    {
        label: 'Loan',
        value: 'Loan'
    },
    {
        label: 'Lease',
        value: 'Lease'
    },
    {
        label: 'Both',
        value: 'Both'
    }
]

export const schedule = [
    { label: "ONCE", value: "ONCE" },
    { label: "DAILY", value: "DAILY" },
    { label: "WEEKLY", value: "WEEKLY" },
    { label: "WEEKEND", value: "WEEKEND" },
    { label: "MONTHLY", value: "MONTHLY" }
]


 export const channel = [
    { label: "SMS", value: "SMS" },
    { label: "EMAIL", value: "EMAIL" },
    { label: "WHATSAPP", value: "WHATSAPP" },
    { label: "IVR", value: "IVR" },
    { label: "TWEET", value: "TWEET" },
    { label: "WEB_NOTIF", value: "WEB_NOTIF" },
    { label: "MOBILE_NOTIF", value: "MOBILE_NOTIF" }
]

 export const timeArray = [
    { label: "00:00", value: "00:00" },
    { label: "00:30", value: "00:30" },
    { label: "01:00", value: "01:00" },
    { label: "01:30", value: "01:30" },
    { label: "02:00", value: "02:00" },
    { label: "02:30", value: "02:30" },
    { label: "03:00", value: "03:00" },
    { label: "03:30", value: "03:30" },
    { label: "04:00", value: "04:00" },
    { label: "04:30", value: "04:30" },
    { label: "05:00", value: "05:00" },
    { label: "05:30", value: "05:30" },
    { label: "06:00", value: "06:00" },
    { label: "06:30", value: "06:30" },
    { label: "07:00", value: "07:00" },
    { label: "07:30", value: "07:30" },
    { label: "08:00", value: "08:00" },
    { label: "08:30", value: "08:30" },
    { label: "09:00", value: "09:00" },
    { label: "09:30", value: "09:30" },
    { label: "10:00", value: "10:00" },
    { label: "10:30", value: "10:30" },
    { label: "11:00", value: "11:00" },
    { label: "11:30", value: "11:30" },
    { label: "12:00", value: "12:00" },
    { label: "12:30", value: "12:30" },
    { label: "13:00", value: "13:00" },
    { label: "13:30", value: "13:30" },
    { label: "14:00", value: "14:00" },
    { label: "14:30", value: "14:30" },
    { label: "15:00", value: "15:00" },
    { label: "15:30", value: "15:30" },
    { label: "16:00", value: "16:00" },
    { label: "16:30", value: "16:30" },
    { label: "17:00", value: "17:00" },
    { label: "17:30", value: "17:30" },
    { label: "18:00", value: "18:00" },
    { label: "18:30", value: "18:30" },
    { label: "19:00", value: "19:00" },
    { label: "19:30", value: "19:30" },
    { label: "20:00", value: "20:00" },
    { label: "20:30", value: "20:30" },
    { label: "21:00", value: "21:00" },
    { label: "21:30", value: "21:30" },
    { label: "22:00", value: "22:00" },
    { label: "22:30", value: "22:30" },
    { label: "23:00", value: "23:00" },
    { label: "23:30", value: "23:30" },
];

export const messageType = [
    {
        label:'promotional',
        value:'PROMOTIONAL'
    },
    {
        label:'transactional',
        value:'TRANSACTIONAL'
    }
]

export const whatsAppHeaderType = [
    {
        label:'TEXT',
        value:'TEXT'
    },
    {
        label:'DOCUMENT',
        value:'DOCUMENT'
    },
]

export const whatsAppButtonType = [
    {
        label:'VISIT_WEBSITE',
         value:'VISIT_WEBSITE',
    },
    {
        label:'CALL_PHONE_NUMBER',
         value:'CALL_PHONE_NUMBER',
    },
    {
        label:'COMPLETE_FLOW',
         value:'COMPLETE_FLOW',
    },
    {
        label:'COPY_OFFER_CODE',
         value:'COPY_OFFER_CODE',
    },
]

export const language = [
    {
        label:'en',
        value:'EN'
    }
]

export const indianStatesAndUTs = [
    { label: 'Andhra Pradesh', value: 'ANDHRA_PRADESH' },
    { label: 'Arunachal Pradesh', value: 'ARUNACHAL_PRADESH' },
    { label: 'Assam', value: 'ASSAM' },
    { label: 'Bihar', value: 'BIHAR' },
    { label: 'Chhattisgarh', value: 'CHHATTISGARH' },
    { label: 'Goa', value: 'GOA' },
    { label: 'Gujarat', value: 'GUJARAT' },
    { label: 'Haryana', value: 'HARYANA' },
    { label: 'Himachal Pradesh', value: 'HIMACHAL_PRADESH' },
    { label: 'Jharkhand', value: 'JHARKHAND' },
    { label: 'Karnataka', value: 'KARNATAKA' },
    { label: 'Kerala', value: 'KERALA' },
    { label: 'Madhya Pradesh', value: 'MADHYA_PRADESH' },
    { label: 'Maharashtra', value: 'MAHARASHTRA' },
    { label: 'Manipur', value: 'MANIPUR' },
    { label: 'Meghalaya', value: 'MEGHALAYA' },
    { label: 'Mizoram', value: 'MIZORAM' },
    { label: 'Nagaland', value: 'NAGALAND' },
    { label: 'Odisha', value: 'ODISHA' },
    { label: 'Punjab', value: 'PUNJAB' },
    { label: 'Rajasthan', value: 'RAJASTHAN' },
    { label: 'Sikkim', value: 'SIKKIM' },
    { label: 'Tamil Nadu', value: 'TAMIL_NADU' },
    { label: 'Telangana', value: 'TELANGANA' },
    { label: 'Tripura', value: 'TRIPURA' },
    { label: 'Uttar Pradesh', value: 'UTTAR_PRADESH' },
    { label: 'Uttarakhand', value: 'UTTARAKHAND' },
    { label: 'West Bengal', value: 'WEST_BENGAL' },
    { label: 'Andaman and Nicobar Islands', value: 'ANDAMAN_AND_NICOBAR_ISLANDS' },
    { label: 'Chandigarh', value: 'CHANDIGARH' },
    { label: 'Dadra and Nagar Haveli and Daman and Diu', value: 'DADRA_AND_NAGAR_HAVELI_AND_DAMAN_AND_DIU' },
    { label: 'Lakshadweep', value: 'LAKSHADWEEP' },
    { label: 'Delhi', value: 'DELHI' },
    { label: 'Puducherry', value: 'PUDUCHERRY' }
];


export const monthData = [
    {month:"January",number:1},
    {month:"February",number:2},                    
    {month:"March",number:3},
    {month:"April",number:4},
    {month:"May",number:5},
    {month:"June",number:6},
    {month:"July",number:7},
    {month:"August",number:8},
    {month:"September",number:9},
    {month:"October",number:10},
    {month:"November",number:11},
    {month:"December",number:12},
  ]

  // task kanban 
  export const taskStatus = [
    {label: 'Pending', value: 'PENDING'},
    {label: 'In-Progress', value: 'IN_PROGRESS'},
    {label: 'Complete', value: 'COMPLETE'}
  ]

  export const statusColors = {
    PENDING: 'primary',
    IN_PROGRESS: 'warning',
    COMPLETE: 'success',
    default: 'secondary',
};

const typeOfProduct = [
    {
        label: "Loan", value: "LOAN",
    },
    {
        label: "Lease", value: "LEASE"
    }
  ]

const loanType = [
    { label: "Vehicle Loan", value: "vehicleLoan" },
    { label: "Business Loan", value: "businessLoan" }
  ]

const leaseType = [
    { label: "Financial Lease", value: "financialLease" },
    { label: "Operating Lease", value: "operatingLease" }
  ]

export const typeOfEntity = [
    {
      label: 'Individual',
      value: 'individual',
      img: 'DCO'
    },
    {
      label: 'Public Limited',
      value: 'publicLimited',
      img: 'PublicLimited'
    },
    {
      label: 'Private Limited',
      value: 'privateLimited',
      img: 'PrivateLimited'
    },
    {
      label: 'Sole proprietor',
      value: 'soleProprietorship',
      img: 'SoleProp'
    },
    {
      label: 'LLP',
      value: 'LLP',
      img: 'LLP'
    },
]

export const lenderConfigFields = (obj) => {
    const {schemeList=[], productList=[], camList=[]} = obj;
    const data = [
    // input, dropdown, multiselect, binary
    {
        label: 'CAM',
        code: 'camCode',
        type: 'dropdown',
        tooltip: 'CAM for login',
        value: [],
        options: camList,
        isMulti: false,
        editable: false
    },
    {
        label: 'Product scheme',
        code: 'productSchemeCode',
        type: 'dropdown',
        tooltip: 'Product scheme mapped with lender',
        value: [],
        options: schemeList,
        isMulti: false,
        editable: false
    },
    {
        label: 'Type of Product',
        code: 'typeOfProduct',
        type: 'dropdown',
        tooltip: 'Type of Product',
        value: [],
        options: typeOfProduct,
        isMulti: true,
        editable: false
    },
    {
        label: 'Type of Loan',
        code: 'typeOfLoan',
        type: 'dropdown',
        tooltip: 'Type of Loan',
        value: [],
        options: loanType,
        isMulti: true,
        editable: false
    },
    {
        label: 'Type of Vehicle',
        code: 'typeOfVehicle',
        type: 'dropdown',
        tooltip: 'Type of Vehicle',
        value: [],
        options: productList,
        isMulti: true,
        editable: false
    },
    {
        label: 'Type of Lease',
        code: 'typeOfLease',
        type: 'dropdown',
        tooltip: 'Type of Lease',
        value: [],
        options: leaseType,
        isMulti: true,
        editable: false
    },
    {
        label: 'Entity Type',
        code: 'entityType',
        type: 'dropdown',
        tooltip: 'Entity Type',
        value: [],
        options: typeOfEntity,
        isMulti: true,
        editable: false
    },
    {
        label: 'Rate of interest (%)',
        code: 'rate_of_interest',
        type: 'number',
        tooltip: 'Rate of interest of lender',
        value: [],
        editable: false,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''   
                    },
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Processing fee (Rs.)',
        code: 'processing_fee',
        type: 'number',
        tooltip: 'Tapfin processing fee for lender',
        value: [],
        editable: false,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''   
                    },
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Loan amount (Rs.)',
        code: 'amountRequired',
        type: 'number',
        value: [],
        tooltip: 'Amount required',
        chart: true,
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Loan tenure (months)',
        code: 'loan_loan_tenure',
        type: 'number',
        value: [],
        tooltip: 'Tenure',
        chart: true,
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    },
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Applicant age',
        code: 'customer_applicant_age',
        type: 'number',
        value: [],
        tooltip: 'Applicant age',
        chart: true,
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Applicant maturity age',
        code: 'customer_applicant_maturity_age',
        type: 'number',
        value: [],
        tooltip: 'Applicant maturity age',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    },
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Applicant maturity age SE',
        code: 'customer_applicant_maturity_age_se',
        type: 'number',
        value: [],
        tooltip: 'Applicant maturity age',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    },
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Positive pincodes',
        code: 'toolkit_possitive_pincode',
        type: 'dropdown',
        value: [],
        tooltip: 'Lendable pincodes',
        editable: true,
        type: 'binary',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'dropdown',
                value: '',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Negative pincodes',
        code: 'toolkit_negative_pincode',
        type: 'dropdown',
        value: [],
        tooltip: 'Non-lendable pincodes',
        editable: true,
        type: 'binary',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'dropdown',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Pan verified',
        code: 'customer_pan_verified',
        type: 'dropdown',
        value: [],
        tooltip: 'Customer pan should be verified',
        editable: true,
        type: 'binary',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'dropdown',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Aadhar verified',
        code: 'customer_aadhaar_verified',
        type: 'dropdown',
        value: [],
        tooltip: 'Customer aadhar should be verified',
        editable: true,
        type: 'binary',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'dropdown',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Face match',
        code: 'customer_face_matched',
        type: 'dropdown',
        value: [],
        tooltip: 'Customer face should be matching',
        editable: true,
        type: 'binary',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'dropdown',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Name match',
        code: 'customer_name_matched',
        type: 'dropdown',
        value: [],
        tooltip: 'Customer name should be matching',
        editable: true,
        type: 'binary',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'dropdown',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Entity name match',
        code: 'entity_name_matched',
        type: 'dropdown',
        value: [],
        tooltip: 'Entity name should be matching',
        editable: true,
        type: 'binary',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'dropdown',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Entity pan verified',
        code: 'entity_pan_verified',
        type: 'dropdown',
        value: [],
        tooltip: 'Entity pan should be verified',
        editable: true,
        type: 'binary',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'dropdown',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Cibil score',
        code: 'cibil_bureau_score',
        type: 'number',
        value: '',
        tooltip: 'Cibil score of customer',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    },
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Cibil credit card inquiries in last 6 months',
        code: 'cibil_cc_enquiries_last_six_month',
        type: 'number',
        value: '',
        tooltip: 'Cibil credit card inquiries',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Cibil credit card DPD in last month',
        code: 'cibil_cc_dpd_last_month',
        type: 'number',
        value: '',
        tooltip: 'Cibil credit card inquiries in last month',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Credit card DPD first bucket last 3 months',
        code: 'cibil_cc_first_bucket_dpd_last_three_month',
        type: 'number',
        value: '',
        tooltip: 'DPD first bucket credit card',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Credit card DPD second bucket last 3 months',
        code: 'cibil_cc_second_bucket_dpd_last_three_month',
        type: 'number',
        value: '',
        tooltip: 'DPD second bucket credit card',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Credit card DPD second bucket last 12 months',
        code: 'cibil_cc_second_bucket_dpd_last_twelve_month',
        type: 'number',
        value: '',
        tooltip: 'DPD second bucket credit card',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Cibil credit card overdue',
        code: 'cibil_cc_overdue',
        type: 'number',
        value: '',
        tooltip: 'Cibil credit card overdue',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Cibil DPD last month',
        code: 'cibil_dpd_last_month',
        type: 'number',
        value: '',
        tooltip: 'Cibil credit card overdue',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil DPD first bucket last 3 months',
        code: 'cibil_first_bucket_dpd_last_three_month',
        type: 'number',
        value: '',
        tooltip: 'DPD first bucket last 3 months',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil DPD second bucket last 3 months',
        code: 'cibil_second_bucket_dpd_last_three_month',
        type: 'number',
        value: '',
        tooltip: 'DPD second bucket last 3 months',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil DPD third bucket last 6 months',
        code: 'cibil_third_bucket_dpd_last_six_month',
        type: 'number',
        value: '',
        tooltip: 'DPD third bucket last 6 months',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil DPD fourth bucket last 12 months',
        code: 'cibil_fourth_bucket_dpd_last_twelve_month',
        type: 'number',
        value: '',
        tooltip: 'DPD fourth bucket last 12 months',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil writeoffs last 36 months',
        code: 'cibil_writeoff_last_thirty_six_month',
        type: 'number',
        value: '',
        tooltip: 'Cibil writeoffs last 36 months',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil substandard last 2 years',
        code: 'cibil_substandard_last_two_year',
        type: 'number',
        value: '',
        tooltip: 'Cibil substandard last 2 years',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil SMA last 12 months',
        code: 'cibil_sma_last_twelve_month',
        type: 'number',
        value: '',
        tooltip: 'Cibil SMA last 12 months',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil willful defaulter',
        code: 'cibil_willfull_defaulter',
        type: 'number',
        value: '',
        tooltip: 'Cibil willful defaulter',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil credit card writeoffs last 36 months',
        code: 'cibil_cc_writeoff_last_thirty_six_month',
        type: 'number',
        value: '',
        tooltip: 'Cibil credit card writeoffs last 36 months',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Bank statement ABB',
        code: 'bankstatement_abb',
        type: 'number',
        value: '',
        tooltip: 'Bank statement ABB',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    },
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Bank statement ABB SE',
        code: 'bankstatement_abb_se',
        type: 'number',
        value: '',
        tooltip: 'Bank statement ABB SE',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    },
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Bank statement age',
        code: 'backstatement_age',
        type: 'number',
        value: '',
        tooltip: 'Bank statement age',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Bank statement period',
        code: 'bankstatement_period',
        type: 'number',
        value: '',
        tooltip: 'Bank statement period',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Bank statement salary credits',
        code: 'backstatement_number_salary_credits',
        type: 'number',
        value: '',
        tooltip: 'Bank statement salary credits number',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Bank statement average number of credits SE',
        code: 'backstatement_average_number_of_credits_se',
        type: 'number',
        value: '',
        tooltip: 'Bank statement average number of credits SE',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Bank statement check bounce last 6 months',
        code: 'backstatement_check_bounces_last_six_month',
        type: 'number',
        value: '',
        tooltip: 'Bank statement check bounce last 6 months',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Bank statement check bounce last 6 months SE',
        code: 'backstatement_check_bounces_last_six_month_se',
        type: 'number',
        value: '',
        tooltip: 'Bank statement check bounce last 6 months SE',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Bank statement EMI bounce last 3 months',
        code: 'backstatement_emi_bounce_last_three_months',
        type: 'number',
        value: '',
        tooltip: 'Bank statement EMI bounce last 3 months',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Trend in turnover',
        code: 'trend_in_turnover_fy',
        type: 'number',
        value: '',
        tooltip: 'Trend in turnover',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Minimum average bank balance divide EMI ent',
        code: 'min_avg_bank_bal_devide_emi_ent',
        type: 'number',
        value: '',
        tooltip: 'Minimum average bank balance divide EMI ent',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Minimum banking turnover ent',
        code: 'min_banking_turnover_ent',
        type: 'number',
        value: '',
        tooltip: 'Minimum average bank balance divide EMI ent',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Inward cheque return ent',
        code: 'inward_cheque_return_ent',
        type: 'number',
        value: '',
        tooltip: 'Inward cheque return ent',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Outward cheque return ent',
        code: 'outward_cheque_return_ent',
        type: 'number',
        value: '',
        tooltip: 'Outward cheque return ent',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'EMI bounce ent',
        code: 'emi_bounce_ent',
        type: 'number',
        value: '',
        tooltip: 'EMI bounce ent',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Cash deposit againt EMI bounce ent',
        code: 'cash_deposite_against_emi_bounce_ent',
        type: 'number',
        value: '',
        tooltip: 'Cash deposit againt EMI bounce ent',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'CCR score ent',
        code: 'ccr_score_ent',
        type: 'number',
        value: '',
        tooltip: 'CCR score ent',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'CMR score ent',
        code: 'cmr_score_ent',
        type: 'number',
        value: '',
        tooltip: 'CMR score ent',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Own contribution vehicle ent',
        code: 'own_contribution_vehicle_ent',
        type: 'number',
        value: '',
        tooltip: 'Own contribution vehicle ent',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Own contribution asset ent',
        code: 'own_contribution_Asset_ent',
        type: 'number',
        value: '',
        tooltip: 'Own contribution asset ent',
        editable: true,
        type: 'number',
        subFields: [
            {
                label: 'Go',
                code: 'go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
]
return data;
}


export const partnerConfigFields = [
    // input, dropdown, multiselect, binary
    {
        label: 'Vintage of customer',
        code: 'customerVintage',
        type: 'number',
        value: [],
        tooltip: 'Minimum Number of years customer is doing business',
    },
    {
        label: 'Revenue',
        code: 'minRevenue',
        type: 'number',
        value: [],
        tooltip: 'Minimum revenue of business required to be eligible for this lender'
    },
    {
        label: 'Minimum interest',
        code: 'minInterest',
        type: 'number',
        value: [],
        tooltip: 'Minimum interest charged by lender'
    },
    {
        label: 'Maximum interest',
        code: 'maxInterest',
        type: 'number',
        value: [],
        tooltip: 'Maximum interest charged by lender'
    },
    {
        label: 'Profit',
        code: 'minProfit',
        type: 'number',
        value: [],
        tooltip: 'Minimum profit of business required to be eligible for this lender'
    },
    {
        label: 'Minimum loan amount',
        code: 'minLoanAmount',
        type: 'numbr',
        value: [],
        tooltip: 'Minimum loan amount offered by lender'
    },
    {
        label: 'Maximum loan amount',
        code: 'maxLoanAmount',
        type: 'number',
        value: [],
        tooltip: 'Maximum loan amount offered by lender'
    },
];

export const productSchemeConfigFields = [
    // input, dropdown, multiselect, binary
    {
        label: 'Processing fee',
        code: 'pf',
        type: 'number',
        value: [],
        tooltip: 'Processing fee for scheme'
    },
    {
        label: 'CGST',
        code: 'cgst',
        type: 'number',
        value: [],
        tooltip: 'CGST for scheme'
    },
    {
        label: 'SGST',
        code: 'sgst',
        type: 'number',
        value: [],
        tooltip: 'SGST for scheme'
    },
    {
        label: 'Interest rate',
        code: 'interestRate',
        type: 'number',
        value: [],
        tooltip: 'Interest rate for scheme'
    },
    {
        label: 'Duration',
        code: 'duration',
        type: 'number',
        value: [],
        tooltip: 'Duration in scheme'
    }
];

export const reKycComments = {
    'cin': ['Company Inactive with this CIN', 'Company Strike off with this CIN'],
    'udyam': ['Line of Business does not match with other registered documents', 'Prop/Promotors/Partnes name does not match with actuals', 'Constitution on Udyam not matches with Other documents like GST/MOA etc'],
    'gst': ['GST details not match with GST returns'],
    'businessIncorporationYear': ['Incorporation date not matching with CIN/GST'],
    'pbtProjected': ['PBT Cant be more than Projected Turnover'],
    'businessCategory': ['Wrong business category'],
    'entityType': ['Entity type not matching with document Proof', 'Constitution changed in current year,supporting docs not provided'],
    'typeOfBusiness': ['Type of Business not as per supporting douments provided'],
    'existingVehicles': ['Incorrect existing vehicle numbers'],
    'projectedRevenue': ['Field should not be blank,Figures in rupees', 'Projected Revenue not supported by agreements', 'Projected Revenue should be achievable and as per last trend'],
    'pbtLastProvisional': ['Incorrect last provisional PBT'],
    'lastProvisionalRevenue': ['Incorrect provisional revenue'],
    'pbtLastAudited': ['Incorrect audited PBT'],
    'revenueLastAudited': ['Incorrect audited revenue'],
    'aadhaar': ['Incorrect aadhaar', 'Invalid aadhaar'],
    'pan': ['Incorrect PAN', 'Invalid PAN'],
    'numberOfVehicleRequired': ['Increase number of vehicles required', 'Decrease number of vehicles required'],
    'amountRequired': ['Increase amount required', 'Decrease amount required'],
    'city': ['City cannot be in negative list'],
    'manufacturer': ['Change manufacturer'],
    'model': ['Change model'],
    'variant': ['Change varient'],
    'financialStatementLastFY': ['Incorrect financial statement'],
    'financialStatementLastFY-1': ['Incorrect financial statement'],
    'financialStatementLastFY-2': ['Incorrect financial statement'],
    'bankStatement': ['Primay Bank Statement/majority transactions Bank statement not uploaded', 'Format not acceptable', 'Clear Bank statement not uploaded', 'Complete Bank statement not uploaded', 'Missing period in between', 'Bank statement pertaining to someone else uploaded'],
    'loanStatementForExistingLoan': ['Incorrect existing loan statement'],
    'incomeTaxReturn': ['Incorrect doc'],
    'gstReturn': ['Incorrect doc'],
    'sanctionLetterOfLoanLease': ['Incorrect doc'],
    'unitEconomics': ['Incorrect doc'],
    'shareHolding': ['Incorrect doc'],
    'companyProfile': ['Incorrect doc'],
    'memorandumOfAssociation': ['Incorrect doc'],
    'partnershipDeed': ['Incorrect doc'],
    'balanceSheet': ['Incorrect doc'],
    'profitLossStatement': ['Incorrect doc'],
    'projections': ['Incorrect doc']
}


export const agreementDynamicFields = [
    {
        name: 'Name',
        code: 'name',
        type: 'text'
    },
    {
        name: 'Date',
        code: 'date',
        type: 'date'
    },
    {
        name: 'Signature',
        code: 'signature',
        type: 'text'
    },
    {
        name: 'CIN',
        code: 'cin',
        type: 'text'
    },
    {
        name: 'address',
        code: 'address',
        type: 'text'
    },
    {
        name: 'Entity Type',
        code: 'entityType',
        type: 'text'
    },
    {
        name: 'Type of Business',
        code: 'businessType',
        type: 'text'
    },
    {
        name: 'Day',
        code: 'day',
        type: 'number',
        maxLength: '2'
    },
    {
        name: 'Month',
        code: 'Month',
        type: 'number',
        maxLength: '2'
    },
    {
        name: 'Year',
        code: 'year',
        type: 'number',
        maxLength: '4'
    },
]