import React, { useState } from 'react';
import { Badge, Button } from 'reactstrap';
import { reKycComments } from '../../../constants/constants';
import { reKycFieldData } from '../../../utils/constants';
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import { useSelector } from 'react-redux';
import TextInput from '../../../components/text-input/TextInput';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { removeAdditionalDocField, removeReKYCField, removeTaskField } from '../../../store/customer/application/applicationAction';

const Rekyc = (props) => {

    const {
        handleModelClose, 
        isLoading,
        customerCode,
        handleAction
    } = props;

    const { dispatch } = useClass();
    const { action, loanUid, productCode, applicationId } = useSelector((state) => (state?.loanAction?.data));
    const { taskFields, reKycFields, additionalDocs } = useSelector((state) => (state?.actionFields));
    const [rekyc, setRekyc] = useState(reKycFields);
    const [task, setTask] = useState(taskFields);
    const [additionalDoc, setAdditionalDoc] = useState(additionalDocs);

    const handleREKYCInput = (key, value, index) => {
        const arr = getNewArr(rekyc, index, key, value);
        setRekyc(arr);
    }

    const handleTaskInput = (key, value, index) => {
        const arr = getNewArr(task, index, key, value);
        setTask(arr);
    }

    const handleAdditionalDocInput = (key, value, index) => {
        const arr = getNewArr(additionalDoc, index, key, value);
        setAdditionalDoc(arr);
    }

    const getNewArr = (module, index, key, value) => {
        let arr = [...module];
        let obj = arr[index];
        obj = {...obj, [key]: value}
        arr[index] = obj;
        return arr;
    }

    const handleSubmit = () => {
        let payload = {
            action, loanUid,
            comment: 'QC on hold'
        }
        if(rekyc.length > 0 || additionalDoc?.length > 0){
            let arr = rekyc?.map((obj) => {
                if(obj?.field === 'aadhaar' || obj?.field === 'pan' || obj?.field === 'mobile') {
                    return {fieldCode: obj?.field, reason: obj?.reason?.label, ...obj?.data}
                }
                return {fieldCode: obj?.field, reason: obj?.reason?.label}
            });
            let rekycArr = [];
            let newDocArr = [];

            additionalDoc?.forEach((obj) => {
                if(obj?.newDoc){
                    newDocArr = [...newDocArr, {fieldCode: obj?.field, fieldName: obj?.value?.label, reason: obj?.reason?.label}];
                } else {
                    rekycArr = [...rekycArr, {fieldCode: obj?.field, reason: obj?.reason?.label}];
                }
            });

            if(additionalDoc?.length > 0){
                arr = [...arr, ...rekycArr];
            }

            payload.rekyc = {
                customerCode, productCode, applicationId,
                fields: arr,
                additionalFields: newDocArr
            }
        }
        
        if(task.length > 0){
            const arr = task?.map((obj) => (
                 {
                    name: obj?.reason?.label,
                    description: obj?.comment,
                    type: obj?.type?.value,
                    field: obj?.field
                }
            ));
            payload.task = {
                applicationId: applicationId,
                customerCode,
                data: arr
            }
        }

        handleAction(payload);
    }

    const handleTaskDelete = (field) => {
        return () => {
            dispatch(removeTaskField(field));
        }
    };

    const handleRekycDelete = (field) => {
        return () => {
            dispatch(removeReKYCField(field));
        }
    };

    const handleAdditionalDocDelete = (field) => {
        return () => {
            dispatch(removeAdditionalDocField(field));
        }
    }
 
    return (
        <>
            <div className="modal-body">
                <div className='d-flex justify-content-between gap-2'>
                    <div className='col-1'>Field</div>
                    <div className='col-3'>Reason</div>
                    <div className='col-3'>Comment</div>
                    <div className='col-3'>Type</div>
                </div>
                {reKycFields?.map((obj, index) => (
                    <div className='d-flex justify-content-between gap-2 align-items-center' key={index}>
                        <div className='col-1'>
                            <Badge color='danger text-wrap'>
                               {reKycFieldData?.[obj?.field]?.label}
                            </Badge>
                        </div>
                        <div className='col-3'>
                            <Dropdown
                                value={rekyc[index].reason}
                                options={reKycComments?.[obj?.field]?.map((item) => ({label: item, value: item}))}
                                handleInput={(key, value) => handleREKYCInput(key, value, index)}
                                name="reason"
                            />
                        </div>
                        <div className='col-3'>
                            <div className='d-flex gap-2 align-items-center'>
                            <div>
                                <div className='text-input'>
                                  <div className="input-group">
                                    <textarea
                                      className='form-control'
                                      placeholder='Any comment...'
                                      value={rekyc[index]?.comment}
                                      onChange={(e) => handleREKYCInput('comment', e.target.value, index)}
                                      cols={12}
                                      rows={3}
                                    />
                                  </div>
                                </div>
                            </div>
                            <Button close onClick={handleRekycDelete(obj?.field)}/>
                            </div>
                        </div>
                        <div className='col-3'>
                        </div>
                    </div>
                ))}
                {additionalDocs?.map((obj, index) => (
                    <div className='d-flex justify-content-between gap-2 align-items-center' key={index}>
                        <div className='col-1'>
                            <Badge color='danger text-wrap'>
                                {obj?.value?.label} 
                            </Badge>
                        </div>
                        <div className='col-3'>
                            <Dropdown
                                value={additionalDoc[index].reason}
                                options={reKycComments?.[obj?.field]?.map((item) => ({label: item, value: item}))}
                                handleInput={(key, value) => handleAdditionalDocInput(key, value, index)}
                                name="reason"
                            />
                        </div>
                        <div className='col-3'>
                            <div className='d-flex gap-2 align-items-center'>
                            <div>
                                <div className='text-input'>
                                  <div className="input-group">
                                    <textarea
                                      className='form-control'
                                      placeholder='Any comment...'
                                      value={additionalDoc[index]?.comment}
                                      onChange={(e) => handleAdditionalDocInput('comment', e.target.value, index)}
                                      cols={12}
                                      rows={3}
                                    />
                                  </div>
                                </div>
                            </div>
                                <Button close onClick={handleAdditionalDocDelete(obj?.field)}/>
                            </div>
                        </div>
                        <div className='col-3'>
                        </div>
                    </div>
                ))}
                {taskFields?.map((obj, index) => (
                    <>
                        <div className='d-flex justify-content-between gap-2 align-items-center' key={`task${index}`}>
                            <div className='col-1'>
                                <Badge color='warning text-wrap'>
                                    {reKycFieldData?.[obj?.field]?.label}
                                </Badge>
                            </div>
                            <div className='col-3'>
                                <Dropdown
                                    value={task[index]?.reason}
                                    options={reKycComments?.[obj?.field]?.map((item) => ({label: item, value: item}))}
                                    handleInput={(key, value) => handleTaskInput(key, value, index)}
                                    name="reason"
                                />
                            </div>
                            <div className='col-3'>
                                <div className='d-flex gap-2 align-items-center'>
                                <div>
                                <div className='text-input'>
                                  <div className="input-group">
                                    <textarea
                                      className='form-control'
                                      placeholder='Any comment...'
                                      value={task[index]?.comment}
                                      onChange={(e) => handleTaskInput('comment', e.target.value, index)}
                                      cols={12}
                                      rows={3}
                                    />
                                  </div>
                                </div>
                                </div>
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='d-flex gap-2 align-items-center'>
                                    <Dropdown
                                        customClass={'w-100'}
                                        value={task[index]?.type}
                                        handleInput={(key, value) => handleTaskInput(key, value, index)}
                                        options={[{label: 'Customer Visit', value: 'CUSTOMER_VISIT'},{label: 'Verification', value: 'VERIFICATION'}]}
                                        name="type"
                                    />
                                    <Button style={{marginTop: '-10px'}} close onClick={handleTaskDelete(obj?.field)}/>
                                </div>
                            </div>
                        </div>
                    </>
                ))}
            </div>
            <div className="modal-footer">
                <button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
                    Close
                </button>
                {<LoadingButton
                    buttonContent={'Submit'}
                    isLoading={isLoading}
                    onClick={handleSubmit}
                    color={'success'}
                />}
            </div>
        </>
    )
}

export default Rekyc;
