import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useClass from "../../../hooks/useClass";
import { useNavigate } from "react-router-dom";
import { FILL_ALL_MENDETORY_FIELDS } from "../../../utils/error";
import LoadingButton from "../../../components/Common/LoadingButton";

const CreatePartner = (props) => {
  const { adminServer, toaster } = useClass();
  const { isOpen, setIsOpen } = props;
  const [partnerName, setPartnerName] = useState("");
  const [partnerCode, setPartnerCode] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleModelClose = () => {
    setIsOpen(false);
  };

  const handlePartnerCreation = async () => {
    if (validateForm()) {
      await createPartner({ partnerName, partnerCode });
    } else {
      toaster.show(true, FILL_ALL_MENDETORY_FIELDS);
    }
  };

  const createPartner = async (payload) => {
    try {
      setLoading(true);
      const response = await adminServer.createPartner(payload);
      const partnerCode = response?.data?.data?.code;
      setLoading(false);
      setIsOpen(false);
      navigate(`/partners/details/${partnerCode}`);
    } catch (error) {
      toaster.show(true, error?.message);
    }
  };

  const validateForm = () => {
    return partnerName.trim() !== "";
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={handleModelClose}
        centered
      >
        <ModalHeader toggle={handleModelClose}>
          Create Partner
        </ModalHeader>
        <ModalBody>
          <div>
            <label className="form-label">Partner Name</label>
            <input
              className="form-control"
              type="text"
              value={partnerName}
              onChange={(e) => {
                setPartnerName(e.target.value);
              }}
              placeholder="Partner Name"
            />
          </div>
          <div className="mt-4">
            <label className="form-label">Partner Code</label>
            <input
              className="form-control"
              type="text"
              value={partnerCode}
              onChange={(e) => {
                setPartnerCode(e.target.value);
              }}
              placeholder="Partner Code"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <LoadingButton
            onClick={handlePartnerCreation}
            buttonContent={'Create'}
            isLoading={loading}
            color={'success'}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreatePartner;
