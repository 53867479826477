import { createSlice } from "@reduxjs/toolkit";

const manufacture = createSlice({
    name: 'manufacture',
    initialState: {
        list: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getManufactureList: (state, action) => {
            state.loading = true;
        },
        getManufactureListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
        },
        getManufactureListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getManufactureDetails: (state, action) => {
            state.loading = true;
        },
        getManufactureDetailsSuccess: (state, action) => {
            state.loading = false;
            state.details = action?.payload;
        },
        getManufactureDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateManufacture: (state, action) => {
            state.loading = true;
        },
        updateManufactureSuccess: (state, action) => {
            state.loading = false;
        },
        updateManufactureError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
})


const vehicle = createSlice({
    name: 'manufacture',
    initialState: {
        list: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getVehicleList: (state, action) => {
            state.loading = true;
        },
        getVehicleListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
        },
        getVehicleListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getVehicleDetails: (state, action) => {
            state.loading = true;
        },
        getVehicleDetailsSuccess: (state, action) => {
            state.loading = false;
            state.details = action?.payload;
        },
        getVehicleDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateVehicle: (state, action) => {
            state.loading = true;
        },
        updateVehicleSuccess: (state, action) => {
            state.loading = false;
        },
        updateVehicleError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
})

const charger = createSlice({
    name: 'charger',
    initialState: {
        list: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getChargerList: (state, action) => {
            state.loading = true;
        },
        getChargerListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
        },
        getChargerListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getChargerDetails: (state, action) => {
            state.loading = true;
        },
        getChargerDetailsSuccess: (state, action) => {
            state.loading = false;
            state.details = action?.payload;
        },
        getChargerDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateCharger: (state, action) => {
            state.loading = true;
        },
        updateChargerSuccess: (state, action) => {
            state.loading = false;
        },
        updateChargerError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
})


const battery = createSlice({
    name: 'battery',
    initialState: {
        list: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getBatteryList: (state, action) => {
            state.loading = true;
        },
        getBatteryListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
        },
        getBatteryListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getBatteryDetails: (state, action) => {
            state.loading = true;
        },
        getBatteryDetailsSuccess: (state, action) => {
            state.loading = false;
            state.details = action?.payload;
        },
        getBatteryDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateBattery: (state, action) => {
            state.loading = true;
        },
        updateBatterySuccess: (state, action) => {
            state.loading = false;
        },
        updateBatteryError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
})

const chargingStation = createSlice({
    name: 'chargingStation',
    initialState: {
        list: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getChargingStationList: (state, action) => {
            state.loading = true;
        },
        getChargingStationListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
        },
        getChargingStationListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getChargingStationDetails: (state, action) => {
            state.loading = true;
        },
        getChargingStationDetailsSuccess: (state, action) => {
            state.loading = false;
            state.details = action?.payload;
        },
        getChargingStationDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateChargingStation: (state, action) => {
            state.loading = true;
        },
        updateChargingStationSuccess: (state, action) => {
            state.loading = false;
        },
        updateChargingStationError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
})
// actions
export const {
    getManufactureDetails,
    getManufactureDetailsError,
    getManufactureDetailsSuccess,
    getManufactureList,
    getManufactureListError,
    getManufactureListSuccess,
    updateManufacture,
    updateManufactureError,
    updateManufactureSuccess
    } = manufacture?.actions;
// Actions
export const {
    getChargingStationList,
    getChargingStationListError,
    getChargingStationListSuccess,
    getChargingStationDetails,
    getChargingStationDetailsError,
    getChargingStationDetailsSuccess,
    updateChargingStation,
    updateChargingStationError,
    updateChargingStationSuccess
} = chargingStation?.actions;
// actions
export const {
    getVehicleDetails,
    getVehicleDetailsError,
    getVehicleDetailsSuccess,
    getVehicleList,
    getVehicleListError,
    getVehicleListSuccess,
    updateVehicle,
    updateVehicleError,
    updateVehicleSuccess
} = vehicle?.actions;
// Actions
export const {
    getChargerList,
    getChargerListError,
    getChargerListSuccess,
    getChargerDetails,
    getChargerDetailsError,
    getChargerDetailsSuccess,
    updateCharger,
    updateChargerError,
    updateChargerSuccess
} = charger?.actions;
// Actions
export const {
    getBatteryList,
    getBatteryListError,
    getBatteryListSuccess,
    getBatteryDetails,
    getBatteryDetailsError,
    getBatteryDetailsSuccess,
    updateBattery,
    updateBatteryError,
    updateBatterySuccess
} = battery?.actions;

export const oemReducer = {
    manufacture: manufacture.reducer,
    vehicle: vehicle.reducer,
    battery: battery.reducer,
    charger: charger.reducer,
    chargingStation: chargingStation.reducer
}
