import React, { useCallback, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import TapFinScore from '../credit-analysis/TapFinScore';
import { useSelector } from 'react-redux';

const Vehicle = () => {

  const { activeApplicationIndex, applicationList } = useSelector((state) => (state?.applicationAction));
  const [isOpen, setIsOpen] = useState(false);

  const getValues = useCallback((key) => {
    const data = applicationList?.[activeApplicationIndex]?.additionalData;
    const manufacterurAndAssistance = data?.filter((obj) => (obj?.fieldKey === 'manufacturerAssistance'))?.[0]?.value;
    const obj = manufacterurAndAssistance?.[0]?.[key]?.[0]
    if(!obj || !key){
      return '-';
    }
    return obj?.label;
  }, [applicationList, activeApplicationIndex]);

  return (
    <>
      <Row>
        <Col>
          <div 
            // style={{borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}
            className='p-4'
          >
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center col-lg-6 col-md-6 col-sm-12'>
                <a style={{fontSize: '40px'}} className='ri-truck-fill' />
                <Button color='link'>
                  {getValues('manufacterur')}
                </Button> /
                <Button color='link'>
                  {getValues('model')}
                </Button> /
                <Button color='link'>
                  {getValues('veriant')}  
                </Button>
                </div>
              <div className='d-flex justify-content-center col-lg-6 col-md-6 col-sm-12'>
                <Button color='link' className='p-0' onClick={() => setIsOpen(!isOpen)}>
                  <span style={{fontWeight: 'bold', fontSize: '24px'}}>790 <i className='ri-line-chart-fill' /></span>
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <TapFinScore isOpen={isOpen} setIsOpen={setIsOpen} name={`${getValues('manufacterur')} / ${getValues('model')} / ${getValues('veriant')}`} />
    </>
  )
}

export default React.memo(Vehicle);
