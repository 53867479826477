import React, { useEffect, useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { formatDate2 } from '../../../../utils/helper';
import useClass from '../../../../hooks/useClass';
import { getPreviousPD } from '../../../../store/customer/application/Assesment';
import { useSelector } from 'react-redux';

const PreviousPD = () => {

  const { dispatch } = useClass();
  const { activeApplicationIndex, applicationList } = useSelector((state) => (state?.applicationAction));
  const { prevPD } = useSelector((state) => (state?.pd));
  const [openPastData, setOpenPastData] = useState('');

  useEffect(() => {
    dispatch(getPreviousPD(applicationList?.[activeApplicationIndex]?.applicationId));
  }, [])

  return (
    <>
      <Accordion open={openPastData} toggle={() => setOpenPastData(openPastData === '1' ? '' : '1')}>
        <AccordionItem>
          <AccordionHeader targetId={'1'}>
            Prev Discussions
          </AccordionHeader>
          <AccordionBody accordionId={'1'} style={{maxHeight: '500px', overflowY: 'scroll'}}>
            <div className="wizard-order">
              <div className="v-line"></div>
              {prevPD?.map((data, index) => (
                <React.Fragment key={`cam-log-data${index}`}>
                  <div className="step completed">
                    <div className='ml-2 data-key' style={{minWidth: '100%'}}>Q: {data?.question}</div>
                    <div className='ml-2 data-value' style={{minWidth: '100%', textAlign: 'start'}}>A: {data?.answer}</div>
                    <div className='ml-2' style={{fontSize: '10px'}}>Created On: {formatDate2(data?.createdAt)}, By: {data?.createdByUserName}</div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </AccordionBody>
        </AccordionItem>  
      </Accordion>
    </>
  )
}

export default React.memo(PreviousPD);
