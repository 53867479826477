import React, { useState } from 'react';
import useClass from '../../../../hooks/useClass';
import { createCampaignWhatsAppTemplate } from '../../../../store/campaign/template';
import { Button, Col, Form, FormGroup, Input } from 'reactstrap';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import TextInput from '../../../../components/text-input/TextInput';
import { whatsAppButtonType, whatsAppHeaderType } from '../../../../constants/constants.js';

const WhatsApp = () => {

  const { dispatch } = useClass();
  const [form, setForm] = useState({
    body:'',
    templateName:'',
    status:'Active',
    language:'en',
    description:'',
    footer:'',
    headerType:'',
    headerContent:'',
    buttonContent:'',
    buttonType:'',
    buttonLabel:'',
    buttonValue:'',
    files:[]
  });
  const [showButtonFields, setShowButtonFields] = useState(false);

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateSmsForm()) {
      const formData = new FormData();
      for (const key in form) {
        if (Array.isArray(form[key])) {
          form[key].forEach(file => {
            formData.append(key, file);
          });
        } else {
          formData.append(key, form[key]);
        }
      }
      dispatch(createCampaignWhatsAppTemplate(formData));
    }
  }

  const validateSmsForm = () => {
    const result = Object.keys(form).every((key) => {
      if(typeof(form[key]) == 'object') return true
      return form.hasOwnProperty(key) && form[key].trim() !== '';
    });
    return result;
  }
  
  return (
    <>
      <Form className='p-3' onSubmit={(e) =>handleSubmit(e,'whatsapp')}>
        <FormGroup>
          <FormGroup>
            <Dropdown
              label={'Select Header Type'}
              value={form?.headerType}
              name={'headerType'}
              handleInput={handleInput}
              options ={whatsAppHeaderType?.map((type) => ({label: type?.label, value: type?.value}))}
            />
          </FormGroup>
          {form?.headerType?.label === "TEXT" && <FormGroup>
            <TextInput 
              label={'Header Content'}
              value={form?.headerContent}
              type={'text'}
              name={'headerContent'}
              handleInput={handleInput}
            />
          </FormGroup>}
          {(form?.headerType?.label == "DOCUMENT") && <FormGroup>
            <div for="headerContent">Header Content</div>
            <Input
              type="file"
              name="headerContent" 
              multiple
              onChange={handleInput} 
            />
          </FormGroup>}
            <TextInput 
              label={'Body'}
              value={form?.body}
              required={true}
              type={'text'}
              name={'body'}
              handleInput={handleInput}
            />
          </FormGroup>
        <FormGroup>
          <TextInput 
            label={'Template Name'}
            value={form?.templateName}
            required={true}
            type={'text'}
            name={'templateName'}
            handleInput={handleInput}
          />
        </FormGroup>
        <FormGroup>
          <TextInput 
            label={'Language'}
            value={form?.language}
            required={true}
            type={'text'}
            name={'language'}
            handleInput={handleInput}
          />
        </FormGroup>
        <FormGroup>
          <TextInput 
            label={'Description'}
            value={form?.description}
            required={true}
            type={'text'}
            name={'description'}
            handleInput={handleInput}
          />
        </FormGroup>
        <FormGroup>
          <TextInput 
            label={'Footer'}
            value={form?.footer}
            type={'text'}
            name={'footer'}
            handleInput={handleInput}
          />
        </FormGroup>
        <Col>
          <Button 
            className='w-100'
            color="primary"
            outline
            type="button" 
            onClick={() => setShowButtonFields(!showButtonFields)}
          >
          {showButtonFields ? 'Remove Button' : 'Add Button Fields'}
          </Button>
        </Col>
        {showButtonFields && (
          <>
            <FormGroup>
              <TextInput 
                label={'Button Content'}
                value={form.buttonContent}
                type={'text'}
                name={'buttonContent'}
                handleInput={handleInput}
              />
            </FormGroup>
            <FormGroup>
              <Dropdown
                label={'Select Button Type'}
                value={form?.buttonType}
                required={true}
                name={'buttonType'}
                handleInput={handleInput}
                options ={whatsAppButtonType?.map((type) => ({label: type?.label, value: type?.value}))}
              />
            </FormGroup>
            <FormGroup>
              <TextInput 
                label={'Button Label'}
                value={form.buttonLabel}
                type={'text'}
                name={'buttonLabel'}
                handleInput={handleInput}
              />
            </FormGroup>
            <FormGroup>
              <TextInput 
                label={'Button Value'}
                value={form.buttonValue}
                type={'text'}
                name={'buttonValue'}
                handleInput={handleInput}
              />
            </FormGroup>
          </>
        )}
        <Button className='w-100 mt-2' color="primary" type='submit'>Create Whatsapp</Button>
      </Form>
    </>
  )
}

export default React.memo(WhatsApp);
