import React from 'react';
import useClass from '../../hooks/useClass';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import LoadingButton from '../Common/LoadingButton';
import { 
  toggleCollapse, 
  updateApplicationCAM, 
  updateEditableFields, 
  updateUserAndDoc 
} from '../../store/customer/cam/action';
import { useParams } from 'react-router-dom';

const CAMFooter = ({editorCAM}) => {

  const { customerCode } = useParams();
  const { dispatch, toaster } = useClass();
  const { openCollapse, editableFields, loading, cam, lenderUsers, documents, mail, lender } = useSelector((state) => (state?.cam));
  const { activeApplicationIndex, applicationList, activeLoanIndex } = useSelector((state) => (state?.applicationAction));

  const handlePreview = () => {
    if (editorCAM.current ) {
      const cam = editorCAM.current.value;
      const previewWindow = window.open("", "Preview", "width=800,height=600");
      previewWindow.document.open();
      previewWindow.document.write(`
        <!DOCTYPE html>
        <html>
        <head>
          <title>Preview</title>
          <style>
            body { font-family: Arial, sans-serif; line-height: 1.6; padding: 20px; }
          </style>
        </head>
        <body>
          ${cam}
        </body>
        </html>
      `);
      previewWindow.document.close();
    }
  };

  const validate = () => {
    const plainText = cam?.replace(/<\/?[^>]+(>|$)/g, '')?.replace(/&nbsp;/g, ' ')?.trim();
    if(!plainText) {
      toaster.show(true, 'Please add CAM to save.');
      return;
    }
    const camPayload = btoa(encodeURIComponent(cam));
    const payload = {
      cam: camPayload, 
      lenderCode: lender.code, 
      applicationId: applicationList[activeApplicationIndex].applicationId,
    }
    dispatch(updateApplicationCAM(payload))
  }

  const handleNext = () => {
    if(openCollapse === 0){
      const invalid = editableFields?.some((obj) => (!obj.value || obj?.value?.trim() === ''));
      if(invalid){
        toaster.show(true, 'Please fill all values');
        return;
      }
      let obj = {};
      editableFields?.forEach((field) => {
        obj = {
          ...obj,
          [field.code]: field?.value
        }
      })
      const payload = {
        lenderCode: lender.code,
        templateCode: lender.config.camCode.code,
        applicationId: applicationList[activeApplicationIndex].applicationId,
        payload: obj
      }
      dispatch(updateEditableFields(payload));
    }
    if(openCollapse === 1){
      const selectedUser = lenderUsers?.filter((user) => (user?.selected));
      if(selectedUser.length === 0){
        toaster.show(true, 'Select atleast 1 user to send');
        return;
      }
      const newCollapse = documents?.filter((doc) => (doc?.selected && doc?.fieldCode === 'cam'))?.length === 1 ? openCollapse + 1 : openCollapse + 2;
      dispatch(toggleCollapse(newCollapse));
    }
    if(openCollapse === 2){
      validate();
    }
  }

  const handleSubmit = () => {
    const plainText = mail?.replace(/<\/?[^>]+(>|$)/g, '')?.replace(/&nbsp;/g, ' ')?.trim();
    if(!plainText) {
      toaster.show(true, 'Please add mail body.');
      return;
    }
    const selectedDocs = documents?.filter((doc) => (doc?.selected));
    const selectedUser = lenderUsers?.filter((user) => (user?.selected));
    if(selectedUser.length === 0){
      toaster.show(true, 'Select atleast 1 user to send');
      return;
    }
    const payload = {
      documents: selectedDocs, 
      users: selectedUser,
      lenderCode: lender.code,
      applicationId: applicationList[activeApplicationIndex].applicationId,
      customerCode,
      loanUid: applicationList[activeApplicationIndex].loan[activeLoanIndex].uid,
      mail
    }
    dispatch(updateUserAndDoc(payload));
  }

  const handlePrevious = () => {
    let newCollapse = openCollapse - 1;
    if(openCollapse === 3){
      newCollapse = documents?.filter((doc) => (doc?.selected && doc?.fieldCode === 'cam'))?.length === 1 ? openCollapse - 1 : openCollapse - 2;
    }
    dispatch(toggleCollapse(newCollapse));
  }
  

  return (
    <>
      <Button
        disabled={openCollapse === 0}
        onClick={handlePrevious}
      >
        Prev
      </Button>
      {(openCollapse === 2) && 
        <Button 
          onClick={handlePreview} 
          color='info'
        >
          Preview
        </Button>
      }
      {openCollapse !== 3 && 
        <LoadingButton 
          onClick={handleNext} 
          color='success'
          isLoading={loading}
          buttonContent={'Next'}
        />
      }
      {openCollapse === 3 && 
        <LoadingButton
          color='success' 
          buttonContent={'Send'}
          isLoading={loading}
          onClick={handleSubmit}
        />
      }
    </>
  )
}

export default React.memo(CAMFooter);
