import { axiosInstance } from "./base";
import { headers } from "./base";

export class LoanService {

    CMS_BASE_URL= process.env.REACT_APP_CMS_BASE_URL;

    getEditableFields = (lenderCode) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/cam/${lenderCode}/fields`, {headers: headers()});
    }

    updateEditableFields = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/cam`, payload, {headers: headers()});
    }

    getApplicationCAM = (payload) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/`, {headers: headers()});
    }

    updateApplicationCAM = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/cam/generate`, payload, {headers: headers()});
    }

    getUserAndDoc = (lenderCode, customerCode) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/cam/${lenderCode}/list/${customerCode}`, {headers: headers()});
    }

    updateUserAndDoc = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/send-to-lender`, payload, {headers: headers()});
    }

}