import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { updatePermissionsPair, updateUserDetails, updateUserPermissions } from "../store/user/userSlice";
import { generatePermissionsKeyPair } from "../utils/helper";
import useClass from "../hooks/useClass";

const AuthProtected = (props) => {

  const { server, toaster, dispatch, location, navigate } = useClass();
  const [activateRoute, setActivateRoute] = useState(false);

  useEffect(() => {
    fetchUserRole();
  }, [])

  const fetchUserRole = async () => {
    try {
      if(!localStorage?.getItem('token')){
        navigate('/login');
        return;
      }
      const response = await server.getUserRole();
      if(response?.data?.success){
        dispatch(updateUserDetails(response?.data?.userInfo));
        dispatch(updateUserPermissions(response?.data?.permissions));
        const result = generatePermissionsKeyPair(response?.data?.permissions);
        dispatch(updatePermissionsPair(result));
        validateRoute(result);  
        setActivateRoute(true);
      } else {
        throw new Error('Unable To Fetch Role');
      }
    } catch(error){
      navigate('/login');
      toaster.error(error);
    }
  }

  const validateRoute = (permissionKey) => {
    const pathSegments = location?.pathname?.split('/')?.filter(Boolean);
    const length = pathSegments?.length;
    let routeFound = length == 0;
    if(length == 1 && !routeFound){
      routeFound = permissionKey?.[pathSegments[0]?.toUpperCase()];
    } else if(!routeFound) {
      routeFound = permissionKey?.[pathSegments?.[0]?.toUpperCase()]?.[pathSegments?.[1]?.toUpperCase()];
    }
    if(!routeFound){
      navigate("/404");
    }
  }
 
  if (!localStorage.getItem('token')) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{activateRoute && props.children}</>;
};

export { AuthProtected };
