import React, { useState } from 'react';
import { entityDocumentsData, reKycFieldData } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import BankStatement from '../credit-analysis/BankStatement';
import FieldAction from '../../../components/Dropdown/application/FieldAction';
import AdditionalDocs from './AdditionalDocs';
import { Col, Row } from 'reactstrap';

const EntityDocs = () => {

  const { entityDetails } = useSelector((state) => (state?.entity));
  const [viewedDoc, setViewedDoc] = useState({});
  const [viewBankStatement, setViewBankStatement] = useState(false);

  const handleImageExpand = (link, label) => {
    return () => {
      window.open(link, '_blank');
      // setViewedDoc({link, label});
      // setIsOpen(true);
    }
  }

  return (
    <>
      <Row>
        {entityDetails?.entityDoc?.length > 0 && entityDetails?.entityDoc?.map((obj, index) => {
          if(entityDocumentsData?.[obj?.docType])
          return (
            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={`entity-doc-${index}`}>
              <div className='data-key ms-4'>
                <span>
                  {entityDocumentsData?.[obj?.docType]}
                </span>
              </div>
              <div className='data-value me-4'>
                {obj?.docType !== 'bankStatement' ? <span className='clickable me-2'>
                  <i className='fas fa-external-link-alt' onClick={handleImageExpand(obj?.presignUrl, entityDocumentsData?.[obj?.docType])} />
                </span> :
                <span className='clickable'>
                  <i className='fas fa-chart-line' onClick={() => setViewBankStatement(!viewBankStatement)}/>
                </span>}
                {reKycFieldData?.[obj?.docType] && <FieldAction field={obj?.docType} />}
              </div>
            </div>
          )
        })}
        <AdditionalDocs />
      </Row>
      <hr />
      <BankStatement
        isOpen={viewBankStatement}
        setIsOpen={setViewBankStatement}
      />
    </>
  )
}

export default React.memo(EntityDocs);
