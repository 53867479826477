import React from 'react';
import { useSelector } from 'react-redux';
import useClass from '../../../hooks/useClass';
import { useParams } from 'react-router-dom';
import { sendMCADocRequest } from '../../../store/customer/customer';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { formatDate2 } from '../../../utils/helper';

const MCADocs = () => {

  const { dispatch } = useClass();
  const { customerCode } = useParams();
  const { entityDetails, mcaDocs, mcaRequest } = useSelector((state) => (state?.entity));

  const handleMCARequest = () => {
    dispatch(sendMCADocRequest({customerCode, cin: entityDetails.cin}));
  }

  const handleImageExpand = (link, label) => {
    return () => {
      window.open(link, '_blank');
      // setViewedDoc({link, label});
      // setIsOpen(true);
    }
  }

  return (
    <>
      <Row>
        <Col>
          <div className='ps-4'>
            <span style={{fontWeight: 'bold', fontSize: '16px'}}>MCA Docs</span>&nbsp;
            <Button 
              onClick={handleMCARequest} 
              className='p-0' 
              color='link' 
              disabled={entityDetails?.mca?.status === 'PENDING' || entityDetails?.mca?.status === 'COMPLETE' || !entityDetails.cin}
            >
              Fetch
            </Button>&nbsp;
            {mcaRequest && <Spinner size='sm' />}
          </div>
          {mcaDocs?.length === 0 ? <>
            {entityDetails?.mca?.status === 'PENDING' && <>
              <div className='text-center'>
                Document requested on {formatDate2(entityDetails?.mca?.date)}. It may take upto 24 Hrs.
              </div>
            </>}
          </> : <>
            <div className='d-flex mt-4 flex-wrap'>
              {mcaDocs?.map((obj, index) => (
                  <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={`entity-mca-doc-${index}`}>
                    <div className='data-key ms-4'>
                      <span>
                        {obj?.docType}
                      </span>
                    </div>
                    <div className='data-value me-4'>
                      <span className='clickable me-2'>
                        <i className='fas fa-external-link-alt' onClick={handleImageExpand(obj?.presignUrl)} />
                      </span>
                    </div>
                  </div>
                )
              )}
            </div>
          </>}
        </Col>
      </Row>
      <hr />
    </>
  )
}

export default React.memo(MCADocs);
