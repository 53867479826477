import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import TextInput from '../../../components/text-input/TextInput';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { FILL_ALL_MENDETORY_FIELDS } from '../../../utils/error';

const Create = (props) => {

  const { toaster, oemServer, permission, navigate } = useClass();
  const {isOpen, setIsOpen} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    website: '',
    contactEmail: '',
    contactNumber: ''
  });

  const handleModelClose = () => {
    setIsOpen(false);
  }

  const handleInput = (key, value) => {
    setForm((prev) => ({
        ...prev,
        [key]: value
    }))
  }

  const handleTaskCreation = async () => {
    try{
      setIsLoading(true);
      if(validateForm()){
        const payload = {
          name: form?.name,
          code: form?.name.toUpperCase(),
          website:form?.website,
          contactEmail:form?.contactEmail,
          contactNumber:form?.contactNumber
      
        }
        const response = await oemServer.createManufacture(payload);
        setIsLoading(false);
        handleModelClose();
        if(permission?.MANUFACTURER?.DETAILS?.VIEW_MANUFACTURE_DETAILS){
          navigate(`/manufacturer/details/${response?.data?.data?.code}`);
          toaster.show(false, 'Manufacture created successfully');
        } else {
          toaster.show(false, 'Manufacture created successfully');
        }
      } else {
        throw new Error(FILL_ALL_MENDETORY_FIELDS);
      }
    } catch(error){
      setIsLoading(false);
      toaster.error(error);
    }
  }

  const validateForm = () => {
    const result = Object.keys(form).every((key) => {
        return form.hasOwnProperty(key) && form[key] !== "";
    });

    return result;
  }

  return (
    <>
        <Modal isOpen={isOpen}
          toggle={() => {setIsOpen(!isOpen)} }
          centered
        >
            <ModalHeader toggle={() => setIsOpen(!isOpen)}>
              Create Manufacturer
            </ModalHeader>
            <ModalBody>
              <div>
                <TextInput 
                  label={'Name'}
                  required={true}
                  name={'name'}
                  value={form?.name}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>

              <div className='mt-4'>
                <TextInput 
                  label={'Website'}
                  required={true}
                  name={'website'}
                  value={form?.website}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>
              
              <div className='mt-4'>
                <TextInput 
                  label={'Contact Email'}
                  required={true}
                  name={'contactEmail'}
                  value={form?.contactEmail}
                  handleInput={handleInput}
                  
                />
              </div>

              <div className='mt-4'>
                <TextInput 
                  label={'Contact Number'}
                  required={true}
                  name={'contactNumber'}
                  value={form?.contactNumber}
                  handleInput={handleInput}
                  type={'number'}
                  maxLength={10}
                />
              </div>
    
            </ModalBody>
            <ModalFooter>
              <Button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
                Close
              </Button>
              <LoadingButton
                buttonContent={'Create'}
                isLoading={isLoading}
                onClick={handleTaskCreation}
                color={'success'}
              />
            </ModalFooter>
        </Modal>
    </>
  )
}

export default Create;
