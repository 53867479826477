import React from 'react';
import '../text-input/text-input.scss';

const DateTimePicker = ({
  value, 
  customClass, 
  required, 
  label, 
  isValid, 
  isInValid, 
  supportiveText,
  name,
  handleInput,
  minDateTime,
  maxDateTime
}) => {

  const onChange = (e) => {
    handleInput(name, e.target.value);
  }

  return (
    <>
      <div className={`${customClass}`}>
      <div className='text-input'>
          <div className='heading'>{label}<span>{required && '*'}</span></div>
          <div className="input-group">
            <input
              type='datetime-local'
              className={`form-control ${isInValid && 'red-border'}`}
              min={minDateTime} // Set the minimum date and time
              max={maxDateTime}
              placeholder="Select a date"
              value={value}
              onChange={onChange}
              name={name}
            />
          </div>
          <div className={`${isValid && 'valid-feedback'} ${isInValid && 'invalid-feedback'}`}>{supportiveText}</div>
        </div>
      </div>
    </>
  )
}

export default React.memo(DateTimePicker);
