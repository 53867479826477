import React, { useEffect, useMemo, useState } from 'react';
import CustomUncontrolledDropdown from '../../../components/Dropdown/CustomUncontrolledDropdown';
import useClass from '../../../hooks/useClass';
import { Link, useParams } from 'react-router-dom';
import { getLeadTask } from '../../../store/user/userSlice';
import { useSelector } from 'react-redux';
import StatusChange from './StatusChange';
import { Spinner } from 'reactstrap';
import { formatDate2, formatDate3 } from '../../../utils/helper';

const ScheduleTable = () => {

  const { leadCode } = useParams();
  const { dispatch } = useClass();
  const { loading, tasks } = useSelector((state) => (state?.hotLead));
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState('');

  useEffect(() => {
    dispatch(getLeadTask(leadCode));
  }, [])

  const handleTaskUpdate = (data, task) => {
    return () => {
      setData({data, task});
      setIsOpen(true);
    }
  }

  if(loading) {
    return <div className='text-center mt-4'>
      <Spinner />
    </div>
  }

  const options = [{label: 'Open', value: 'OPEN'}, {label: 'Pending', value: 'PENDING'}, {label: 'In-Progress', value: 'IN_PROGRESS'}, {label: 'Complete', value: 'COMPLETE'}]

  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped mb-0">
          <thead>
            <tr className='text-center'>
              <th>Task ID</th>
              <th>Generation Date</th>
              <th>Due Date</th>
              <th>Type of Communication</th>
              <th>Comment</th>
              <th>Status</th>
              <th>Details of Meeting</th>
            </tr>
          </thead>
          <tbody>
            {tasks?.map((obj,index) => (
              <tr className='text-center' key={`follo-up-log-${index}`}>
                <td><Link to={`/task/details/${obj?.code}`}>{obj?.code}</Link></td>
                <td>{formatDate3(obj?.createdAt)}</td>
                <td>{formatDate2(obj?.followUp)}</td>
                <td>{obj?.details?.meetingType}</td>
                <td>{obj?.taskComment || '-'}</td>
                <td>
                  <CustomUncontrolledDropdown
                    options={options}
                    onClick={(option) => handleTaskUpdate(option, obj)}
                    value={options?.filter((f) => (f?.value === obj?.taskStatus))?.[0]?.label}
                    loading={false}
                    showSearch={false}
                  />
                </td>
                <td>{obj?.description || '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <StatusChange
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={data}
      />
    </>
  )
}

export default React.memo(ScheduleTable);
