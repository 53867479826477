export const generatePermissionsKeyPair = (permissions) => {
    let result = {};
    for(const module of permissions){
        let obj = {};
        for(const subModule of module?.subModules){
            obj = {
                ...obj,
                [subModule.subModuleCode]: subModule.permissions
            }
        }
        result = {
            ...result,
            [module.moduleCode]: obj
        }
    }

    return result;
}

export const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}

export const formatDate2 = (dateString) => {
  if(!dateString) return '-';
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of year

  const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Add leading 0 if needed
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM'; // AM or PM

  return `${day} ${month}’${year} at ${hours}:${minutes} ${ampm}`;
}

export const formatDate3 = (data) => {
  if(!data){
    return;
  }
  let date = new Date(data);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

export const validateFormAndCreatePayload = (form, prevData) => {
    let payload = {};
    const result = Object.keys(form)?.every((key) => {
      if(form?.[key] != prevData?.[key]){
        payload = {...payload, [key]: form?.[key]}
      }
      return form?.hasOwnProperty(key) && form?.[key] !== '';
    })

    return { result, payload }
}

export const validateForm = (form) => {
  const result = Object.keys(form || {})?.every((key) => {
    return form?.hasOwnProperty(key) && form?.[key] !== '';
  })
  return result;
}

export function validateNestedObject(obj, arr) {

  let result = true;

  for(let field of arr) {
    let fieldCode = field?.code;
    if(field?.subFields){
      for(let subField of field?.subFields){
        let subFieldCode = subField?.code;
        if(subField?.childFields){
          for(let childField of subField?.childFields){
            let childFieldCode = childField?.code;
            if(obj?.[fieldCode]?.[subFieldCode]?.[childFieldCode] === '' || obj?.[fieldCode]?.[subFieldCode]?.[childFieldCode] === undefined) result = false;
            if(!result) break;
          }
        } else {
          if(obj?.[fieldCode]?.[subFieldCode] === '' || obj?.[fieldCode]?.[subFieldCode] === undefined) result = false;
        }
        if(!result) break;
      }
    } else {
      if(obj?.[fieldCode] === '' || obj?.[fieldCode] === undefined) result = false;
    }
    if(!result) break;
  }
  
  return result; // Valid if all keys have values
}

export const formatNotificationTime = (dateString) => {
  const now = new Date();
  const notificationTime = new Date(dateString);
  const diffInSeconds = Math.floor((now - notificationTime) / 1000);
  const minutes = Math.floor(diffInSeconds / 60);
  const hours = Math.floor(diffInSeconds / 3600);
  const days = Math.floor(diffInSeconds / 86400);

  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return "Just now";
  }
}

export const productNameMapping = {
  vehicleLoan: 'Vehicle Loan'
}

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const isMobileValid = (value) => {
  if(
    value?.toString()?.[0] === '0' || 
    value?.toString()?.[0] === '1' || 
    value?.toString()?.[0] === '2' || 
    value?.toString()?.[0] === '3' || 
    value?.toString()?.[0] === '4' ||
    value?.toString()?.[0] === '5' ||
    value?.toString()?.[0] === 'e'
  ){
    return false;
  }
  return true;
}