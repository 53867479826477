import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, NavItem, NavLink, TabContent, TabPane, Nav } from 'reactstrap';

const CustomerDetails = () => {

    const { entityDetails } = useSelector((state) => (state?.entity));
    const [activeTab, setActiveTab] = useState(0);
    const [revenueBreakup, setRevenueBreakup] = useState([]);

    useEffect(() => {
        if(entityDetails?.additionalData){
            // const partners = entityDetails?.additionalData?.filter((obj) => (obj?.fieldKey === 'topPartners'))?.[0]?.value?.filter((data) => (data?.value !== 'NA'));;
            const details = entityDetails?.additionalData?.filter((obj) => (obj?.fieldKey === 'revenueBreakup'))?.[0]?.value;
            // let arr = [];
            // partners?.forEach((part) => {
            //     const partDetails = details?.filter((obj) => (obj?.name === part?.value))?.[0];
            //     arr = [...arr, {...part, ...partDetails}];
            // })
            setRevenueBreakup(details);
        }
    }, [entityDetails]);


    const toggleTab = (id) => {
        return () => {
            setActiveTab(id);
        }
    }

    return (
        <>
            {revenueBreakup?.length > 0 && <Row className='ps-4 pe-4'>
                <Col style={{fontWeight: 'bold'}} className='mb-2'>Top Customers</Col>
                <Nav tabs>
                    {revenueBreakup?.map((obj, index) => (
                        <NavItem key={`topPartners${index}`}>
                            <NavLink 
                                style={{color: activeTab === index && 'green' }} 
                                className={`${activeTab === index && 'active'} clickable`} 
                                onClick={toggleTab(index)}
                            >
                              {obj?.name}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
                <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId={activeTab} id="toppartners">
                        <Row>
                            <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                <div className='data-key'>Type of vehicle deployed</div>
                                <div className='data-value'>{revenueBreakup?.[activeTab]?.typeOfVehicleDeployed?.[0]?.label}</div>
                            </div>
                            <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                <div className='data-key'>Manufacturer</div>
                                <div className='data-value'>{revenueBreakup?.[activeTab]?.manufacturer?.[0]?.label}</div>
                            </div>
                            <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                <div className='data-key'>Number of vehicle deployed</div>
                                <div className='data-value'>{revenueBreakup?.[activeTab]?.numberOfVehicleDeployed}</div>
                            </div>
                            <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                <div className='data-key'>Avg. Kms driven/month</div>
                            <div className='data-value'>{revenueBreakup?.[activeTab]?.avgKmDrivenPerMonth || '-'}</div>
                            </div>
                            <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                <div className='data-key'>Contract Value</div>
                                <div className='data-value'>₹ {revenueBreakup?.[activeTab]?.contractValue ? parseInt(revenueBreakup?.[activeTab]?.contractValue)?.toLocaleString() : '-'}</div>
                            </div>
                            <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                <div className='data-key'>Avg. Revenue per Month</div>
                                <div className='data-value'>₹ {revenueBreakup?.[activeTab]?.avgRevenuePerMonth ? parseInt(revenueBreakup?.[activeTab]?.avgRevenuePerMonth)?.toLocaleString() : '-'}</div>
                            </div>
                            <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                <div className='data-key'>Contract</div>
                                <div className='data-value'>-</div>
                            </div>
                        </Row>
                    </TabPane>
                </TabContent>
            </Row>}
        </>
    )
}

export default React.memo(CustomerDetails);
