import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';

const FloatingButton = ({children}) => {

    const wrapperRef = useRef();
    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    const handleActionButtonClick = () => {
        setShowOptions(!showOptions);
    }

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShowOptions(false);
        }
    };

    return (
        <>
            {children && <Draggable>
                <div ref={wrapperRef} style={{position: 'fixed', top: '50%', left: '90%', zIndex: 1001, touchAction: "none",}}>
                    <div className='multi-action'>
                        <button className={`action-button ${showOptions && 'active'}`} onClick={handleActionButtonClick}><span className='fa fa-plus'></span></button>
                        <ul className='actions'>
                            {children}
                        </ul>
                    </div>
                </div>
            </Draggable>}
        </>
    )
}

export default React.memo(FloatingButton);
