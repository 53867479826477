import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import CustomSelectInput from './CustomSelectInput';
import { FormGroup, Label } from 'reactstrap';

const AsyncDropdown = (props) => {

  const {
    label, 
    name, 
    value, 
    handleInput,
    disabled,
    required,
    placeholder,
    customClass,
    loadOptions,
    valid,
    returnsObj=false,
    defaultOptions,
    isMulti=false,
    isClearable=true,
    isInValid=false,
    supportiveText
  } = props

  const [selectedOption, setSelectedOption] = useState('');
  const [searchedDebounce, setSearchedDebounce] = useState(null);
  
  useEffect(() => {
    if(isMulti || returnsObj){
      setSelectedOption(value);
    } else if(!returnsObj && value != undefined){
      value?.trim() !== '' ? setSelectedOption({label: value}) : setSelectedOption('');
    }
  }, [value])

  const handleChange = (value) => {
    setSelectedOption(value);
    (returnsObj || isMulti) ? handleInput(name, value) : handleInput(name, value.value);
  }

  const handleLoadOptions = (search='', callback) => {
    if(searchedDebounce){
      clearTimeout(searchedDebounce);
      setSearchedDebounce(null);
    }
    const timeout = setTimeout(() => {
      loadOptions(search, callback)
    }, 500)
    setSearchedDebounce(timeout);
  }
  

  return (
    <>
      <div className={customClass}>
        <FormGroup className="form-group has-float-label">
          {label}{required && <span style={{color: 'red'}}>*</span>}
          <AsyncSelect
          //   cacheOptions
            defaultOptions={defaultOptions}
            valid={valid}
            invalid={isInValid}
            components={{ Input: CustomSelectInput }}
            className={`react-select ${isInValid && 'danger-dropdown'}`}
            classNamePrefix="react-select"
            placeholder={placeholder}
            name={name}
            value={selectedOption}
            onChange={handleChange}
            loadOptions={handleLoadOptions}
            required={required}
            isDisabled={disabled}
            isMulti={isMulti}
            isClearable={isClearable}
          />
          <div className={`${isInValid && 'invalid-feedback'}`}>{supportiveText}</div>
        </FormGroup>
      </div>
    </>
  );
};
export default AsyncDropdown;
